import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import close_icon from "../../../assets/001-close.svg";
import {useProps} from "../../../contexts";
import { DialogProps } from "../../../types/common";

type VRPopUpProps = {
  vrLink: string;
  title: string;
  isNotHomePage?: boolean;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "20px",
      backgroundColor: "white",
    },
  },
  dialog_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    minHeight: "40vh",
    backgroundColor: "white",
    borderRadius: "50px",
  },
  icon_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    zIndex: 2,
  },
  title: {
    margin: "0px",
    fontSize: "23px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  icon: {
    cursor: "pointer",
    width: "20px",
    "&:hover ": {
      filter: "invert(35%) sepia(55%) saturate(3225%) hue-rotate(338deg) brightness(87%) contrast(116%)",
    },
  },
  dummy_box: {
    position: "absolute",
    top: 0,
    left: -2,
    height: "50px",
    width: "52vw",
    backgroundColor: "white",
    zIndex: 1,
  },
  dummy_box_bottom: {
    bottom: 0,
    position: "absolute",
    left: -2,
    height: "50px",
    width: "52vw",
    backgroundColor: "white",
  },
  iframe_container: {
    display: "flex",
    position: "relative",
    height: "60vh",
    width: "50vw",
    marginTop: "-10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog_inner_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px 0px 10px",
  },
}));

type props = DialogProps & VRPopUpProps;
const VRPopUp: React.FC<props> = ({
  // history,
  isOpen,
  handleClose,
  vrLink,
  title,
  isNotHomePage = false,
}) => {
  const classes = useStyles();
  const { languagePackage } = useProps();

  let src = `https://sketchfab.com/models/${vrLink}/embed?autospin=0&autostart=1&ui_infos=0&ui_controls=0&ui_stop=0`;

  if (isNotHomePage) src = vrLink;

  return (
    <Dialog
      className={classes.dialog}
      open={isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog_container}>
        <div className={classes.icon_container}>
          <p className={classes.title}>{title}</p>
          <img className={classes.icon} src={close_icon} onClick={handleClose} />
        </div>
        <div className={classes.dialog_inner_container}>
          <div className={classes.iframe_container}>
            <div className={classes.dummy_box} />
            <iframe style={{ height: "100%", width: "100%" }} src={src} />
            <div className={classes.dummy_box_bottom} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VRPopUp;
