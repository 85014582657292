import {apiMiddleware} from "../utils/middleware";
import {getProfitCompanyProfitRates, updateProfitCompanyProfitRates} from "../services/api/general";


export const getProfits = async (
    setMainState: (params: any) => void,
    changeContext: any
) => {
    const {data, success} = await apiMiddleware(changeContext, getProfitCompanyProfitRates)
    if (!success) return;

    const ratesArr = data.rates.map((e: any) => ({value: e}));
    setMainState({flowValues: ratesArr})
}

export const updateProfits = async (
    mainState: any,
    setMainState: (params: any) => void,
    changeContext: any
) => {
    const {flowValues} = mainState;
    const rates = flowValues.map((e: any) => e.value)
    const {success} = await apiMiddleware(changeContext, updateProfitCompanyProfitRates, {rates})
    if (!success) return;

    // const ratesArr = data.rates.map((e: any) => ({value: e}));
    // setMainState({flowValues: ratesArr})
}
