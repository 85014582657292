import { login, LoginAPIProps, logout } from "../services/api/user";
import { IUser } from "../types/user";
import { apiMiddleware } from "../utils/middleware";
const contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};
const userContext: IUser = {
  isLogin: false,
  isSuperAdmin: false,
  username: "",
  role: 0,
};
export const handleLogoutAPI = async (changeContext: any, isBusiness: boolean) => {
  changeContext({ openBackdrop: true });

  const { data, success } = await apiMiddleware(changeContext, logout);
  if (!success) return;
  else{
    userContext.isLogin = false;
    userContext.isSuperAdmin = false;
    userContext.username = "";
    userContext.role = 0;
    contextParams.user = userContext;
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "登出成功";
    changeContext(contextParams);
    return true;
  }

};

export const handleLoginAPI = async (
  username: string,
  password: string,
  rememberAcc: boolean = false,
  changeContext: any
) => {
  changeContext({ openBackdrop: true });
  const loginAPIParams: LoginAPIProps = {
    username,
    password,
    rememberAcc,
  };
  const { data, success } = await apiMiddleware(changeContext, login, loginAPIParams);
  if (!success) return;
  else {
    //setUserStatus
    userContext.username = data.username;
    userContext.isSuperAdmin = data.isSuperAdmin;
    userContext.isLogin = true;
    contextParams.user = userContext;
    //setLocalStorage
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "登入成功!";

    changeContext({...contextParams});
    return true;
  }
};
