import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { routes } from "../../configs/routes";
import { getRoutesWithPath } from "../../utils";
//Component
import { useProps } from "../../contexts";
import CustomTable from "../components/CustomTable";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
// Controllers
import { ManagerListItemProps } from "../../services/api/manager";
import { createManagerAPI, getManagerListAPI } from "../../controller/SuperAdmin/ManagerController";
import CustomButtons from "../components/Inputs/CustomButtons";
import ManagerPopup from "../components/Popups/CreateManagerPopup";
import { getCountryListAPI } from "../../controller/GeneralController";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 30,
  },
  toolsWrapper: {
    display: "flex",
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  sub_header_container: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "name", label: "姓名", align: "left" },
  { id: "country", label: "國家", align: "left" },
  { id: "email", label: "郵件", align: "left" },
  { id: "createdAt", label: "創建日期", align: "left" },
  { id: "edit", label: "", disabledSort: true, align: "left" },
];

const generateBody = (
  classes: any,
  body: Array<ManagerListItemProps>,
  page: number,
  rowsPerPage: number,
  handleEditProfile: any
) => {
  return body.map((e, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    name: e.name,
    country: e.country,
    email: e.email,
    createdAt: e.createdAt,
    edit: (
      <IconButton aria-label="inspect" onClick={handleEditProfile(e.ID)}>
        <VisibilityIcon />
      </IconButton>
    ),
  }));
};

const ManagerList = () => {
  const classes = useStyles();
  const { languagePackage, _handleChange } = useProps();
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const [countryList, setCountryList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
    bodyData: [],
    refreshList: false,
    username: "",
    password: "",
    name: "",
    email: "",
    countryName: "",
    countryCode: "",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(
    (event, prop) => {
      setMainState({ [prop]: event.target.value });
    },
    [setMainState]
  );

  const handleChangeCountry = (event: { value: string; label: string }, statusKey: string) => {
    setMainState({ [statusKey]: event.value, countryName: event.label });
  };

  const handleEditProfile = (id: number) => () => {
    history.push(getRoutesWithPath(routes.navBar.managerDetails.path, [id]));
  };

  // API
  const handleCreate = async () => {
    await createManagerAPI(mainState, setMainState, setOpenDialog, _handleChange, languagePackage);
  };

  useEffect(() => {
    getManagerListAPI(mainState, setMainState, _handleChange, setQuery);
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList]);

  useEffect(() => {
    getCountryListAPI(_handleChange, setCountryList);
  }, []);

  const body = generateBody(classes, mainState.bodyData, mainState.page, mainState.limits, handleEditProfile);

  return (
    <React.Fragment>
      <div className={classes.sub_header_container}>
        <Typography variant="h1">{routes.navBar.managerList.text}</Typography>
        <CustomButtons
          label="新增帳號"
          customStyle={{ marginLeft: "30px" }}
          handleClick={() => {
            setOpenDialog(true);
          }}
        />
      </div>
      <div className={classes.wrapper}>
        <CustomTable
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totals}
          rowsPerPage={mainState.limits}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>

      <ManagerPopup
        username={mainState.username}
        password={mainState.password}
        email={mainState.email}
        name={mainState.name}
        countryList={countryList}
        countryName={mainState.countryName}
        countryCode={mainState.countryCode}
        isOpen={openDialog}
        setOpen={setOpenDialog}
        handleChangeCountry={handleChangeCountry}
        handleChange={handleChange}
        handleCreate={handleCreate}
      />
    </React.Fragment>
  );
};

export default ManagerList;
