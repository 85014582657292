import React, { useEffect, useState } from "react";
import { DetailsProps, AccountProps } from "../../../types/user";
import { useProps } from "../../../contexts";
import { DialogProps } from "../../../types/common";
//Component
import CustomTextField from "../Inputs/CustomTextField";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
//Img
import close_icon from "../../../assets/001-close.svg";

type SubmitProps = DetailsProps & AccountProps;

type EditAccountPopUpProps = {
  isRegister: boolean;
  isSales: boolean;
  accountDetails?: SubmitProps | undefined;
};
type Props = DialogProps & EditAccountPopUpProps;

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "20px",
      backgroundColor: "white",
    },
  },
  dialog_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    minHeight: "40vh",
    backgroundColor: "white",
    borderRadius: "50px",
  },
  icon_container: {
    alignSelf: "flex-end",
  },
  icon: {
    cursor: "pointer",
    width: "20px",
    "&:hover ": {
      filter: "invert(35%) sepia(55%) saturate(3225%) hue-rotate(338deg) brightness(87%) contrast(116%)",
    },
  },
  form_container: {
    display: "flex",
    flex: 1,
    padding: "0px 0px 0px 0px",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  form_inner_container: {
    flex: 0.45,
    margin: "0px 15px",
  },
  input_container: {
    margin: "10px 0px",
    width: "400px",
    [theme.breakpoints.down("lg")]: {
      width: "280px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
  },
  input_title: {
    margin: "0px",
    marginBottom: "20px",
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  submit_button: {
    marginLeft: "20px",
    alignSelf: "center",
    marginTop: "5px",
    width: "120px",
    padding: "8px 6px",
    color: "white",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  dialog_inner_container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 40px 35px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 35px 10px",
    },
  },
  button_container: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  },
}));

const EditAccountPopUp: React.FC<Props> = ({ isOpen, handleClose, isSales, accountDetails }) => {
  const classes = useStyles();
  const { languagePackage } = useProps();
  const [formDetails, setFormDetails] = useState<SubmitProps>({
    name: "",
    phoneNumber: "",
    email: "",
    idNumber: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (accountDetails) {
      setFormDetails(accountDetails);
    } else {
      setFormDetails({
        name: "",
        phoneNumber: "",
        email: "",
        idNumber: "",
        username: "",
        password: "",
        confirmPassword: "",
      });
    }
  }, [accountDetails, isOpen]);

  let title = languagePackage.SALES_REGISTER;
  if (!isSales) title = languagePackage.PRODUCT_MANAGER_REGISTER;

  const handleTextInput = () => {};

  return (
    <Dialog
      className={classes.dialog}
      open={isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog_container}>
        <div className={classes.icon_container}>
          <img className={classes.icon} alt="" src={close_icon} onClick={handleClose} />
        </div>
        <div className={classes.dialog_inner_container}>
          <p className={classes.input_title}>{title}</p>

          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label={languagePackage.ADMIN_NAME}
                type={"text"}
                value={formDetails.name}
                stateKey={"name"}
                handleChange={handleTextInput}
                fullWidth
                disabled
              />
              <CustomTextField
                value={formDetails.username}
                stateKey={"username"}
                handleChange={handleTextInput}
                label={languagePackage.USER_NAME}
                type={"text"}
                fullWidth
                disabled
              />
              <CustomTextField
                value={formDetails.phoneNumber}
                stateKey={"phoneNumber"}
                handleChange={handleTextInput}
                label={languagePackage.PHONE_NUMBER}
                type={"tel"}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                value={formDetails.email}
                stateKey={"email"}
                handleChange={handleTextInput}
                label={languagePackage.EMAIL}
                type={"email"}
                fullWidth
                disabled
              />
              <CustomTextField
                value={formDetails.idNumber}
                stateKey={"idNumber"}
                handleChange={handleTextInput}
                label={languagePackage.IC_NUMBER}
                type={"text"}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          {/* <div className={classes.form_container}>
            <div className={classes.form_inner_container}></div>
            <div className={classes.form_inner_container}></div>
          </div> */}
        </div>
      </div>
    </Dialog>
  );
};

export default EditAccountPopUp;
