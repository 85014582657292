import React from 'react';
import clsx from 'clsx';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { styleProps } from '../../../types/common';
import { PropTypes } from '@material-ui/core';

type InputProps = {
    label: string;
    themeColor?: PropTypes.Color;
    handleClick: (event:any) => void;
    fullWidth?: boolean;
    active?: boolean;
    disabled?: boolean;
    deactivate?: boolean;
    customStyle?: styleProps;
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.light,
        textTransform: 'none',
        width: '100%',
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        paddingLeft: 50,
        paddingRight: 50,
    },
    fullWidth: {
        width: '100%',
    },
    themeBackground: {
        // backgroundColor: theme.palette.background.button,
        color: theme.palette.primary.main,
    },
    deactivate: {
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: '#f44336',
        }
    }
}));

export default function CustomButtons(props: InputProps) {
    const {label, handleClick, fullWidth, active, disabled, deactivate,themeColor = "primary",customStyle} = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <ColorButton
                variant="contained"
                color={themeColor}
                style={customStyle}
                disabled={disabled && disabled}
                className={clsx(classes.root,
                    fullWidth && classes.fullWidth,
                    active && classes.themeBackground,
                    deactivate && classes.deactivate
                )}
                onClick={handleClick}
            >
                {label}
            </ColorButton>
        </React.Fragment>
    );
}
