import { FunctionComponent, ReactNode, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Select, { StylesConfig } from "react-select";
import { defaultTheme } from "react-select";
import { styleProps } from "../../types/common";

export interface StateOption {
  readonly value: string;
  readonly label: string;
}

interface State {
  readonly isOpen: boolean;
  readonly value: StateOption | null | undefined;
}

type SelectProps = {
  customStyle?: styleProps;
  stateKey?: string;
  handleChange: (event: { value: string; label: string }, stateKey: string) => void;
  initValue: string | number | null;
  listArray?: any;
  disabled?: boolean;
  defaultLabel?: string;
};

export const stateOptions: readonly StateOption[] = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const { colors } = defaultTheme;

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: "none !important",
    borderRadius: 13,
    height: 45,
    width: 160,
    backgroundColor: theme.palette.background.paper,
    color: "black",
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    justifyContent: "space-between",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.gray.main,
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.60)",
    },
  },
}));

const selectStyles: StylesConfig<StateOption, false> = {
  control: (provided: any) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    cursor: "pointer",
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  option: (style: any) => ({ ...style, cursor: "pointer !important" }),
};
const SelectDropDownWithSearch: React.FC<SelectProps> = ({
  customStyle,
  handleChange,
  stateKey,
  initValue,
  listArray,
  disabled,
  defaultLabel,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let list = stateOptions;
  if (listArray) {
    list = listArray;
  }
  let obj = list.find((element) => {
    if (element.value === initValue) {
      return element;
    }
  });

  let defaultText = defaultLabel ? defaultLabel : "-";
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const onSelectChange = (value: any) => {
    toggleOpen();
    handleChange && handleChange(value, stateKey ? stateKey : "");
  };
  return (
    <div>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <Button
            disabled={disabled}
            style={customStyle}
            variant="outlined"
            color={"primary"}
            className={classes.button}
            onClick={toggleOpen}
            endIcon={<ChevronDown />}
          >
            {obj ? `${obj.label}` : defaultText}
          </Button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={onSelectChange}
          options={list}
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={obj}
        />
      </Dropdown>
    </div>
  );
};

// styled components

const Menu = (props: JSX.IntrinsicElements["div"]) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 3,
        cursor: "pointer",
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
      cursor: "pointer",
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}
const Dropdown: FunctionComponent<DropdownProps> = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p: JSX.IntrinsicElements["svg"]) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path d="M 8 10 l 4 4 l 4 -4 z" fill="red" fillRule="evenodd" />
  </Svg>
);

export default SelectDropDownWithSearch;
