import { languagePackage } from "../../types/common"

const zh_tw: languagePackage = {
  LOGIN: '登入',
  REGISTER: '註冊',
  START_DATE:'開始日期',
  END_DATE:'結束日期',
  CHOOSE_LANGUAGE: '選擇語言:',
  CHINESE: '繁體中文',
  ENGLISH: '英文',
  BACK: '返回',
  CANCEL: '取消',
  CREATE: "創建",
  BANNER_TITLE: '讓你的產品展示給全球',
  BANNER_DESCRIPTION: 'SHOW YOUR BEST to the World',
  BANNER_BUTTON: '服務項目',
  MORE_DETAILS: '瞭解更多',
  NEWS: '最新消息',
  PIN: '置頂',
  SERVICE: '服務',
  CONTACT_US: '聯絡我們',
  SEND: '提交',
  NAME: '姓名',
  EMAIL: '電子郵件',
  TEMP_EMAIL: '備用電子郵件',
  MASSAGE: '留言',
  TERM: '使用條款',
  POLICY: '隱私條款',
  COPY_RIGHT: '依斯博萊斯活動方案有限公司 © 2021 All Right Reserved.',
  MEMBER_COUNT: '商業會員數:',
  TODAY: '今天',
  YESTERDAY: '昨天',
  TOTAL: '總數',
  ONLINE_MEMBER: '在線人數',
  LANDING_PRODUCT: '3D 產品展示',
  LANDING_FOOTER: 'Show Your Best',
  LANDING_BUTTON: '點擊進入SYB',
  LOGIN_USERNAME: '帳號名字',
  LOGIN_BUSINESS_USERNAME: '管理者帳號',
  LOGIN_BUSINESS_PASSWORD: '管理者密碼',
  LOGIN_COMPANY_USERNAME: '企業會員帳號(公司編號)',
  PASSWORD: '密碼',
  NEW_PASSWORD: '新密碼',
  OLD_PASSWORD: '舊密碼',
  CONFIRM_PASSWORD: '確認密碼',
  REMEMBER_PASSWORD: '記住密碼',
  FORGET_PASSWORD: '忘記密碼',
  REGISTER_NOW: '立即註冊',
  LOGIN_NOW: '立即登入',
  VERIFY_CODE_INPUT: '輸入驗證碼',
  CONFIRM_TRANSFER:'確認匯款',
  INDIVIDUAL: '個人會員',
  INDIVIDUAL_DESC_1: 'Personal Account - Highlight',
  BUSINESS: '企業會員',
  BUSINESS_DESC_1: 'Business Account - Highlight',
  FREE_REGISTER: '免費註冊',
  REGISTER_INDIVIDUAL_BENEFIT: '線上觀覽各產業產品.',
  REGISTER_INDIVIDUAL_BENEFIT_1: '分享朋友註冊企業帳號,及擁有分潤的機會.',
  REGISTER_BUSINESS_BENEFIT: '線上展示公司產品.',
  REGISTER_BUSINESS_BENEFIT_1: '多種展示方式包含 3D,VR,影片及檔案上傳功能.',
  VERIFY_POPUP_TITLE: '請輸入通行碼',
  VERIFY_POPUP_DESC: '請輸入通行碼以進入查看產品詳情',
  GET_VERIFY_CODE: '聯絡業務獲取通行碼',
  PRODUCT_VR: 'VR影片',
  PRODUCT_VIDEO: '影片',
  NO_VIDEO:'未上傳影片',
  PRODUCT_DOC: '檔案下載',
  PRODUCT_CONTACT_US: '聯絡我們',
  PRODUCT_CONTACT_NOW: '寫信給我',
  DOWNLOAD: '下載',
  EDIT: '編輯',
  DONE: '完成',
  ADD_ON: '添加',
  UPDATE: '更新',
  SUCCESSFUL:'成功',
  EDIT_COMPANY_LOGO:'編輯公司標示',
  EDIT_SERIES:'編輯系列',
  EDIT_PRODUCT:'編輯產品',
  SERIES:'系列',
  ADD_NEW_SERIES: '添加新系列',
  SERIES_NAME: '系列名稱',
  ADD_NEW_PRODUCT: '添加新產品',
  PRODUCT_NAME: '產品名稱',
  IMG: '圖示',
  NO_UPLOAD :'未上傳',
  UPLOAD: '上傳',
  DELETE: '刪除',
  CONFIRM_DELETE: '確認刪除',
  CONFIRM_DELETE_DESC: '確認是否刪除',
  VR_VIDEO: 'VR 影片',
  VR_VIDEO_UPLOAD_DESC: '請在此貼上VR影片連結',
  VD_VIDEO: '影片',
  VD_VIDEO_UPLOAD_DESC: '請在此貼上影片連結',
  SET_PASS_CODE: '設定通行碼',
  SET_PUBLISH: '設定上架',
  DOC: '檔案',
  CLICK_UPLOAD_IMG: '點此上傳圖示',
  DROP_UPLOAD_DOC: '將檔案拖到此處上傳<br> <span style="color:black">或</span><br>點擊上傳檔案',
  RE_UPLOAD_DOC: '拖拉或點擊重新上傳檔案',
  ADMIN_PANEL: '搜尋產業/公司',
  BUSINESS_ADMIN_PANEL: '企業管理介面',
  PROFILE: '我的帳戶',
  LOGOUT: '登出',
  RESET_PASSWORD: '重設密碼',
  RESET_PASSWORD_DESC: "請提供妳所註冊的電子郵件，我們將寄送重設密碼的郵件給您。",
  PERSONAL_DETAIL: '基本資料',
  REGISTER_NAME: '姓名（本名）',
  REGISTER_NAME_DESC: '(等同金融匯款帳戶戶名，請正確填寫）',
  BIRTHDAY: '出生日期',
  IC_NUMBER: '身分證字號',
  IC_IMG_BNF: '身分證正反面',
  FRONT_IC:'身分證正面',
  BACK_IC:'身分證背面',
  PHONE_NUMBER: '行動電話',
  REGISTER_CONFIRM_EMAIL_DESC: '註冊成功後請確認您的電子郵件',
  ADDRESS: '聯絡地址',
  POST_CODE: '郵政編號',
  BANK_ACCOUNT_SETTING: '帳號設定',
  USER_NAME: '帳號名稱',
  NEXT_STEP: '下一步',
  PERVIOUS_STEP: '上一步',
  JOB_DETAIL: '職業資料',
  COMPANY_NAME: '公司名稱',
  COMPANY_DESCRIPTION: '公司介紹',
  CURRENT_COMPANY: '任職公司',
  COMPANY_CODE: '公司編號',
  COMPANY_CODE_DESC: '(所輸入公司編號將等同於企業會員帳號，請正確填寫)',
  COMPANY_COUNTRY: '公司所在國家',
  COMPANY_PHONE: '公司電話',
  COMPANY_EMAIL: '公司電子信箱',
  COMPANY_WEBSITE: '公司網址',
  COMPANY_REGISTER_ADDRESS: '公司登記地址',
  COMPANY_CATEGORY: '公司產業',
  COMPANY_CONTACT_ADDRESS: '公司聯絡地址',
  PHONE_CODE: '分機',
  COMPANY_ADDRESS: '公司地址',
  REFERRER_CODE: '介紹人代碼',
  REFERRER:"介紹人",
  CODE:'代碼',
  INVITE_CODE: '邀請碼',
  BANK_ACCOUNT: '帳戶資料',
  BANK_NAME: '銀行名稱',
  BANK_BRANCH: '銀行分行',
  BANK_NUMBER: '帳戶號碼',
  BANK_USER_NAME: '戶名',
  TOP_UP_PAGE: '充值頁面',
  RECORD_PROFITS: '利潤紀錄',
  AGREE_TERM: '我同意以上條款',
  BUSINESS_DETAILS: '企業資料',
  BUSINESS_BEHALF: '負責人姓名',
  FOLLOW_TICK: '同上',
  ADMIN_DETAILS: '管理者資料',
  ADMIN_ACCOUNT: '管理者帳號',
  ADMIN_NAME: '管理者姓名',
  OPTIONAL: '（非必填）',
  SALES_REGISTER: '業務聯絡員',
  PRODUCT_MANAGER_REGISTER: '產品管理員',
  ACCOUNT_SETTING:'帳號設定',
  NO_RECORD_FOUND:'無紀錄',
  CHOOSE: '選擇',
  COUNTRY: '國家',
  CITY: '城市',
  STATE: '州属',
  MY_COINS: '我的點數',
  RECHARGE_POINT:'點數充值',
  TRANSACTION_RECORD:'交易紀錄',
  CURRENT_BALANCE: '當前餘額',
  UNLOCK: '解鎖',
  NO_UNLOCK:'未解鎖',
  NO_RESOURCES:'未上傳',
  UNLOCK_FEE:'解鎖費用',
  CONFIRM_UNLOCK: '確認解鎖',
  CONFIRM_UNLOCK_DESC: '請問是否確認解鎖?',
  MESSAGE:'信息',
  SYSTEM_DEPOSIT: "系統充值",
  WITHDRAW: "提款",
}

export default zh_tw
