import React from 'react';
import CountUp from 'react-countup';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        // minWidth: 275,
        backgroundColor: theme.palette.primary.light,
        // borderColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.gray.main}`,
        // boxShadow: '1px 1px 10px #d2d2d2ba',
        borderRadius: 15,
        // padding: '35px 0',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    description: {
        fontSize: 30,
    },
}))

type CardProps = {
    title: string;
    value: number;
}

const CustomCard: React.FC<CardProps> = (props) => {
    const {title, value} = props;
    const classes = useStyles();

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {title}
                </Typography>
                <Typography className={classes.description} variant="body2" component="p">
                    <CountUp start={0} end={value} duration={2}/>
                </Typography>
            </CardContent>
        </Card>
    );
}

export default CustomCard;
