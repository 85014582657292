import React, {useCallback, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { routes } from "../../configs/routes";
import configVariables from "../../configs/variables.json";
import { SelectChangeEventHandler } from "../../types/common";
import { getRoutesWithPath } from "../../utils";
//Component
import {useProps} from "../../contexts";
import DeletePopUp from "../components/Popups/DeletedPopUp";
import CustomTable from "../components/CustomTable";
import CustomSelect from "../components/Inputs/CustomSelect";
import CustomSearch from "../components/CustomSearch";
import RedirectText from "../components/RedirectText";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
// Controllers
import {getMemberList, updateMemberManualStatus, updateMemberStatus} from "../../controller/MemberController";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 30,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  sub_header_container: {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    padding:"0",
    alignItems: "center",
    [theme.breakpoints.down("sm")]:{
      flexDirection:"column",
      padding:"10px",
      alignItems: "normal",
    },
  },
}));

type TableBody = {
  ID: number;
  name: string;
  email: string;
  status: number;
  manualStatus: number;
  idPhoto: string;
  createdAt: string;
};

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "memberName", label: "姓名", align: "left" },
  { id: "email", label: "郵件", align: "left" },
  { id: "status", label: "認證狀態", align: "left" },
  { id: "manualStatus", label: "狀態", align: "left" },
  { id: "createdAt", label: "創建日期", align: "left" },
  { id: "idPhoto", label: "認證圖片", align: "left" },
  { id: "edit", label: "檢視", disabledSort: true, align: "left" },
];

const generateBody = (
  classes: any,
  body: TableBody[],
  page: number,
  rowsPerPage: number,
  handleChange: any,
  handleChangeManual: any,
  handleDeleteDialog: any,
  handleEditProfile: any,
  handleRedirect: any
) => {
  return body.map((e, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    memberName: e.name,
    email: e.email,
    status: <CustomSelect
        stateKey="status"
        value={e.status}
        items={e.status === configVariables.userStatus[0].value ? configVariables.userStatus :configVariables.userStatusActive}
        handleChange={handleChange(i, e.ID)}
        autoWidth
        incline
    />,
    manualStatus: <CustomSelect
        stateKey="manualStatus"
        value={e.manualStatus}
        items={configVariables.userManualStatus}
        handleChange={handleChangeManual(i, e.ID)}
        autoWidth
        incline
    />,
    idPhoto: e.idPhoto ?
        <RedirectText id={e.ID} text="查看圖片" tooltip="查看圖片" handleClick={handleRedirect(e.ID)} />
        : '無',
    createdAt: e.createdAt,
    edit: (
      <IconButton aria-label="inspect"  onClick={handleEditProfile(e.ID)}>
        <VisibilityIcon />
      </IconButton>
    )
  }));
};

const MembersList = () => {
  const classes = useStyles();
  const {_handleChange} = useProps();
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    rowsPerPage: query.limit || 10,
    totalPageItem: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "createdAt",
    bodyData: [],
    deleteDialogID: 0,
    deleteDialogContent: "",
    refreshList: false,
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChangeDataSelect = useCallback(
    (index, ID) => async (event: SelectChangeEventHandler, prop: string) => {
      const { bodyData } = mainState;
      const tmpData: any = [...bodyData];
      const value = event.target.value;
      tmpData[index][prop] = value;

      // API
      const updateObj = {ID: ID, status: value}
      await updateMemberStatus(updateObj, setMainState, _handleChange)

      setMainState({ bodyData: tmpData });
    },
    [mainState.bodyData, setMainState]
  );

  const handleChangeManualSelect = useCallback(
      (index, ID) => async (event: SelectChangeEventHandler, prop: string) => {
        const { bodyData } = mainState;
        const tmpData: any = [...bodyData];
        const value = event.target.value;
        tmpData[index][prop] = value;

        // API
        const updateObj = {ID: ID, status: value}
        await updateMemberManualStatus(updateObj, setMainState, _handleChange)

        setMainState({ bodyData: tmpData });
      },
      [mainState.bodyData, setMainState]
  );

  const handleDeleteDialog = useCallback(
    (id: number, name: string) => () => {
      setMainState({ deleteDialogID: id, deleteDialogContent: name });
      setOpenDeleteDialog(true);
    },
    []
  );
  const handleEditProfile = (id: number) => () => {
    history.push(getRoutesWithPath(routes.navBar.memberProfile.path, [id]));
  };

  const handleRedirect = (id: number) => () => {
    history.push(getRoutesWithPath(routes.navBar.memberBankAccount.path, [id]));
  }

  // API
  const handleSearch = async (state: any) => {
    const extraFields = {
      startDate: state.startDate,
      endDate: state.endDate,
      search: state.searchParam,
    }

    await fetchData(extraFields);
  }

  const fetchData = async (extraFields: any=undefined) => {
    const {page, rowsPerPage, order, orderBy}  = mainState;
    let params = {
      page,
      limits: rowsPerPage,
      order,
      orderBy
    }

    if (extraFields) {
      params = {...params, ...extraFields}
    }

    await getMemberList(params, setMainState, _handleChange);
    setQuery(params);
  }

  useEffect(() => {
    fetchData();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList])

  const body = generateBody(
    classes,
    mainState.bodyData,
    mainState.page,
    mainState.rowsPerPage,
    handleChangeDataSelect,
    handleChangeManualSelect,
    handleDeleteDialog,
    handleEditProfile,
    handleRedirect
  );

  return (
    <React.Fragment>
      <div className={classes.sub_header_container}>
        <Typography variant="h1">{routes.navBar.members.text}</Typography>
        <CustomSearch submitSearch={handleSearch} />
      </div>
      <div className={classes.wrapper}>
        <CustomTable
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totalPageItem}
          rowsPerPage={mainState.rowsPerPage}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>

      <DeletePopUp
          handleSubmit={() => {}}
        itemName={mainState.deleteDialogContent}
        isOpen={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
      />
    </React.Fragment>
  );
};

export default MembersList;
