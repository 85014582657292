import fetchData from "../../utils/fetchData";

export type ManagerListItemProps = {
    ID: number;
    username?: string;
    password?: string;
    name: string;
    country: string;
    countryName?: string;
    countryValue?: string;
    email: string;
    rates?: Array<number>;
    createdAt?: string;
};


export function getManagerList(params: any) {
    return fetchData({
        url: "/managers",
        method: "GET",
        params: params,
    });
}

export function getManagerDetails(managerID: string) {
    return fetchData({
        url: `/managers/${managerID}`,
        method: "GET",
    });
}

export function updateManagerDetails(body: ManagerListItemProps) {
    return fetchData({
        url: `/managers/${body.ID}`,
        method: "PUT",
        data: body,
    });
}

export function deleteManagerDetails(managerID: string) {
    return fetchData({
        url: `/managers/${managerID}`,
        method: "DELETE",
    });
}

export function createManager(body: ManagerListItemProps) {
    return fetchData({
        url: `/managers`,
        method: "POST",
        data: body,
    });
}
