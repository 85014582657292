import React, { useCallback, useState } from "react";
import moment from "moment";
import { SelectChangeEventHandler } from "../../types/common";
// Components
import CustomDatePickers from "../components/CustomDatePickers";
import useQuery from "../../hooks/useQuery";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  search_bar_container: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width:'100%',
    margin:"auto",
    backgroundColor: "white",
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    [theme.breakpoints.down("sm")]: {
      margin:"10px 0",
    },
  },
  filter_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "14px",
  },
  iconButton: {
    padding: 10,
  },
  icon: {
    color: theme.palette.background.default,
  },
  date_picker_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    backgroundColor: "white",
    borderRadius: "10px",
    margin: "0 5px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      justifyContent: "center",
    },
  },
}));
export interface StateOption {
  readonly value: string;
  readonly label: string;
}

type SearchProps = {
  startDate: string;
  endDate: string;
  searchParam: string|number;
};

type SearchDetailsProps = {
  disabledSearchBar?: boolean;
  sortingStatus?: number;
  handleChange?: (stateKey: string, event: SelectChangeEventHandler) => void | undefined;
  submitSearch?: (state: any) => void;
};

type DateParams = {
  key: string;
  value: string;
}

const CustomSearch: React.FC<SearchDetailsProps> = (props) => {
  const classes = useStyles();
  const { disabledSearchBar = false, handleChange, submitSearch } = props;
  const [query] = useQuery();
  const [mainState, _setMainState] = useState<SearchProps>({
    startDate: query.startDate || moment().subtract("3", "months").format("YYYY-MM-DD"),
    endDate: query.endDate || moment().format("YYYY-MM-DD"),
    searchParam: "",
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleDateChange = useCallback((event: any, key) => {
    const newValue = moment(event._d).format("YYYY-MM-DD");
    setMainState({ [key]: newValue });
    handleSearch({key, value: newValue})()
  }, [mainState]);

  const handleSearch = (params: DateParams|undefined = undefined) => () => {
    const {startDate, endDate, searchParam} = mainState;
    const reqParams = {
      startDate:  (params && params.key === 'startDate') ? params.value : startDate,
      endDate:  (params && params.key === 'endDate') ? params.value : endDate,
      searchParam
    }
    if (submitSearch) {
      submitSearch(reqParams)
    }
  };

  return (
    <React.Fragment>
      <div className={classes.filter_container}>
        {disabledSearchBar ? null : (
          <div className={classes.search_bar_container}>
            <InputBase
              value={mainState.searchParam}
              className={classes.input}
              placeholder="SEARCH..."
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => {
                setMainState({ searchParam: event.target.value });
              }}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              onClick={handleSearch()}
            >
              <SearchIcon className={classes.icon} />
            </IconButton>
          </div>
        )}
        <div className={classes.date_picker_container}>
          <CustomDatePickers
            stateKey="startDate"
            maxDate={mainState.endDate}
            value={mainState.startDate}
            handleChange={handleDateChange}
          />
          <p style={{ margin: "0", marginRight: "10px" }}>{"—"}</p>
          <CustomDatePickers
            stateKey="endDate"
            minDate={mainState.startDate}
            value={mainState.endDate}
            handleChange={handleDateChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomSearch;
