import React, { useCallback, useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";
import { routes } from "../configs/routes";
import moment from "moment";
import { useProps } from "../contexts";
import { NewsProps } from "../services/api/general";
import { SearchProps } from "../types/common";
import clsx from "clsx";
//Component
import CustomSearch from "./components/CustomSearch";
import DeletePopUp from "./components/Popups/DeletedPopUp";
import CustomTable from "./components/CustomTable";
import StatusText from "./components/StatusText";
import NewsPopUp from "./components/Popups/NewsPopUp";
import CustomButtons from "./components/Inputs/CustomButtons";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Hidden from "@material-ui/core/Hidden";
// Controllers
import {
  handleCreateNewsAPI,
  handleDeleteNewAPI,
  handleGetNewsListAPI,
  handleUpdateNewsAPI,
} from "../controller/SuperAdmin/NewsController";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 30,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  flex: {
    display: "flex",
  },
  sub_header_container: {
    flexDirection: "column",
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0",
    },
  },
  sub_header_title: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  flex_and_sub_header_title: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "title", label: "標題", align: "left" },
  { id: "newsContent", label: "描述", align: "left" },
  { id: "forceOrder", label: "置頂", align: "left" },
  { id: "createdAt", label: "創建日期", align: "left" },
  { id: "edit", label: "編輯", disabledSort: true, align: "center" },
  { id: "delete", label: "刪除", disabledSort: true, align: "center" },
];

const generateBody = (
  classes: any,
  body: Array<NewsProps>,
  page: number,
  rowsPerPage: number,
  handleDeleteDialog: any,
  handleEditProfile: any
) => {
  if (body.length === 0) {
    return [];
  } else {
    return body.map((e: NewsProps, i) => ({
      id: (page - 1) * rowsPerPage + i + 1,
      title: e.title,
      newsContent: e.content,
      forceOrder: <StatusText text={e.forceOrder ? "是" : "否"} status={e.forceOrder ? 0 : 1} />,
      createdAt: moment(e.createdAt).format("YYYY-MM-DD"),
      edit: (
        <IconButton aria-label="inspect" onClick={() => handleEditProfile(i)}>
          <EditIcon />
        </IconButton>
      ),
      delete: (
        <IconButton aria-label="inspect" onClick={handleDeleteDialog(e.ID, e.title)}>
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      ),
    }));
  }
};
type NewsAllProps = SearchProps & {
  // list: Array<NewsProps>;
  list: [];
  totals: number;
  selectedItems: NewsProps;
  deleteDialogID: number;
  deleteDialogContent: string;
  refreshList: boolean;
};

const NewList = () => {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [mainState, _setMainState] = useState<NewsAllProps>({
    list: [],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 100,
    order: query.sortBy || "desc",
    orderBy: query.sort || "createdAt",
    selectedItems: {
      ID: 0,
      title: "",
      content: "",
      order: 0,
      forceOrder: false,
      createdAt: "",
    },
    deleteDialogID: 0,
    deleteDialogContent: "",
    refreshList: false,
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = (event: any, key: string) => {
    const { selectedItems } = mainState;
    const tmpData: any = selectedItems;
    tmpData[key] = event.target.value;
    setMainState({ selectedItems: tmpData });
  };

  const handleCheck = (key: string) => (event: any) => {
    const { selectedItems } = mainState;
    const tmpData: any = selectedItems;
    tmpData[key] = event.target.checked;
    setMainState({ selectedItems: tmpData });
  };

  const handleSearch = async (state: any) => {
    const extraFields = {
      startDate: state.startDate,
      endDate: state.endDate,
    };

    await fetchList(extraFields);
  };

  //API
  const fetchList = async (extraFields: any = undefined) => {
    handleGetNewsListAPI(mainState, extraFields, setMainState, _handleChange, setQuery);
  };

  const handleDeleteDialog = useCallback(
    (id: number, name: string) => () => {
      setMainState({ deleteDialogID: id, deleteDialogContent: name });
      setOpenDeleteDialog(true);
    },
    []
  );

  const handleEditProfile = (id: number | undefined, isNew = false) => {
    let obj = { ID: 0, title: "", content: "", forceOrder: false, createdAt: "" }; //init
    if (id !== undefined) obj = mainState.list[id];

    setMainState({ selectedItems: obj });
    setIsNew(isNew);
    setOpenEditDialog(true);
  };

  const handleSubmit = async () => {
    const { ID, title, content, order, forceOrder } = mainState.selectedItems;
    if (isNew) {
      await handleCreateNewsAPI(title, content, forceOrder, _handleChange);
    } else {
      let params = { title: title, content: content, order: order, forceOrder: forceOrder };
      await handleUpdateNewsAPI(ID, params, _handleChange);
    }
    setMainState({ refreshList: !mainState.refreshList });
    setOpenEditDialog(false);
  };

  const handleCloseNewsPopUp = () => {
    setOpenEditDialog(false);
  };

  const handleDeleteNews = async () => {
    await handleDeleteNewAPI(mainState.deleteDialogID, _handleChange);
    setMainState({ refreshList: !mainState.refreshList });
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    fetchList();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList]);

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limits,
    // handleChangeDataSelect,
    handleDeleteDialog,
    handleEditProfile
  );

  return (
    <React.Fragment>
      <div className={clsx(classes.flex, classes.sub_header_container)}>
        <div className={clsx(classes.flex, classes.sub_header_title)}>
          <Typography variant="h1">{routes.navBar.newList.text}</Typography>
          <Hidden only={["md", "lg", "xl"]}>
            <CustomButtons
              label="新增消息"
              customStyle={{ marginLeft: "30px" }}
              handleClick={() => handleEditProfile(undefined, true)}
            />
          </Hidden>
        </div>
        <div className={clsx(classes.flex_and_sub_header_title)}>
          <CustomSearch disabledSearchBar submitSearch={handleSearch} />
          <Hidden only={["xs", "sm"]}>
            <CustomButtons
              label="新增消息"
              customStyle={{ marginLeft: "30px" }}
              handleClick={() => handleEditProfile(undefined, true)}
            />
          </Hidden>
        </div>
      </div>
      <div className={classes.wrapper}>
        <CustomTable
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totals}
          rowsPerPage={mainState.limits}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>

      <NewsPopUp
        isCreate={isNew}
        isOpen={openEditDialog}
        newsID={mainState.selectedItems.ID}
        title={mainState.selectedItems.title}
        content={mainState.selectedItems.content}
        order={mainState.selectedItems.order}
        forceOrder={mainState.selectedItems.forceOrder}
        maxNumber={mainState.totals}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleClose={handleCloseNewsPopUp}
        handleSubmit={handleSubmit}
      />

      <DeletePopUp
        itemName={mainState.deleteDialogContent}
        isOpen={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        handleSubmit={handleDeleteNews}
      />
    </React.Fragment>
  );
};

export default NewList;
