import React from "react";
import clsx from "clsx";
import { DialogProps } from "../../../types/common";

//Component
import CustomTextField from "../Inputs/CustomTextField";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//Img
import close_icon from "../../../assets/001-close.svg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "20px",
      backgroundColor: "white",
    },
  },
  dialog_container: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    // minHeight: "50vh",
    backgroundColor: "white",
    borderRadius: "50px",
  },
  icon_container: {
    alignSelf: "flex-end",
  },
  icon: {
    cursor: "pointer",
    width: "20px",
    "&:hover ": {
      filter: "invert(35%) sepia(55%) saturate(3225%) hue-rotate(338deg) brightness(87%) contrast(116%)",
    },
  },
  input_container: {
    display: "flex",
    padding: "20px 0px 20px 0px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  input_title: {
    margin: "5px",
    marginBottom: "25px",
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  img_upload_file: {
    margin: "0px 0px 0px 10px",
    fontSize: "15px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
  },
  input_form_container: {
    margin: "0px 0px 0px 0px",
    width: "200px",
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
  },
  checkboxWrapper: {
    textAlign: "right",
    "& span": {
      fontSize: 16,
    },
  },
  back_button: {
    alignSelf: "center",
    marginTop: "5px",
    width: "120px",
    padding: "8px 6px",
    color: "black",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  submit_button: {
    marginLeft: "20px",
    alignSelf: "center",
    marginTop: "5px",
    width: "120px",
    padding: "8px 6px",
    color: "white",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  dialog_inner_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 10px 30px 10px",
    [theme.breakpoints.up("lg")]: {
      padding: "0px 100px 30px 100px",
    },
  },
  button_container: {
    marginTop: "20px",
    textAlign: "center",
  },
}));

type Props = DialogProps & {
  newsID: number;
  title: string;
  content: string;
  order: number;
  forceOrder: boolean;
  isOpen: boolean;
  isCreate?: boolean;
  maxNumber?:number;
  handleClose: () => void;
  handleChange: (event: any, key: string) => void;
  handleCheck: any;
  handleSubmit: () => void;
};
const NewsPopUp: React.FC<Props> = (props) => {
  const {
    isOpen,
    newsID,
    title,
    content,
    order,
    forceOrder,
    isCreate,
    maxNumber,
    handleClose,
    handleChange,
    handleCheck,
    handleSubmit,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog_container}>
        <div className={classes.icon_container}>
          <img className={classes.icon} src={close_icon} onClick={handleClose} alt="close-icon" />
        </div>
        <div className={classes.dialog_inner_container}>
          <div className={classes.input_container}>
            <p className={classes.input_title}>
              {isCreate ? "新增" : "編輯"}
              消息
            </p>
            <div className={classes.input_form_container}>
              <CustomTextField
                label="標題"
                value={title}
                type="textArea"
                characterLimit={35}
                rows={1}
                stateKey="title"
                handleChange={handleChange}
                fullWidth={true}
              />
              <CustomTextField
                label="描述"
                type="textArea"
                characterLimit={75}
                value={content}
                rows={3}
                stateKey="content"
                handleChange={handleChange}
                fullWidth={true}
              />
              <CustomTextField
                label="排序"
                type="number"
                value={order}
                stateKey="order"
                maxNumber={maxNumber}
                handleChange={handleChange}
              />
              <div className={classes.checkboxWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forceOrder}
                      onChange={handleCheck("forceOrder")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="置頂"
                />
              </div>
            </div>
          </div>
          <div className={classes.button_container}>
            <Button className={classes.back_button} onClick={handleClose}>
              {/*{languagePackage.BACK}*/}
              取消
            </Button>
            <Button
              className={classes.submit_button}
              variant="contained"
              color={"primary"}
              onClick={handleSubmit}
            >
              {/*{languagePackage.CONFIRM_DELETE}*/}
              {isCreate ? "提交" : "更新"}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NewsPopUp;
