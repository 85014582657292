import { useMemo, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
const queryString = require("query-string");

function useQuery() {
    const routerHistory = useHistory();
    const routerLocation = useLocation();

    const { search } = routerLocation;
    const currentState = useMemo(() => queryString.parse(search), [search]);

    const setState = useCallback(
        (newState = {}) => {
            const { search, pathname } = routerLocation;
            const currentState = queryString.parse(search);
            const newParams = queryString.stringify(
                {
                    ...currentState,
                    ...newState,
                },
                { skipNull: true }
            );
            routerHistory.replace(`${pathname}?${newParams}`);
        },
        [routerLocation, routerHistory]
    );

    return [currentState, setState];
}

export default useQuery;
