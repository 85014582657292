import fetchData from "../../utils/fetchData";

export type FinancialDetailsProps = {
  ID: number;
  amount: number;
  coins: number;
  status: number;
  createdAt: string;
  companyID: number;
  companyName: string;
  referralList: boolean;
};

export type FinancialProps = {
  list: Array<FinancialDetailsProps>;
  totals: number;
};
export function getFinancialPagination(params: any) {
  return fetchData({
    url: "/financial",
    method: "GET",
    params: params,
  });
}
