import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { routes } from "../../configs/routes";
import configVariables from "../../configs/variables.json";
// Components
import useQuery from "../../hooks/useQuery";
import { getRoutesWithPath } from "../../utils";
import { useProps } from "../../contexts";
import CustomTable from "../components/CustomTable";
import { SelectChangeEventHandler } from "../../types/common";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import CustomSearch from "../components/CustomSearch";
// Controllers
import {handleGetCompanyListAPI, updateCompanyAdminStatus} from "../../controller/CompanyController";
import { CompanyDetailsProps } from "../../services/api/company";
import AmountText from "../components/AmountText";
import CustomSelect from "../components/Inputs/CustomSelect";

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  // { id: "username", label: "帳號", align: "left" },
  { id: "name", label: "姓名", align: "left" },
  { id: "companyName", label: "企業名稱", align: "left" },
  { id: "coins", label: "點數", align: "left" },
  { id: "status", label: "狀態", align: "left" },
  { id: "createdAt", label: "登入日期", align: "left" },
  { id: "edit", label: "檢視", disabledSort: true, align: "left" },
];

const getTextStatus = (list: { label: string; value: number }[], statusID: number) => {
  let status;

  list.map((item, value) => {
    if (item.value === statusID) status = item.label.toString();
  });

  return status;
};

const generateBody = (
  classes: any,
  body: Array<CompanyDetailsProps>,
  page: number,
  rowsPerPage: number,
  handleChange: any,
  handleEditProfile: any
) => {
  return body.map((e:CompanyDetailsProps, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    // username: e.username,
    name: e.name,
    companyName: e.companyName,
    coins: <AmountText amount={e.coins} onlyUnit />,
    // status: getTextStatus(configVariables.userStatus, e.status),
    status: <CustomSelect
        stateKey="status"
        value={e.status}
        items={e.status === configVariables.adminStatus[0].value ? configVariables.adminStatus :configVariables.adminStatusActive}
        handleChange={handleChange(i, e.adminID)}
        autoWidth
        incline
    />,
    createdAt: moment(e.createdAt).format("DD/MM/YYYY"),
    edit: (
      <IconButton aria-label="inspect" onClick={()=>handleEditProfile(e.companyID)}>
        <Visibility />
      </IconButton>
    ),
  }));
};

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    marginTop: 30,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  sub_header_container: {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    padding:"0",
    alignItems: "center",
    [theme.breakpoints.down("sm")]:{
      flexDirection:"column",
      padding:"10px",
      alignItems: "normal",
    },
  },
}));

const CompanyList = () => {
  const classes = useStyles();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [query, setQuery] = useQuery();
  const [mainState, _setMainState] = useState({
    list:[],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
    startDate: query.startDate || moment().subtract("3", "months").format("YYYY-MM-DD"),
    endDate: query.endDate || moment().format("YYYY-MM-DD"),
    deleteDialogID: 0,
    deleteDialogContent: "",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChangeDataSelect = useCallback(
      (index, adminID) => async (event: SelectChangeEventHandler, prop: string) => {
        const { list } = mainState;
        const tmpData: any = [...list];
        const value = event.target.value;
        tmpData[index][prop] = value;

        // API
        const updateObj = {ID: adminID, status: value};
        await updateCompanyAdminStatus(updateObj, setMainState, _handleChange)

        setMainState({ list: tmpData });
      },
      [mainState.list, setMainState]
  );

  const handleEditProfile = (id: number) => {
    history.push(getRoutesWithPath(routes.navBar.companyProfile.path, [id]));
  };

  const handleSearch = async (state: any) => {
    const extraFields = {
      startDate: state.startDate,
      endDate: state.endDate,
      search: state.searchParam,
    }

    await fetchList(extraFields);
  }

  //API
  const fetchList = async (extraFields: any=undefined) => {
    handleGetCompanyListAPI(mainState, extraFields, setMainState, _handleChange, setQuery);
  }

  useEffect(() => {
    fetchList();
  }, [mainState.page,mainState.order, mainState.orderBy])

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limits,
    handleChangeDataSelect,
    handleEditProfile
  );

  return (
    <React.Fragment>
      <div className={classes.sub_header_container}>
        <Typography variant="h1">{routes.navBar.company.text}</Typography>
        <CustomSearch submitSearch={handleSearch}/>
      </div>
      <div className={classes.contentWrapper}>
        <CustomTable
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totals}
          rowsPerPage={mainState.limits}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>
    </React.Fragment>
  );
};

export default CompanyList;
