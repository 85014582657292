import React, { useCallback, useState } from "react";
import { AccountProps, BusinessManagerProps, DetailsProps } from "../../types/user";
import { useProps } from "../../contexts";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../types/common";
//Componnet
import CustomTextField from "../components/Inputs/CustomTextField";
import EditAccountPopUp from "../components/Popups/EditAccountPopUp";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
//Controller
import { HandleGetInitAdminAccData } from "../../controller/SuperAdmin/CompanyAdminAccController";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
    }
  },
  form_inner_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  sub_account_container: {
    overflow: "auto",
    overflowX: "hidden",
    maxHeight: "250px",
    minHeight: "60px",
  },
  edit_sub_account_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.gray.light,
    borderRadius: "10px",
    // maxWidth: "110px",
    width: "100%",
    height: "30px",
    marginBottom: "10px",
    padding: "8px 10px",
    minWidth: "160px",
  },
  edit_sub_account_dummy_container: {
    opacity: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  edit_sub_account_text: {
    margin: 0,
    width: "200px",
  },
  edit_name_hidden: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  sub_title: {
    fontSize: "15px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  no_margin:{
    margin:0,
  },
  sub_title_account: {
    color: theme.palette.primary.main,
  },
  sub_header_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 0px 10px 0px",
  },
}));
type SubmitProps = DetailsProps & AccountProps;
type EditBlockProps = {
  data: SubmitProps;
  index: number;
};
type AccountShowProps = {
  data: Array<DetailsProps & AccountProps>;
  isSales: boolean;
  handleOpenPopup: (isSales: boolean, isEdit: boolean, detailsObj?: SubmitProps, index?: number) => void;
};

const AccountShow: React.FC<AccountShowProps> = ({ data, isSales, handleOpenPopup }) => {
  const classes = useStyles();
  const { languagePackage } = useProps();
  const EditBlock: React.FC<EditBlockProps> = ({ data, index }) => {
    return (
       <div className={classes.edit_sub_account_container}>
        <p className={clsx(classes.sub_title, classes.edit_sub_account_text, classes.edit_name_hidden)}>
          {data.name}
        </p>
        <IconButton
          aria-label="toggle view visibility"
          onClick={() => handleOpenPopup(isSales, true, data, data.ID)}
          edge="end"
        >
          <Visibility />
        </IconButton>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className={classes.sub_account_container}>
        {/* No Record */}
        {!data || data.length === 0 ? (
          <div className={classes.edit_sub_account_container}>
            <p className={clsx(classes.sub_title,classes.no_margin)}>
              {languagePackage.NO_RECORD_FOUND}
            </p>
          </div>
        ) : null}
        <Grid direction="row" container spacing={1}>
          <Grid container item sm={12} md={12} lg={6}>
            {data && data.map((data, i) => (i % 2 === 0 ? <EditBlock key={`block-admin-${i}`} data={data} index={i} /> : <></>))}
          </Grid>
          <Grid container item sm={12} md={12} lg={6}>
            {data && data.map((data, i) => (i % 2 === 1 ? <EditBlock key={`block-contact-${i}`} data={data} index={i} /> : <></>))}
            {data && data.length % 2 === 1 ? (
              <div
                className={clsx(classes.edit_sub_account_container, classes.edit_sub_account_dummy_container)}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

type ManagerAllProps = BusinessManagerProps & {
  roleManage: [];
  roleContact: [];
};
const CompanyAdminAcc = () => {
  const classes = useStyles();
  const { companyID } = useParams<ParamTypes>();
  const { languagePackage, _handleChange } = useProps();
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const [isSales, setIsSales] = useState<boolean>(false);
  const [mainState, _setMainState] = useState<ManagerAllProps>({
    ID: 0,
    username: "",
    name: "",
    phoneNumber: "",
    idNumber: "",
    email: "",
    status: 0,
    roleManage: [],
    roleContact: [],
  });

  const [popupDetails, setPopupDetails] = useState<SubmitProps | undefined>({
    name: "",
    phoneNumber: "",
    email: "",
    idNumber: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  //API
  if (companyID !== undefined) {
    let ID: any = companyID;
    HandleGetInitAdminAccData(ID, setMainState, _handleChange);
  }

  const handleOpenPopup = (
    isSales: boolean,
    isEdit: boolean,
    detailsObj?: SubmitProps,
    index?: number | undefined
  ) => {
    setIsSales(isSales);
    setPopupDetails(detailsObj ? detailsObj : undefined);
    setPopupOpen(true);
  };
  const handleTextInput = (event: { target: { value: any } }, statusKey: string) => {
    const key = statusKey;
    let value = event.target.value;
    setMainState({ ...mainState, [key]: value });
  };

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <div className={classes.form_inner_container}>
            <CustomTextField
              label={languagePackage.ADMIN_NAME}
              type={"text"}
              stateKey={"name"}
              value={mainState.name}
              handleChange={handleTextInput}
              customStyle={{ margin: "0px" }}
              fullWidth
              disabled
            />
            <CustomTextField
              label={languagePackage.PHONE_NUMBER}
              type={"tel"}
              stateKey={"phoneNumber"}
              value={mainState.phoneNumber}
              handleChange={handleTextInput}
              disabled
            />
            <CustomTextField
              label={languagePackage.EMAIL}
              type={"email"}
              stateKey={"email"}
              value={mainState.email}
              handleChange={handleTextInput}
              fullWidth
              disabled
            />

            <CustomTextField
              label={languagePackage.IC_NUMBER}
              type={"text"}
              stateKey={"idNumber"}
              value={mainState.idNumber}
              handleChange={handleTextInput}
              fullWidth
              disabled
            />

            <CustomTextField
              label={languagePackage.USER_NAME}
              type={"text"}
              stateKey={"username"}
              value={mainState.username}
              handleChange={handleTextInput}
              fullWidth
              disabled
            />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.sub_header_container}>
            <p className={clsx(classes.sub_title_account, classes.sub_title,classes.no_margin)}>
              {languagePackage.SALES_REGISTER}
            </p>
          </div>
          <AccountShow data={mainState.roleContact} isSales={true} handleOpenPopup={handleOpenPopup} />

          <div className={classes.sub_header_container}>
            <p className={clsx(classes.sub_title_account, classes.sub_title)}>
              {languagePackage.PRODUCT_MANAGER_REGISTER}
            </p>
          </div>
          <AccountShow data={mainState.roleManage} isSales={false} handleOpenPopup={handleOpenPopup} />
        </Grid>
      </Grid>
      <EditAccountPopUp
        isRegister
        isSales={isSales}
        isOpen={isPopupOpen}
        accountDetails={popupDetails}
        handleClose={() => {
          setPopupOpen(false);
        }}
      />
    </div>
  );
};

export default CompanyAdminAcc;
