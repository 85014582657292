import React from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { getRoutesWithPath } from "../../utils";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ParamTypes } from "../../types/common";

const AntTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.light,
  },
}))(Tabs);

const useStyles = makeStyles((theme) => ({
  tabButton: {
    minWidth: 125,
    color: theme.palette.gray.dark,
    backgroundColor: theme.palette.gray.light,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    border: `1px solid ${theme.palette.gray.light}`,
    fontWeight: "bold",
    "&.Mui-selected": {
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: theme.palette.gray.main,
      // borderBottomColor: theme.palette.primary.light,
    },
  },
  activeTabButton: {
    border: `1px solid ${theme.palette.gray.main}`,
  },
  buttonWrapper: {
    "& .MuiTabs-indicator": {
      backgroundColor: 'transparent',
    },
  },
  tabWrapper: {
    zIndex: 0,
    paddingBottom: 0,
    border: `1px solid ${theme.palette.gray.main}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: "0 20px 20px 20px",
    [theme.breakpoints.down('md')]: {
      borderTopRightRadius: 0,
    },
  },
}));

type Headers = {
  active: boolean;
  label: string;
};

type TabBarProps = {
  activeTab: number;
  headers: Headers[];
  tabRoutes: string[];
  FirstTab?: React.ReactNode;
  SecondTab?: React.ReactNode;
  ThirdTab?: React.ReactNode;
  FourthTab?: React.ReactNode;
  FifthTab?: React.ReactNode;
  SixthTab?: React.ReactNode;
  SeventhTab?: React.ReactNode;
};
type SwicthProps = {
  params: number;
  FirstTab?: React.ReactNode;
  SecondTab?: React.ReactNode;
  ThirdTab?: React.ReactNode;
  FourthTab?: React.ReactNode;
  FifthTab?: React.ReactNode;
  SixthTab?: React.ReactNode;
  SeventhTab?: React.ReactNode;
};
const SwitchActiveTab: React.FC<SwicthProps> = (props) => {
  const classes = useStyles();
  const { params, FirstTab, SecondTab, ThirdTab, FourthTab, FifthTab, SixthTab, SeventhTab } = props;

  let tab;
  switch (params) {
    case 0:
      tab = FirstTab;
      break;

    case 1:
      tab = SecondTab;
      break;

    case 2:
      tab = ThirdTab;
      break;

    case 3:
      tab = FourthTab;
      break;

    case 4:
      tab = FifthTab;
      break;

    case 5:
      tab = SixthTab;
      break;

    case 6:
      tab = SeventhTab;
      break;

    default:
      tab = FirstTab;
  }
  return <div className={classes.tabWrapper}>{tab}</div>;
};
const TabBar: React.FC<TabBarProps> = (props) => {
  const {
    headers,
    activeTab,
    tabRoutes,
    FirstTab,
    SecondTab,
    ThirdTab,
    FourthTab,
    FifthTab,
    SixthTab,
    SeventhTab,
  } = props;
  const { companyID, memberID } = useParams<ParamTypes>();
  const history = useHistory();
  const classes = useStyles();

  const ID = companyID || memberID;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const redirectPath = tabRoutes[newValue];
    let path = getRoutesWithPath(redirectPath, [ID]);
    history.push(path);
  };

  return (
    <React.Fragment>
      <AntTabs
        value={activeTab}
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        className={classes.buttonWrapper}
        variant="scrollable"
        scrollButtons="off"
      >
        {headers.map((item, index) => (
          <Tab key={item.label}
               className={clsx((activeTab === index) && classes.activeTabButton, classes.tabButton)}
               disabled={!item.active}
               label={item.label}
          />
        ))}
      </AntTabs>

      <SwitchActiveTab
        params={activeTab}
        FirstTab={FirstTab}
        SecondTab={SecondTab}
        ThirdTab={ThirdTab}
        FourthTab={FourthTab}
        FifthTab={FifthTab}
        SixthTab={SixthTab}
        SeventhTab={SeventhTab}
      />

    </React.Fragment>
  );
};

export default TabBar;
