import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

type BreadcrumbCellProps = {
  title: string;
  link: string | undefined;
  hidden: boolean;
};

type BreadcrumbProps = {
  data: Array<BreadcrumbCellProps>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  link_title: {
    fontSize: "13px",
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.info.main,
  },
}));

const CustomBreadcrumbs: React.FC<BreadcrumbProps> = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb"> */}
      <Breadcrumbs aria-label="breadcrumb">
        {data.map((i, index) => {
          if (i.link && !i.hidden) {
            return (
              <Link key={index} className={classes.link_title} href={i.link}>
                {i.title}
              </Link>
            );
          } 
          else if (!i.link && i.hidden) {
              <span key={index} className={classes.link_title}>
                {i.title}
              </span>
          }

          else {
            return null;
          }
          // else {
          //   return (
          //     <span key={index} className={classes.link_title}>
          //       {i.title}
          //     </span>
          //   );
          // }
        })}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumbs;
