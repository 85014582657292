import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Typography } from "@material-ui/core";
import { InputChangeEventHandler, OnClickEventHandler } from "../../types/common";
import Pagination from "@material-ui/lab/Pagination";

type StateProps = {
  page?: number;
  rowsPerPage?: number;
  order?: string;
  orderBy?: string;
};

type TableProps = {
  header: any[];
  body: any[];
  page: number;
  rowsPerPageOptions: number[];
  count: number;
  rowsPerPage: number;
  noBorder?: boolean;
  // order: "asc" | "desc" | undefined;
  order: any;
  orderBy: string;
  isLimitCol?: boolean;
  setState: (p: StateProps) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  container: {
    // maxHeight: 440,
    // minWidth: "700px",
    width: "100%",
    // textAlign: "justify",
  },
  table_container: {
    overflow: "hidden",
  },
  headerCol: {
    backgroundColor: "transparent",
    fontWeight: "bold",
    color: theme.palette.gray.dark,
    padding: 0,
    "&.MuiTableCell-root": {
      borderBottom: "none",
      minWidth: 50,
      [theme.breakpoints.down("sm")]: {
        minWidth: 100,
      },
    },
  },
  firstHeaderColumn: {
    padding: 10,
  },
  emptyRow: {
    border: 0,
  },
  bodyRow: {
    backgroundColor: theme.palette.primary.light,
    "&.MuiTableRow-hover:hover": {
      backgroundColor: theme.palette.gray.light,
      // color: theme.palette.primary.main,
    },
  },
  bodyCell: {
    borderTop: 1,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    borderStyle: "solid",
    borderColor: theme.palette.gray.main,
    padding: "10px 4px",
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  indexRow: {
    width: 115,
  },
  firstBodyRow: {
    borderTop: 0,
  },
  firstBodyCell: {
    borderLeft: `1px solid ${theme.palette.gray.main}`,
  },
  lastBodyCell: {
    borderRight: `1px solid ${theme.palette.gray.main}`,
  },
  firstRowFirstBodyCell: {
    borderTopLeftRadius: 20,
  },
  firstRowLastBodyCell: {
    borderTopRightRadius: 20,
  },
  lastRowFirstBodyCell: {
    borderBottomLeftRadius: 20,
  },
  lastRowLastBodyCell: {
    borderBottomRightRadius: 20,
  },
  lastRowBody: {
    borderBottom: `1px solid ${theme.palette.gray.main}`,
  },
  visuallyHidden: {
    display: "none",
  },
  limit_hidden_col: {
    maxWidth: "150px",
    minWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  sortLabel: {
    "&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        // color: theme.palette.background.active
      },
    "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
      // color: theme.palette.background.active
    },
    "&:hover": {
      // color: theme.palette.gray.light
    },
    "&:focus": {
      // color: theme.palette.background.active
    },
  },
  pagination: {
    marginTop: 30,
    // marginRight: 20,
    marginBottom: -100,
    "& .MuiPagination-ul": {
      justifyContent: "flex-end",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function CustomTable(props: TableProps) {
  const {
    header,
    body,
    page,
    rowsPerPageOptions,
    count,
    rowsPerPage,
    noBorder = false,
    order,
    orderBy,
    isLimitCol,
    setState,
  } = props;
  const classes = useStyles();

  const totalPages = Math.ceil(count / rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setState({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: InputChangeEventHandler) => {
    setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleRequestSort = (event: OnClickEventHandler, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    let newOrder = isAsc ? "desc" : "asc";
    setState({ order: newOrder, orderBy: property });
  };

  const createSortHandler = (property: string) => (event: OnClickEventHandler) => {
    handleRequestSort(event, property);
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" className={clsx(classes.table_container)}>
          <TableHead>
            <TableRow>
              {header.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  // style={{ minWidth: column.minWidth }}
                  className={clsx(
                    index === 0 && classes.firstHeaderColumn,
                    classes.headerCol
                  )}
                >
                  {column.disabledSort ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      className={clsx(classes.sortLabel)}
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={column.disabledSort ? () => {} : createSortHandler(column.id)}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {column.label}
                      {!column.disabledSort && orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!body.length && (
              <TableRow>
                <TableCell className={classes.emptyRow} colSpan={header.length} align="center">
                  <Typography>No Data Found</Typography>
                </TableCell>
              </TableRow>
            )}
            {body.map((row, bodyIndex) => {
              return (
                <TableRow className={classes.bodyRow} hover role="checkbox" tabIndex={-1} key={row.id}>
                  {header.map((column, headerIndex) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={clsx(
                          classes.bodyCell,
                          (column.id === "newsContent" || column.id === "memberName") && classes.limit_hidden_col,
                          bodyIndex === 0 && noBorder && classes.firstBodyRow,
                          bodyIndex === 0 && headerIndex === 0 && classes.indexRow,
                          headerIndex === 0 && !noBorder && classes.firstBodyCell,
                          headerIndex === header.length - 1 && !noBorder && classes.lastBodyCell,
                          bodyIndex === 0 && headerIndex === 0 && !noBorder && classes.firstRowFirstBodyCell,
                          bodyIndex === 0 &&
                            headerIndex === header.length - 1 &&
                            !noBorder &&
                            classes.firstRowLastBodyCell,
                          bodyIndex === body.length - 1 &&
                            !noBorder &&
                            classes.lastRowBody,
                          bodyIndex === body.length - 1 &&
                            headerIndex === 0 &&
                            // !noBorder &&
                            classes.lastRowFirstBodyCell,
                          bodyIndex === body.length - 1 &&
                            headerIndex === header.length - 1 &&
                            // !noBorder &&
                            classes.lastRowLastBodyCell
                        )}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={clsx(totalPages > 0 && classes.pagination)}
        page={page}
        count={totalPages}
        onChange={handleChangePage}
        defaultPage={1}
        hideNextButton
        hidePrevButton
      />
    </Paper>
  );
}
