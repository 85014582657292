import { getFinancialPagination } from "../services/api/financial";
import { apiMiddleware } from "../utils/middleware";

export const handleGetFinancialAPI = async (
    mainState: any,
    setMainState: any,
    changeContext: any,
    setQuery: any
) => {
  changeContext({ changeContext: true });

  const { page, limits, order, orderBy } = mainState;
  const params = {
    page: page,
    limits: limits,
    order: order,
    orderBy: orderBy,
  };

  const { success, data } = await apiMiddleware(changeContext, getFinancialPagination, params);

  if (!success) return;
  setQuery(params)
  setMainState({ list: data.data, totalRevenue: data.totalRevenue, totals: data.totals });
};
