import {
  checkCompanyStatus,
  getBillingsPagination,
  getCompanyPagination,
  getCompanyProfile,
  getReferralBillingsPagination,
  getReferralList, updateAdminStatus,
} from "../services/api/company";
import { apiMiddleware } from "../utils/middleware";

const contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetCompanyListAPI = async (
    mainState: any,
    extraFields: any,
    setMainState: any,
    changeContext: any,
    setQuery: any
) => {
  changeContext({ openBackdrop: true });

  const { page, limits, order, orderBy } = mainState;
  let params = {
    page: page,
    limits: limits,
    order: order,
    orderBy: orderBy,
  };

  if (extraFields) params = {...params, ...extraFields}

  const { success, data } = await apiMiddleware(changeContext, getCompanyPagination, params);

  if (!success) return;
  setQuery(params)
  setMainState({ list: data.data, totals: data.totals });
};

export const handleGetCompanyProfileAPI = async (
  companyID: number,
  mainState: any,
  setMainState: any,
  changeContext: any,
) => {
  changeContext({ openBackdrop: true });

  const { success, data } = await apiMiddleware(changeContext, getCompanyProfile, companyID);

  if (!success) return;
  setMainState({ ...data });
};

export const handleGetBillingListAPI = async (
  companyID: number,
  mainState: any,
  setMainState: any,
  changeContext: any
) => {
  changeContext({ openBackdrop: true });

  const { page, limits, order, orderBy } = mainState;
  const params = {
    companyID: companyID,
    pageDetails: {
      page: page,
      limits: limits,
      order: order,
      orderBy: orderBy,
    },
  };

  const { success, data } = await apiMiddleware(changeContext, getBillingsPagination, params);
  if (!success) return;
  setMainState({ list: data.data, totals: data.totals, balanceCoin: data.balance});
};

export const handleGetReferralListAPI = async (companyID: number, setMainState: any, changeContext: any) => {
  changeContext({ openBackdrop: true });

  const { success, data } = await apiMiddleware(changeContext, getReferralList, companyID);
  if (!success) return;

  setMainState({ list: data });
};

export const handleGetReferralBillingListAPI = async (
  companyID: number,
  mainState: any,
  setMainState: any,
  changeContext: any
) => {
  changeContext({ openBackdrop: true });

  const { page, limits, order, orderBy } = mainState;
  const params = {
    companyID: companyID,
    pageDetails: {
      page: page,
      limits: limits,
      order: order,
      orderBy: orderBy,
    },
  };

  const { success, data } = await apiMiddleware(changeContext, getReferralBillingsPagination, params);

  if (!success) return;
  setMainState({ list: data.data, totals: data.totals });
};

export const updateCompanyAdminStatus = async (
    updateObj: any,
    setMainState: (params: any) => void,
    changeContext: any
) => {
  const { success } = await apiMiddleware(changeContext, updateAdminStatus, updateObj);
  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "Successfully updated!";
  changeContext(contextParams);
}
export const handleCheckCompanyStatusAPI = async (
  ID: number | undefined,
  setMainState: any,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, checkCompanyStatus, ID);
  if (!success) return;
  setMainState({ allowDelete: data.allowDelete, status: data.status });
};
