import { useEffect } from "react";
import { getSeries, getSeriesPagination } from "../services/api/company";
import { apiMiddleware } from "../utils/middleware";

export const HandleGetInitSeriesData = (
  companyID: number,
  mainState: any,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  useEffect(() => {
    handleGetSeriesListAPI(companyID, mainState, setMainState, changeContext);
  }, [mainState.page,mainState.order, mainState.orderBy])
};
export const handleGetSeriesListAPI = async (
  companyID: number,
  mainState: any,
  setMainState: any,
  changeContext: any
) => {
  changeContext({ openBackdrop: true });

  const { page, limits, order, orderBy } = mainState;
  const params = {
    companyID: companyID,
    pageDetails: {
      page: page,
      limits: limits,
      order: order,
      orderBy: orderBy,
    },
  };
  const { success, data } = await apiMiddleware(changeContext, getSeriesPagination, params);

  if (!success) return;

  const tmpData = data.data.map((e: any) => ({
    seriesID: e.seriesID,
    businessID: e.businessID,
    name: e.name,
    imageUrl: e.imageUrl,
    isZoomed: false,
    totalProduct: e.totalProduct,
    createdAt: e.createdAt,
  }))

  setMainState({ list: tmpData, totals: data.totals });
};

export const handleGetSeriesAPI = async (
    seriesID: number,
    setMainState: any,
    changeContext: any
  ) => {
    changeContext({ openBackdrop: true });

    const { success, data } = await apiMiddleware(changeContext, getSeries, seriesID);

    if (!success) return;
    setMainState({ list: data, totals: data.totals });
  };
