import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../types/common";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
//Component
import CustomTextField from "../components/Inputs/CustomTextField";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useProps } from "../../contexts";
import { IndividualBankProps } from "../../types/user";
// Controllers
import { getMemberBank } from "../../controller/MemberController";
import { Typography } from "@material-ui/core";

// TODO: Show ID photo

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
    }
  },
  form_side_container: {
    flex: 0.45,
  },
  input_container: {
    margin: "20px 0px",
  },
  imageBox: {
    marginTop: 23,
    marginBottom: 60,
  },
  imageBoxTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  imageBoxDescription: {
    fontSize: 18,
  },
  icPhoto: {
    width: 100,
  },
}));

const MemberBankAccount = () => {
  const classes = useStyles();
  const { memberID } = useParams<ParamTypes>();
  const { _handleChange } = useProps();
  const { languagePackage } = useProps();

  const [mainState, _setMainState] = useState<IndividualBankProps>({
    bankName: "",
    bankBranch: "",
    bankAccount: "",
    bankAccountName: "",
    bankAccountIDCard: "",
    frontIdPhoto: "",
    backIdPhoto: "",
    photos: [],
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleTextInput = (event: any, statusKey: string) => {
    setMainState({ ...mainState, [statusKey]: event.target.value });
  };

  useEffect(() => {
    getMemberBank(memberID, setMainState, _handleChange);
  }, [memberID]);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant="h2">銀行資訊</Typography>
            <div className={classes.form_side_container}>
              <div className={classes.input_container}>
                <CustomTextField
                  label={languagePackage.BANK_NAME}
                  value={mainState.bankName}
                  stateKey={"bankName"}
                  handleChange={handleTextInput}
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className={classes.input_container}>
                <CustomTextField
                  label={languagePackage.BANK_BRANCH}
                  value={mainState.bankBranch}
                  stateKey={"bankBranch"}
                  handleChange={handleTextInput}
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className={classes.input_container}>
                <CustomTextField
                  label={languagePackage.BANK_NUMBER}
                  value={mainState.bankAccount}
                  stateKey={"bankAccount"}
                  handleChange={handleTextInput}
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className={classes.input_container}>
                <CustomTextField
                  label={languagePackage.BANK_USER_NAME}
                  value={mainState.bankAccountName}
                  stateKey={"bankAccountName"}
                  handleChange={handleTextInput}
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className={classes.input_container}>
                <CustomTextField
                  label={languagePackage.IC_NUMBER}
                  value={mainState.bankAccountIDCard}
                  stateKey={"bankAccountIDCard"}
                  handleChange={handleTextInput}
                  fullWidth={true}
                  disabled
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant="h2">上傳證件</Typography>
            <div className={classes.imageBox}>
              <Typography className={classes.imageBoxTitle}>身分證正面圖示:</Typography>
              {mainState.photos[0] ?
                  <Zoom>
                    <img alt="id-photo" src={mainState.photos[0]} className={classes.icPhoto} />
                  </Zoom>
                  :
                  <Typography className={classes.imageBoxDescription}>未上傳圖片</Typography>
              }
            </div>

            <div className={classes.imageBox}>
              <Typography className={classes.imageBoxTitle}>身分證反面圖示:</Typography>
              {mainState.photos[1] ?
                  <Zoom>
                    <img alt="id-photo" src={mainState.photos[1]} className={classes.icPhoto} />
                  </Zoom>
                  :
                  <Typography className={classes.imageBoxDescription}>未上傳圖片</Typography>
              }
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default MemberBankAccount;
