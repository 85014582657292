import React, {useCallback} from "react";
import {useHistory} from "react-router";
import {routes} from "../../configs/routes";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

type BackButtonProps = {
    to?: string;
};

const useStyles = makeStyles((theme) => ({
    button: {
        margin: 0,
        padding: 0,
        fontSize: 22,
        color: theme.palette.gray.dark,
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        }
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: '34px !important',
    },
}));

const BackButton: React.FC<BackButtonProps> = (props) => {
    const {to=routes.navBar.dashboard.path} = props;
    const classes = useStyles();
    const history = useHistory();

    const handleChangePage = useCallback(() => {
        history.push(to)
    }, [history, to])

    return (
        <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<KeyboardArrowLeftIcon className={classes.icon} />}
            onClick={handleChangePage}
        >
            返回
        </Button>
    );
};

export default BackButton;
