import React, {useState, useCallback, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {routes} from "../configs/routes";
// Components
import {useProps} from "../contexts";
import useQuery from "../hooks/useQuery";
import CustomTable from "./components/CustomTable";
import CustomButtons from "./components/Inputs/CustomButtons";
import DeletePopUp from "./components/Popups/DeletedPopUp";
import IndustryPopup from "./components/Popups/IndustryPopup";
// Material UI
import {
    Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// Controllers
import {
    createIndustry,
    getIndustryDetails,
    getIndustryList,
    updateIndustryDetails,
    deleteIndustry,
    IListBody,
} from "../controller/IndusctryController";

const header = [
    {id: 'id', label: '序號', disabledSort: true, align: 'center'},
    {id: 'name', label: '名稱', align: 'left'},
    {id: 'description', label: '敘述', align: 'left'},
    {id: 'createdAt', label: '創建日期 時間', align: 'left'},
    {id: 'edit', label: '編輯', disabledSort: true, align: 'center'},
    {id: 'delete', label: '刪除', disabledSort: true, align: 'center'},
];

const useStyles = makeStyles(theme => ({
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    wrapper: {
        marginTop: 30,
    },
    addIcon: {
        fontSize: 35,
        color: theme.palette.secondary.main,
    },
    deleteIcon: {
        color: theme.palette.primary.main,
    }
}))

const generateBody = (
    classes: any, body: IListBody[], page: number, rowsPerPage: number, handleDialogOpen: any, handleDeleteDialog: any
) => {
    return body.map((e, i) => ({
        id: ((page - 1) * rowsPerPage) + i + 1,
        name: e.name,
        description: e.description,
        createdAt: e.createdAt,
        edit: <IconButton aria-label="inspect" onClick={handleDialogOpen(1, e.id)}>
            <EditIcon/>
        </IconButton>,
        delete: <IconButton aria-label="inspect" onClick={handleDeleteDialog(e.id, e.name)}>
            <DeleteIcon className={classes.deleteIcon}/>
        </IconButton>
    }))
}

const Industry = () => {
    const classes = useStyles();
    const {_handleChange} = useProps();
    const [query, setQuery] = useQuery();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [mainState, _setMainState] = useState({
        page: (query.offset) ? parseInt(query.offset) : 1,
        rowsPerPage: (query.limit) || 10,
        totalPageItem: 10,
        order: (query.sortBy) || 'desc',
        orderBy: (query.sort) || 'createdAt',
        bodyData: [],
        dialogType: 0,
        dialogID: 0,
        name: '',
        description: '',
        deleteDialogID: 0,
        deleteDialogContent: '',
        refreshList: false,
    })

    const setMainState = useCallback((newState) => {
        _setMainState(prevState => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    const handleChange = useCallback((event,prop) => {
        setMainState({[prop]: event.target.value});
    }, [setMainState]);

    const handleDialogOpen = useCallback((type: number, id: any=undefined) => async () => {
        const state: any = {dialogType: type}
        if (type === 1) {
            await handleGetIndustry(id);
        } else {
            state.name = '';
            state.description = '';
        }

        setMainState(state)
        setOpenDialog(true)
    }, [setMainState]);

    const handleDeleteDialog = useCallback((id: number, name: string) => () => {
        setMainState({deleteDialogID: id, deleteDialogContent: name})
        setOpenDeleteDialog(true)
    }, [setMainState])

    const handleUpdate = async () => {
        await updateIndustryDetails(mainState, _handleChange);
        setMainState({name: '', description: '', refreshList: !mainState.refreshList})
        setOpenDialog(false)
    }

    const handleSubmit = async () => {
        await createIndustry(mainState, _handleChange);
        setMainState({name: '', description: '', refreshList: !mainState.refreshList})
        setOpenDialog(false)
    }

    const handleDelete = async () => {
        await deleteIndustry(mainState, _handleChange);
        setMainState({refreshList: !mainState.refreshList})
        setOpenDeleteDialog(false)
    }

    // API
    const handleGetIndustry = async (id: number) => {
        await getIndustryDetails(id, setMainState, _handleChange);
    }

    const fetchData = async () => {
        const {page, rowsPerPage, order, orderBy}  = mainState;
        const params = {
            page,
            limits: rowsPerPage,
            order,
            orderBy
        }
        await getIndustryList(params, setMainState, _handleChange);
        setQuery(params);
    }

    useEffect(() => {
        fetchData();
    }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList])

    const body = generateBody(classes, mainState.bodyData, mainState.page, mainState.rowsPerPage, handleDialogOpen, handleDeleteDialog);

    return (
        <React.Fragment>
            <div className={classes.headerWrapper}>
                <Typography variant="h1">{routes.navBar.industry.text}</Typography>
                <CustomButtons
                    label="新增產業"
                    handleClick={handleDialogOpen(0)}
                />
            </div>
            <div className={classes.wrapper}>
                <CustomTable
                    header={header}
                    body={body}
                    rowsPerPageOptions={[10, 25, 100]}
                    count={mainState.totalPageItem}
                    rowsPerPage={mainState.rowsPerPage}
                    page={mainState.page}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                />
            </div>
            <IndustryPopup
                name={mainState.name}
                descriptions={mainState.description}
                isOpen={openDialog}
                setOpen={setOpenDialog}
                type={mainState.dialogType}
                handleChange={handleChange}
                handleUpdate={handleUpdate}
                handleCreate={handleSubmit}
            />
            <DeletePopUp
                itemName={mainState.deleteDialogContent}
                isOpen={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                handleSubmit={handleDelete}
            />
        </React.Fragment>
    )
}

export default Industry;
