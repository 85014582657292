import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
  title: {
    fontSize: 16,
    margin: "10px 0",
  },
});

type ArrayProps = {
  value: number;
};
type SliderProps = {
  index: number;
  subTitle?: string;
  testValue: number;
  currentList: ArrayProps[];
  handleTotalValue: (event: any, index: number, value: number) => void;
  disabled?: boolean;
};

const getPrevTotal = (index: number, valueList: ArrayProps[]) => {
  let total: number = 0;
  if (total === 0) {
    for (let key in valueList) {
        if (key === index.toString()) {
        break;
      }
      total += valueList[key]["value"];
    }
  }
  return total;
};

const CustomSlider: React.FC<SliderProps> = (props) => {
  const { index, subTitle, testValue, currentList, handleTotalValue, disabled=false } = props;
  const classes = useStyles();
  let value: number = 0;
  let prevTotal = getPrevTotal(index, currentList);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    if (Number(newValue) <= 100 -prevTotal) {
      value = Number(newValue);
    }
    else {
      value = 100 - prevTotal;
    }
    handleTotalValue(event, index, Number(value));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "" && Number(event.target.value) <= 100 -prevTotal) {
      value = Number(event.target.value);
    }
    else {
      value = 100 - prevTotal;
    }
    handleTotalValue(event, index, Number(value));
  };

  const handleBlur = (event: any) => {
    if (testValue < 0) {
      handleTotalValue(event, index, Number(0));
    } else if (testValue > 100) {
      handleTotalValue(event, index, Number(100));
    }
  };

  return (
    <div className={classes.root}>
      {!disabled &&
        <Typography id="input-slider" className={classes.title}>
          第 {index+1} 層 <br /> 分潤百分比
        </Typography>
      }

      {!disabled && <Slider value={testValue} onChange={handleSliderChange} aria-labelledby="input-slider" />}
      <Input
        className={classes.input}
        value={testValue}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
          step: 10,
          min: 0,
          max: 100,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomSlider;
