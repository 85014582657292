import { useEffect } from "react";
import { getProduct, updateProductStatus } from "../services/api/company";
import { apiMiddleware } from "../utils/middleware";

export const HandleGetProductDetailsInitData = (
  productID: number,
  mainState: any,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  useEffect(() => {
    handleGetProductDetailsAPI(productID, setMainState, changeContext);
  }, [mainState.status]);
};

export const handleGetProductDetailsAPI = async (ID: number, setMainState: any, changeContext: any) => {
  changeContext({ changeContext: true });

  const { success, data } = await apiMiddleware(changeContext, getProduct, ID);

  if (!success) return;
  setMainState({ ...data });
};

export const handleChangeProductStatusAPI = async (
  productID: number,
  accessCode: string,
  status: number,
  setMainState: any,
  changeContext: any
) => {
  changeContext({ changeContext: true });

  let params = {
    productID: productID,
    details: {
      accessCode: accessCode,
      status: status,
    },
  };
  const { success, data } = await apiMiddleware(changeContext, updateProductStatus, params);

  if (!success) return;
  setMainState({ status: status });
};
