import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useProps } from "../../contexts";
import useQuery from "../../hooks/useQuery";
import { getRoutesWithPath } from "../../utils";
import {routes } from "../../configs/routes";
import { PageProps, ParamTypes } from "../../types/common";
import {ReferralListItemProps, ReferralListProps} from "../../types/user";
//Component
import CustomTable from "../components/CustomTable";
import RedirectText from "../components/RedirectText";
//Controller
import {getMemberReferralListAPI} from "../../controller/MemberController";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        fontSize: 24,
    },
    wrapper: {
        padding: "40px 0px",
    },
    deleteIcon: {
        color: theme.palette.primary.main,
    },
}));

const header = [
    { id: "id", label: "序號", disabledSort: true, align: "center" },
    { id: "businessName", label: "企業名稱", align: "left" },
    { id: "referralList", label: "列表會員", disabledSort: true, align: "left" },
    { id: "createdAt", label: "創建日期", align: "left" },
    { id: "view", label: "檢視", disabledSort: true, align: "left" },
];

const generateBody = (
    classes: any,
    body: ReferralListItemProps[],
    page: number,
    rowsPerPage: number,
    handleRedirect: any,
    handleRedirectBusiness: any,
) => {
    return body.map((e:ReferralListItemProps, i) => ({
        id: (page - 1) * rowsPerPage + i + 1,
        businessName: <RedirectText id={e.businessID} text={e.businessName} tooltip="查看企業" handleClick={handleRedirectBusiness} />,
        referralList: e.referralList,
        createdAt: e.createdAt,
        view: (
            <IconButton aria-label="inspect" onClick={handleRedirect(e.ID)}>
                <Visibility />
            </IconButton>
        ),
    }));
};

type ReferralAllProps = ReferralListProps & PageProps & {
    order: string;
    orderBy: string;
};

const MemberBranchList = () => {
    const classes = useStyles();
    const [query, setQuery] = useQuery();
    const history = useHistory();
    const {_handleChange} = useProps();
    const { memberID } = useParams<ParamTypes>();
    const [mainState, _setMainState] = useState<ReferralAllProps>({
        list:[],
        totals: 10,
        page: query.offset ? parseInt(query.offset) : 1,
        limits: query.limit || 10, //rowPerPage
        order: query.sortBy || "desc",
        orderBy: query.sort || "date",

    });

    const setMainState = useCallback((newState) => {
        _setMainState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    const handleRedirect = (ID: number) => () => {
        history.push(getRoutesWithPath(routes.navBar.memberBranchListDetails.path, [memberID, ID]));
    }

    const handleRedirectBusiness = (ID: number) => {
        history.push(getRoutesWithPath(routes.navBar.companyProfile.path, [ID]));
    }

    useEffect(() => {
        getMemberReferralListAPI(memberID, mainState, setMainState, _handleChange)
    }, [mainState.page, mainState.order, mainState.orderBy])

    const body = generateBody(
        classes,
        mainState.list,
        mainState.page,
        mainState.limits,
        handleRedirect,
        handleRedirectBusiness,
    );

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <CustomTable
                    noBorder={true}
                    header={header}
                    body={body}
                    rowsPerPageOptions={[10, 25, 100]}
                    count={mainState.totals}
                    rowsPerPage={mainState.limits}
                    page={mainState.page}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                />
            </div>
        </React.Fragment>
    );
};

export default MemberBranchList;
