import React, { useState, KeyboardEventHandler, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TextField, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import CopyToClipboard from "react-copy-to-clipboard";
import { InputChangeEventHandler, styleProps } from "../../../types/common";
import { useProps } from "../../../contexts";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type InputProps = {
  label?: string;
  type?: string;
  value?: string | number | null;
  placeholder?: string | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  invertTheme?: boolean;
  multiline?: boolean;
  rows?: number;
  customStyle?: styleProps;
  characterLimit?: number;
  isFlex?: boolean;
  handleKeyPress?: (event: any) => void;
  maxNumber?: number;
  // keyPress?: KeyboardEventHandler<HTMLDivElement> | undefined;
  // (...args: string[]) => void
  handleChange?: (event: InputChangeEventHandler | any, stateKey: string) => void;
  stateKey?: string;
  customClass?: styleProps;
};

type CountryData = {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginTop: 25,
  },
  disabled_font: {
    color: "-internal-light-dark(black, white)",
  },
  root: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      maxHeight: 45,
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-disabled": {
      color: "#000000ad",
      // color:"-internal-light-dark-color(black, white)",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",
      fontSize: "15px",
      maxHeight: 45,
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiOutlinedInput-input": {
      maxHeight: 45,
      borderRadius: "15px",
      padding: "15.5px 14px",
    },
    "& .MuiInputBase-multiline": {
      maxHeight: 45,
      backgroundColor: theme.palette.background.paper,
      "& .MuiOutlinedInput-input": {
        borderRadius: "0px",
      },
    },
  },
  fullWidth: {
    width: "100%",
    "& .MuiInputBase-root .MuiInputBase-input": {
      width: "100%",
    },
  },
  fieldLabel: {
    // color: theme.palette.primary.contrastText,
    // fontSize: 16,
    margin: "0px 0px 0px 10px",
    fontSize: "15px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  invertTheme: {
    color: theme.palette.primary.light,
  },
  incline: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginRight: 10,
    },
  },

  root_text_area: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.background.paper,
      fontSize: "16px",
      borderRadius: "20px",
      // padding: '10px 14px'
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.background.paper,
      "& .MuiOutlinedInput-input": {
        borderRadius: "0px",
      },
    },
  },
  phone_input: {
    width: "100% !important",
    borderRadius: "15px !important",
    backgroundColor: `${theme.palette.background.paper} !important`,
    height: "45px !important",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px !important",
    },
    // [theme.breakpoints.between("lg","xl")]: {
    //   width: "300px !important",
    // },
  },
  phone_input_fullWidth: {
    width: "100% !important",
  },
  phone_button: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    borderTopLeftRadius: "15px !important",
    borderBottomLeftRadius: "15px !important",
    width: "45px !important",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  text_title: {
    minWidth: "80px",
    margin: "0px 0px 5px 10px",
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  flex_text_field: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

const CustomPhoneInput: React.FC<InputProps> = ({ value, disabled, stateKey, fullWidth, handleChange }) => {
  const classes = useStyles();
  let inputValue = JSON.stringify(value);

  return (
    <PhoneInput
      inputClass={clsx(classes.phone_input, fullWidth && classes.phone_input_fullWidth)}
      buttonClass={classes.phone_button}
      value={inputValue}
      disabled={disabled}
      onChange={(data, country: CountryData) => {
        // console.log("+" + data);
        let event = {
          target: {
            value: "+" + data,
            country: country.countryCode,
          },
        };
        handleChange && handleChange(event, stateKey ? stateKey : "");
      }}
    />
  );
};
const TextArea: React.FC<InputProps> = ({
  type,
  label = "",
  characterLimit,
  stateKey,
  value,
  rows,
  customStyle,
  handleChange,
}) => {
  const classes = useStyles();
  const _handleChange = useCallback(
    (key: string) => (event: InputChangeEventHandler) => {
      handleChange && handleChange(event, key);
    },
    [handleChange]
  );
  return (
    <TextField
      style={customStyle}
      className={classes.root_text_area}
      value={value}
      multiline
      fullWidth
      variant="outlined"
      rows={rows}
      maxRows={7}
      inputProps={{
        maxlength: characterLimit && characterLimit,
      }}
      helperText={`${typeof value === "string" && value.length}/ ${characterLimit && characterLimit}`}
      // onChange={_handleChange(stateKey)}
      onChange={(event) => {
        handleChange && handleChange(event, stateKey ? stateKey : "");
      }}
    />
  );
};

const CopyInput: React.FC<InputProps> = ({
  type,
  label = "",
  value,
  stateKey,
  customStyle,
  handleChange,
}) => {
  const classes = useStyles();
  const { _handleChange } = useProps();
  const [copied, setCopied] = useState({
    copied: false,
  });

  return (
    <TextField
      className={classes.root}
      value={value}
      type={type}
      id="outlined-full-width"
      variant="outlined"
      disabled
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboard
              text={typeof value === "string" ? value : ""}
              onCopy={() => {
                setCopied({ copied: true });
                setTimeout(() => {
                  setCopied({ copied: false });
                }, 1000);
              }}
            >
              <IconButton
                arial-label="copy value"
                onClick={() => {
                  _handleChange &&
                    _handleChange({
                      snackbar: true,
                      snackbarType: 0,
                      snackbarContent: " 拷貝成功", // TODO
                      // registerBusTempData: {
                      //   salesAccount: [],
                      //   productManagerAccount: [],
                      // },
                    });
                }}
              >
                {copied.copied ? <CopyIcon style={{ fill: "red" }} /> : <CopyIcon />}
              </IconButton>
            </CopyToClipboard>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default function CustomTextField(props: InputProps) {
  const {
    label,
    type,
    value,
    stateKey,
    placeholder = "",
    disabled,
    fullWidth = false,
    invertTheme = false,
    multiline = false,
    rows = 1,
    characterLimit,
    customStyle,
    isFlex,
    maxNumber = 0,
    handleKeyPress,
    handleChange,
  } = props;
  const classes = useStyles();
  let showElement = null;

  const _handleChange = useCallback(
    (key: string) => (event: InputChangeEventHandler) => {
      handleChange && handleChange(event, key);
    },
    [handleChange]
  );
  if (type === "textArea") {
    showElement = (
      <TextArea
        stateKey={stateKey}
        rows={rows}
        type={type}
        value={value}
        characterLimit={characterLimit}
        handleChange={handleChange}
      />
    );
  } else if (type === "copy") {
    showElement = <CopyInput stateKey={stateKey} type={"text"} value={value} />;
  } else if (type === "tel") {
    showElement = (
      <CustomPhoneInput
        disabled={disabled}
        stateKey={stateKey}
        type={"text"}
        value={value}
        fullWidth={fullWidth}
        handleChange={handleChange}
      />
    );
  }
  return (
    <div className={clsx(classes.inputWrapper, isFlex && classes.flex_text_field)} style={customStyle}>
      {/* <Typography className={clsx(classes.fieldLabel, invertTheme && classes.invertTheme)} align="left">
        {label}
      </Typography> */}
      {label && <p className={classes.text_title}>{label}</p>}

      {showElement ? (
        showElement
      ) : (
        <TextField
          id={`text-filed-${label}`}
          type={type}
          multiline={multiline}
          rows={rows && rows}
          color="primary"
          variant="outlined"
          className={clsx(classes.root, fullWidth && classes.fullWidth)}
          value={value}
          disabled={disabled}
          InputProps={{ inputProps: { min: 0, max: maxNumber } }}
          onChange={(event) => {
            handleChange && handleChange(event, stateKey ? stateKey : "");
          }}
          // onChange={_handleChange(stateKey)}
          placeholder={placeholder}
          // helperText={`${typeof value === "string" && value.length}/ ${characterLimit && characterLimit}`}
          onKeyPress={(event) => handleKeyPress && handleKeyPress(event)}
          // onKeyDown={handleKeyPress && handleKeyPress}
        />
      )}
    </div>
  );
}
