import React from "react";
import clsx from "clsx";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import { styleProps } from "../../types/common";

const useStyles = makeStyles((theme) => ({
  img_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "120px",
    borderRadius: "20px",
    overflow: "hidden",
    border: `2px solid ${theme.palette.gray.light}`,
    backgroundColor: theme.palette.gray.light,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  //   marginBottom: "10px",
  margin_right: {
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  circle_img_container: {
    borderRadius: "60px",
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.gray.light}`,
  },
  image_size: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

type ImageProps = {
  isCircle?: boolean;
  imageURL: any;
  customStyle?: styleProps;
};

const ImageContainer: React.FC<ImageProps> = ({
  isCircle = false,
  imageURL,
  customStyle,
}) => {
  const classes = useStyles();
  return (
    <div
      style={customStyle}
      className={clsx(
        classes.img_container,
        isCircle && classes.circle_img_container,
      )}
    >
      <img className={classes.image_size} src={imageURL} alt="image" />
    </div>
  );
};
export default ImageContainer;
