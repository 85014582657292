import React from 'react';
import Header from "./Header";
import ListItems from "./ListItems";
// material ui
import {useTheme, makeStyles} from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';


type NavProps = {
    children: React.ReactNode
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        height: '91vh'
    },
    sideBarWrapper: {
        flex: 0.15,
    },
    bodyWrapper: {
        backgroundColor: theme.palette.background.paper,
        flex: 0.85,
        overflow: 'scroll',
        padding: '50px 60px',
        [theme.breakpoints.down('md')]: {
            flex: 1,
            padding:'50px 30px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 10,
        },
    }
}))

const Layout: React.FC<NavProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSizeDevice = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <React.Fragment>
            <Header />
            <div className={classes.container}>
                {!isSizeDevice &&
                    <div className={classes.sideBarWrapper}>
                      <ListItems />
                    </div>
                }
                <div className={classes.bodyWrapper}>
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Layout;
