import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {getFileUploadPath, getRoutesWithPath} from "../utils";
import useQuery from "../hooks/useQuery";
import { routes } from "../configs/routes";
import { useProps } from "../contexts";
import configVariables from "../configs/variables.json";
//Component
import {SelectChangeEventHandler} from "../types/common";
import CustomTable from "./components/CustomTable";
import RedirectText from "./components/RedirectText";
import CustomSearch from "./components/CustomSearch";
import AmountText from "./components/AmountText";
import UploadBankStatementPopUp from "./components/Popups/UploadBankStatementPopUp";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Typography } from "@material-ui/core";
// Controllers
import { getAllWithdrawList } from "../controller/WithdrawListController";
import {updateWithdrawRecord} from "../controller/MemberController";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: 30,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  sub_header_container: {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    padding:"0",
    alignItems: "center",
    [theme.breakpoints.down("sm")]:{
      flexDirection:"column",
      padding:"10px",
      alignItems: "normal",
    },
  },
}));

type TableBody = {
  ID: number;
  userID: number;
  username: string;
  name: string;
  email: string;
  coins: number;
  amount: number;
  requestDescription: string;
  imageURL: string;
  status: number;
  createdAt: string;
};

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "username", label: "帳戶", align: "left" },
  { id: "name", label: "姓名", align: "left" },
  { id: "email", label: "郵件", align: "left" },
  { id: "status", label: "狀態", align: "left" },
  { id: "amount", label: "金額", align: "left" },
  { id: "createdAt", label: "創建日期", align: "left" },
  { id: "edit", label: "編輯", disabledSort: true, align: "center" },
];

const getUserStatus = (list: { label: string; value: number }[], statusID: number) => {
  let status;

  list.map((item, value) => {
    if (item.value === statusID) status = item.label.toString();
  });

  return status;
};

const generateBody = (
  classes: any,
  body: TableBody[],
  page: number,
  rowsPerPage: number,
  handleEditProfile: any,
  handleOpenEditPopUp: any
) => {
  return body.map((e, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    username: (
      <RedirectText id={e.userID} text={e.username} tooltip="查看用戶" handleClick={handleEditProfile} />
    ),
    name: e.name,
    email: e.email,
    status: getUserStatus(configVariables.withdrawStatus, e.status),
    amount: <AmountText amount={e.amount} unit={"$"} onlyUnit={true}/>,
    createdAt: e.createdAt,
    edit: (
      <IconButton
        aria-label="inspect"
        onClick={handleOpenEditPopUp(e.ID, e.userID, e.imageURL, e.status)}
      >
        <EditIcon />
      </IconButton>
    ),
  }));
};

const Withdraw = () => {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const history = useHistory();
  const [file, setFile] = useState<File>(new File([""], ""));
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    rowsPerPage: query.limit || 10,
    totalPageItem: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "createdAt",
    bodyData: [],
    refreshList: false,
    localUploadImagePath: '',
    selectItem: {
      ID: 0,
      userID: 0,
      status: 0,
      imageURL: '',
    },
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleEditProfile = (id: number) => {
    history.push(getRoutesWithPath(routes.navBar.memberProfile.path, [id]));
  };

  const handleOpenEditPopUp = (ID: number, userID: number, imageURL: string, status: number) => () => {
    const tmpData = {ID, userID, imageURL, status}
    setMainState({selectItem: tmpData});
    setOpenEditDialog(true);
  };

  const handleCloseEditPopUp = () => {
    setMainState({localUploadImagePath: ''})
    setFile(new File([""], ""))
    setOpenEditDialog(false);
  };

  const handleSetFile = (event: any) => {
    const tmpFile = event.target.files[0];
    getFileUploadPath(tmpFile, (path: string) => {
      setMainState({localUploadImagePath: path})
    })
    setFile(tmpFile);
  };

  const handleSelect = (event: SelectChangeEventHandler) => {
    const tmpData: any = {...mainState.selectItem}
    tmpData.status = event.target.value;
    setMainState({selectItem: tmpData});
  };

  // API
  const handleSubmit = async () => {
    const {selectItem} = mainState;
    await updateWithdrawRecord(selectItem.userID, file, mainState, setMainState, _handleChange);
    setOpenEditDialog(false);
    setMainState({refreshList: !mainState.refreshList})
  }

  const handleSearch = async (state: any) => {
    const extraFields = {
      startDate: state.startDate,
      endDate: state.endDate,
      search: state.searchParam,
    };

    await fetchData(extraFields);
  };

  const fetchData = async (extraFields: any = undefined) => {
    const { page, rowsPerPage, order, orderBy } = mainState;
    let params = {
      page,
      limits: rowsPerPage,
      order,
      orderBy,
    };

    if (extraFields) {
      params = { ...params, ...extraFields };
    }

    await getAllWithdrawList(params, setMainState, _handleChange);
    setQuery(params);
  };

  useEffect(() => {
    fetchData();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList]);

  const body = generateBody(
    classes,
    mainState.bodyData,
    mainState.page,
    mainState.rowsPerPage,
    handleEditProfile,
    handleOpenEditPopUp
  );

  return (
    <React.Fragment>
      <div className={classes.sub_header_container}>
        <Typography variant="h1">{routes.navBar.withdraw.text}</Typography>
        <CustomSearch submitSearch={handleSearch} />
      </div>
      <div className={classes.tableWrapper}>
        <CustomTable
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totalPageItem}
          rowsPerPage={mainState.rowsPerPage}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>

      <UploadBankStatementPopUp
          title={"上傳銀行帳單"}
          selectID={mainState.selectItem.ID}
          selectStatus={mainState.selectItem.status}
          imageName={file.name}
          imagePath={mainState.localUploadImagePath}
          originalImage={mainState.selectItem.imageURL}
          isOpen={openEditDialog}
          handleChange={handleSelect}
          handleSetFile={handleSetFile}
          handleClose={handleCloseEditPopUp}
          handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

export default Withdraw;
