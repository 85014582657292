import React, {useCallback, useEffect, useState} from "react";
import {WorldMap} from "react-svg-worldmap"
// Components
import {useProps} from "../contexts";
import CustomCard from "./components/CustomCard";
import PieChart from "./components/PieChart";
// Material UI
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
// Controllers
import {getDashboardData} from "../controller/DashboardControllerPage";

const useStyles = makeStyles(theme => ({
    wrapper: {},
    container: {
        // marginTop: 50,
        // padding: 10,
    },
    mapWrapper: {
        marginTop: 40,
        textAlign: 'center',
        // padding: '0 80px',
        backgroundColor: theme.palette.primary.light,
        // boxShadow: '1px 1px 10px #d2d2d2ba',
        border: `1px solid ${theme.palette.gray.main}`,
        borderRadius: 15,
    },
}))

const generatePieData = (labels: Array<string>, values: Array<number>) => ({
    labels,
    datasets: [
        {
            label: '# of Votes',
            data: values,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(124, 221, 221, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(255, 163, 47, 0.2)',
                'rgba(147, 240, 59, 0.2)',
                'rgba(149, 82, 234, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(124, 221, 221, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 163, 47, 1)',
                'rgba(147, 240, 59, 1)',
                'rgba(149, 82, 234, 1)',
            ],
            borderWidth: 1,
        },
    ],
})

const Dashboard = () => {
    const classes = useStyles();
    const {_handleChange} = useProps();
    const [mainState, _setMainState] = useState({
        userData: [
            {label: '總會員人數', value: 0},
            {label: '在線人數', value: 0},
            {label: '昨天', value: 0},
            {label: '今天', value: 0},
        ],
        geoData: [
            {country: "cn", value: 10}, // china
            {country: "in", value: 6}, // india
            {country: "tw", value: 100}, // taiwan
            {country: "my", value: 30}, // taiwan
            {country: "us", value: 30}, // taiwan
        ],
        deviceData: {
            label: [],
            value: []
        },
        readyShowChart: false,
    })

    const setMainState = useCallback((newState) => {
        _setMainState(prevState => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    const fetchData = async () => {
        await getDashboardData(mainState, setMainState, _handleChange);
    }

    useEffect(() => {
        fetchData();
    }, [])

    const chartData = generatePieData(mainState.deviceData.label, mainState.deviceData.value)

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <Grid className={classes.container} container spacing={2} alignItems="center">
                    {mainState.userData.map(item => (
                        <Grid key={`tab-${item.label}`} item xs={6} md={3}>
                            <CustomCard title={item.label} value={item.value}/>
                        </Grid>
                    ))}
                </Grid>

                <Grid className={classes.container} container spacing={2} alignItems="center">
                    <Grid item xs={12} md={8}>
                        <div className={classes.mapWrapper}>
                            <WorldMap color="red" size="responsive" data={mainState.geoData} backgroundColor="transparent"/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {mainState.readyShowChart && <PieChart chartData={chartData}/>}
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;
