import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconTextWrapper: {
    margin: 0,
  },
  positiveAmount: {
    color: theme.palette.secondary.main,
  },
  negativeAmount: {
    color: theme.palette.primary.main,
  },
}));

type AmountTextProps = {
  amount: number;
  unit?: string;
  onlyUnit?: boolean;
  endUnit?: boolean;
};

const AmountText: React.FC<AmountTextProps> = (props) => {
  const { amount, unit='', onlyUnit, endUnit } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {(amount > 0) &&
        <div className={clsx(classes.iconTextWrapper, classes.positiveAmount)}>
          {onlyUnit ?
              endUnit ? `${amount} ${unit}` : `${unit} ${amount}`
              : endUnit? `+ ${amount} ${unit}` : `+${unit} ${amount}`
          }
        </div>
      }

      {(amount === 0) &&
        <div className={clsx(classes.iconTextWrapper)}>
          {amount}
        </div>
      }

      {(amount < 0) &&
        <div className={clsx(classes.iconTextWrapper, classes.negativeAmount)}>
          {onlyUnit ?
              endUnit ? `${amount} ${unit}` : `${unit} ${amount}`
              : endUnit ? `- ${-amount} ${unit}` : `${unit} - ${-amount}`
          }
        </div>
      }
    </React.Fragment>
  );
};

export default AmountText;
