import React, { useCallback, useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import { PageProps, ParamTypes } from "../../../types/common";
import { ReferralBillingDetailsProps, ReferralBillingProps } from "../../../types/company";
import { useProps } from "../../../contexts";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../../configs/routes";
import { getRoutesWithPath } from "../../../utils";
import moment from "moment";
//component
import CustomTable from "../../components/CustomTable";
import AmountText from "../../components/AmountText";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
//Controller
import { handleGetReferralBillingListAPI } from "../../../controller/CompanyController";
import RedirectText from "../../components/RedirectText";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  wrapper: {
    padding: "40px 0px",
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  // { id: "username", label: "帳號", align: "left" },
  { id: "name", label: "公司名稱", align: "left" },
  { id: "amount", label: "分潤點數", align: "left" },
  { id: "date", label: "日期", align: "left" },
];

const generateBody = (
  classes: any,
  body: ReferralBillingDetailsProps[],
  page: number,
  rowsPerPage: number,
  handleRedirect: any,
) => {
  return body.map((e: ReferralBillingDetailsProps, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    // username: <RedirectText id={e.memberID} text={e.username} tooltip="查看個人" handleClick={handleRedirect} />,
    name: <RedirectText id={e.memberID} text={e.name} tooltip="查看個人" handleClick={handleRedirect} />,
    amount: <AmountText amount={e.coins} />,
    date: moment(e.createdAt).format("DD/MM/YYY"),
  }));
};

type ReferralBillingAllProps = ReferralBillingProps &
  PageProps & {
    // list: Array<ReferralBillingDetailsProps>;
    order: string;
    orderBy: string;
  };
const CompanyBranchRecord = () => {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const { companyID } = useParams<ParamTypes>();
  const [mainState, _setMainState] = useState<ReferralBillingAllProps>({
    list: [],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10, //rowPerPage
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleRedirect = (ID: number) => {
    history.push(getRoutesWithPath(routes.navBar.memberProfile.path, [ID]));
  }

  useEffect(() => {
    let ID: any = companyID;
    handleGetReferralBillingListAPI(ID, mainState, setMainState, _handleChange);
  }, [mainState.page,mainState.order, mainState.orderBy])

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limits,
    handleRedirect
  );

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <CustomTable
          noBorder={true}
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totals}
          rowsPerPage={mainState.limits}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>
    </React.Fragment>
  );
};

export default CompanyBranchRecord;
