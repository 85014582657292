import { formatDate } from "../utils";
import { apiMiddleware } from "../utils/middleware";
import { BasicQueryProps } from "../types/common";
import {
  checkMemberStatus,
  deleteMember,
  getBankDetails,
  getCoinListPagination,
  getDetails,
  getMemberReferralDetails,
  getMemberReferralList,
  getMembersPagination,
  getWithdrawPagination,
  updateManualStatus,
  updateStatus,
  updateWithdraw,
} from "../services/api/members";

const contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export type MemberTransferBody = {
  ID: number;
  userID: number;
  description: string;
  imageURL: string;
  status: number;
  amount: number;
  coins: number;
  createdAt: string;
};

export type MemberBenefitBody = {
  ID: number;
  actions: number;
  businessID: number;
  companyName: string;
  amount: number;
  createdAt: string;
};

export const getMemberList = async (
  params: BasicQueryProps,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, getMembersPagination, params);
  if (!success) return;
  const sortData = data.data.map((item: any) => ({
    ID: item.ID,
    username: item.username,
    name: item.name,
    email: item.email,
    status: item.status,
    manualStatus: item.manualStatus,
    idPhoto: item.idPhoto,
    createdAt: formatDate(item.createdAt),
  }));

  setMainState({ bodyData: sortData, totalPageItem: data.totals });
};

export const updateMemberStatus = async (
  updateObj: any,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { success } = await apiMiddleware(changeContext, updateStatus, updateObj);
  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "Successfully updated!";
  changeContext(contextParams);
};

export const updateMemberManualStatus = async (
  updateObj: any,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { success } = await apiMiddleware(changeContext, updateManualStatus, updateObj);
  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "Successfully updated!";
  changeContext(contextParams);
};

export const getMemberDetails = async (
  ID: string | undefined,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, getDetails, ID);
  if (!success) return;
  setMainState({
    name: data.name,
    birthDate: data.birthDate,
    idNumber: data.idNumber,
    phoneNumber: data.phoneNumber,
    email: data.email,
    backupEmail: data.backupEmail,
    address: data.address,
    companyName: data.companyName,
    companyPhone: data.companyPhone,
    companyPhonePrefix: data.companyPhonePrefix,
    companyAddress: data.companyAddress,
    username: data.username,
    referralCode: data.referralCode,
    referralBy: {
      ID: data.referralBy.ID,
      name: data.referralBy.name,
      email: data.referralBy.email,
    },
  });
};

export const handleCheckMemberStatusAPI = async (
  ID: number | undefined,
  setMainState: any,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, checkMemberStatus, ID);
  if (!success) return;

  setMainState({ allowDelete: data.allowDelete, manualStatus: data.manualStatus, status: data.status });
};

export const handleDeleteMemberAPI = async (
  ID: number | undefined,
  allowDelete: boolean,
  changeContext: any
) => {
  if (!allowDelete) {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = " The user has been verified and cannot be deleted";
    changeContext(contextParams);
    return false;
  }

  const { data, success } = await apiMiddleware(changeContext, deleteMember, ID);
  if (!success) return success;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "刪除成功!";
  changeContext(contextParams);
  return success;
};

export const getMemberBank = async (
  ID: string | undefined,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, getBankDetails, ID);
  if (!success) return;

  setMainState({
    bankName: data.bankName,
    bankBranch: data.bankBranch,
    bankAccount: data.bankAccount,
    bankAccountName: data.bankAccountName,
    bankAccountIDCard: data.bankAccountIDCard,
    photos: data.imageLinks,
  });
};

export const getMemberWithdrawList = async (
  ID: string | undefined,
  params: BasicQueryProps,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, getWithdrawPagination, params, ID);
  if (!success) return;

  const sortData = data.data.map((item: any) => ({
    ID: item.ID,
    description: item.requestDescription,
    amount: item.amount,
    coins: item.coins,
    status: item.status,
    imageURL: item.imageUrl,
    createdAt: item.createdAt,
  }));

  setMainState({ bodyData: sortData, totalPageItem: data.totals });
};

export const updateWithdrawRecord = async (
  memberID: string | undefined | number,
  file: File,
  mainState: any,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { selectItem } = mainState;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("status", selectItem.status);

  const { success } = await apiMiddleware(changeContext, updateWithdraw, formData, memberID, selectItem.ID);
  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "Successfully updated!";
  changeContext(contextParams);
};

export const getMemberCoinList = async (
  ID: string | undefined,
  params: BasicQueryProps,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  const { data, success } = await apiMiddleware(changeContext, getCoinListPagination, params, ID);
  if (!success) return;

  const sortData = data.data.map((item: any) => ({
    ID: item.ID,
    actions: item.actions,
    businessID: item.businessID,
    companyName: item.businessName,
    amount: item.coins,
    createdAt: item.createdAt,
  }));

  setMainState({
    bodyData: sortData,
    totalPageItem: data.totals,
    balanceCoin: data.balance,
    totalCoin: data.totalCoin,
    totalMonthCoin: data.totalMonthCoin,
  });
};

export const getMemberReferralListAPI = async (
  memberID: string | undefined,
  mainState: any,
  setMainState: any,
  changeContext: any
) => {
  const { page, limits, order, orderBy } = mainState;
  const params = { page, limits, order, orderBy };

  const { data, success } = await apiMiddleware(changeContext, getMemberReferralList, memberID, params);
  if (!success) return;

  const tmpData = data.data.map((e: any) => {
    const filterMemberName = e.members.map((member: any) => member.name);
    const stringMember = filterMemberName.join(",");
    return {
      ID: e.ID,
      businessID: e.businessID,
      businessName: e.businessName,
      referralList: stringMember,
      createdAt: e.createdAt,
    };
  });

  setMainState({ list: tmpData, totals: data.totals });
};

export const getMemberReferralDetailsAPI = async (
  memberID: string | undefined,
  branchID: string | undefined,
  mainState: any,
  setMainState: any,
  changeContext: any
) => {
  const { page, limits, order, orderBy } = mainState;
  const params = { page, limits, order, orderBy };

  const { data, success } = await apiMiddleware(
    changeContext,
    getMemberReferralDetails,
    memberID,
    branchID,
    params
  );
  if (!success) return;

  const businessDetails = {
    ID: data.businessDetails.ID,
    name: data.businessDetails.name,
  };
  const memberNumber = data.memberList.length;
  const emptyMember = 5 - memberNumber;
  const membersArray = data.memberList.map((e: any, i: any) => ({
    ID: e.ID,
    label: e.name,
    value: data.rates[i],
  }));

  for (let i = 0; i < emptyMember; i++) {
    const defaultMemberValue = { ID: 0, label: "", value: data.rates[memberNumber + i] };
    membersArray.push(defaultMemberValue);
  }

  const records = data.records.map((record: any) => ({
    ID: record.ID,
    coin: record.coins,
    originalAmount: record.originalAmount,
    createdAt: record.createdAt,
  }));

  setMainState({
    businessDetails,
    referralList: membersArray,
    list: records,
    totals: data.totalRecords,
  });
};
