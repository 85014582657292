export const apiMiddleware = async (
  changeContext: any,
  callbackFunctions: any,
  data: any = undefined,
  data2: any = undefined,
  data3: any = undefined,
) => {
  const contextParams: { [key: string]: any } = {
    snackbar: true,
    snackbarType: 1,
    snackbarContent: "",
  };

  changeContext({ openBackdrop: true });

  try {
    const result = await callbackFunctions(data, data2, data3);

    if (!result.success) {
      contextParams.snackbarContent = result.data.message;
      changeContext(contextParams);
    }

    changeContext({ openBackdrop: false });
    return result;

  } catch (error) {
    contextParams.snackbarContent = error.massage;
    changeContext({ ...contextParams, openBackdrop: false });
  }
};
