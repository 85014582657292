import {
  createNews,
  deleteNews,
  getNewsList,
  NewsDetailsProps,
  updateNews,
} from "../../services/api/general";
import { apiMiddleware } from "../../utils/middleware";

const contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetNewsListAPI = async (
    mainState: any,
    extraFields: any,
    setMainState: any,
    changeContext: any,
    setQuery: any
) => {
  changeContext({ openBackdrop: true });

  let params = {
    page: mainState.page,
    limits: mainState.limits,
    order: mainState.order,
    orderBy: mainState.orderBy,
  };

  if (extraFields) params = {...params, ...extraFields}

  const { success, data } = await apiMiddleware(changeContext, getNewsList, params);
  if (!success) return;
  setMainState({ list: data.data, totals: data.totals});
  setQuery(params)
};

export const handleCreateNewsAPI = async (
  title: string,
  content: string,
  forceOrder: boolean,
  // setFetchURL: any,
  changeContext: any
) => {
  let params = { title, content, forceOrder };
  const { success, data } = await apiMiddleware(changeContext, createNews, params);

  if (!success) return;
  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "新增成功!";
  changeContext(contextParams);
  // setFetchURL(data);
};

export const handleUpdateNewsAPI = async (
  id: number,
  content: NewsDetailsProps,
  // setFetchURL: any,
  changeContext: any
) => {
  const { success, data } = await apiMiddleware(changeContext, updateNews, id, content);

  if(!success)return;
  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "編輯成功!";
  changeContext(contextParams);
  // setFetchURL("Edit");
};

export const handleDeleteNewAPI = async (
  id: number, 
  // setFetchURL: any, 
  changeContext: any) => {
  const { success, data } = await apiMiddleware(changeContext, deleteNews, id);

  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "刪除成功!";
  changeContext(contextParams);
  // setFetchURL("Delete");
};
