import React, { useState, useCallback } from "react";
import { useHistory } from "react-router";
import { useProps } from "../contexts";
import { routes } from "../configs/routes";
import clsx from "clsx";
//Component
import CustomTextField from "./components/Inputs/CustomTextField";
import CustomButtons from "./components/Inputs/CustomButtons";
import CustomPasswordField from "./components/Inputs/CustomPasswordField";
// Assets
import Logo from "../assets/SYB_logo_black.jpg";
import Deco1 from "../assets/innerpage_deco1.png";
import Deco2 from "../assets/innerpage_deco2.png";
// Material ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
//controller
import { handleLoginAPI } from "../controller/LoginPageController";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "none",
    position: "relative",
  },
  section: {
    background: theme.palette.primary.light,
    width: "25vw",
    minHeight: "50vh",
    padding: 40,
    textAlign: "center",
    zIndex: 1,
    border: `1px solid ${theme.palette.pink.main}`,
    [theme.breakpoints.down("md")]: {
      width: "65vw",
    },
  },
  logo: {
    width: 200,
    height: "auto",
    borderRadius: 100,
  },
  imgDeco1: {
    transform: "rotate(180deg)",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  imgDeco2: {
    transform: "rotate(180deg)",
    position: "absolute",
    top: 0,
    right: 0,
  },
  spacer: {
    marginTop: 30,
  },
  inputIcon: {
    fontSize: 30,
    color: theme.palette.primary.light,
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
  },
  loginLeftTitle:{
    textAlign: "left",
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { languagePackage, _handleChange } = useProps();
  const [mainState, _setMainState] = useState({
    username: "",
    password: "",
    rememberAcc: false,
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(
    (event, prop) => {
      setMainState({ [prop]: event.target.value });
    },
    [setMainState]
  );

  const handleLogin = () => {
    const { username, password, rememberAcc } = mainState;
    handleLoginAPI(username, password, rememberAcc, _handleChange).then((isSuccess:any) => {
      if (isSuccess) {
        history.push(routes.navBar.dashboard.path);
      }
    });
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className={classes.wrapper}>
      <img className={classes.imgDeco1} src={Deco1} alt="syb-deco" />
      <img className={classes.imgDeco2} src={Deco2} alt="syb-deco" />
      <Paper className={classes.section} elevation={1}>
        <img className={classes.logo} src={Logo} alt="syb-logo" />
        <div className={clsx(classes.spacer,classes.loginLeftTitle)}>
          <CustomTextField
            label={languagePackage.LOGIN_USERNAME}
            value={mainState.username}
            stateKey="username"
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            fullWidth
          />
        </div>

        <div className={classes.spacer}>
          <CustomPasswordField
            label={languagePackage.PASSWORD}
            value={mainState.password}
            stateKey="password"
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            fullWidth
          />
        </div>
        <div className={classes.spacer}>
          <CustomButtons label="登入" handleClick={handleLogin} />
        </div>
      </Paper>
    </div>
  );
};

export default Login;
