import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import CountUp from 'react-countup';
import { useHistory } from "react-router-dom";
import { routes } from "../configs/routes";
// Components
import { getRoutesWithPath } from "../utils";
import useQuery from "../hooks/useQuery";
import AmountText from "./components/AmountText";
import CustomTable from "./components/CustomTable";
import RedirectText from "./components/RedirectText";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
// Controllers
import {handleGetFinancialAPI} from "../controller/FinancialController";
// Props
import { useProps } from "../contexts";
import { SearchProps } from "../types/common";
import { FinancialDetailsProps } from "../services/api/financial";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: 30,
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalSection: {
    padding: "20px 50px",
    backgroundColor: theme.palette.primary.light,
    minWidth: 150,
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
      padding: "10px 25px",
    },
  },
  totalText: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "companyName", label: "企業名稱", align: "left" },
  { id: "coins", label: "點數紀錄", align: "left" },
  { id: "amount", label: "金額紀錄", align: "left" },
  { id: "branchStatus", label: "分支狀態", align: "left" },
  { id: "createdAt", label: "創建日期 時間", align: "left" },
];

const generateBody = (
  classes: any,
  body: Array<FinancialDetailsProps>,
  page: number,
  rowsPerPage: number,
  handleEditProfile: any,
  handleOpenBranchStatus: any
) => {
  return body.map((e:FinancialDetailsProps, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    companyName: (
      <RedirectText
        id={e.ID}
        text={e.companyName}
        tooltip="查看企業"
        handleClick={handleEditProfile(e.companyID)}
      />
    ),
    coins: <AmountText amount={e.coins} />,
    amount: <AmountText amount={e.amount} />,
    branchStatus: e.referralList ?
        (<RedirectText id={e.ID} text="已建立" tooltip="查看分支" handleClick={handleOpenBranchStatus(e.companyID)} />)
        : "未成立",
    createdAt: e.createdAt,
  }));
};

type FinancialAllProps = SearchProps & {
  list:[];
  totalRevenue: number;
  totals: number;
  descriptions: string;
  deleteDialogID: number;
  deleteDialogContent: string;
};
const Financial = () => {
  const classes = useStyles();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [query, setQuery] = useQuery();
  const [mainState, _setMainState] = useState<FinancialAllProps>({
    list:[],
    totalRevenue: 0,
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
    startDate: query.startDate || moment().subtract("3", "months").format("YYYY-MM-DD"),
    endDate: query.endDate || moment().format("YYYY-MM-DD"),
    descriptions: "",
    deleteDialogID: 0,
    deleteDialogContent: "",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleEditProfile = (id: number) => () => {
    history.push(getRoutesWithPath(routes.navBar.companyProfile.path, [id]));
  };

  const handleOpenBranchStatus = (id: number) => (event: any) => {
    history.push(getRoutesWithPath(routes.navBar.companyBranchList.path, [id]));
  };

  //API
  useEffect(() => {
    handleGetFinancialAPI(mainState, setMainState, _handleChange, setQuery);
  }, [mainState.page, mainState.order, mainState.orderBy])

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limits,
    handleEditProfile,
    handleOpenBranchStatus
  );

  return (
    <React.Fragment>
      <div className={classes.headerWrapper}>
        <Typography variant="h1">{routes.navBar.financial.text}</Typography>
        <Paper className={classes.totalSection}>
          <Typography className={classes.totalText}>總收入:
            <CountUp start={0} end={mainState.totalRevenue} duration={2}/>
          </Typography>
        </Paper>
      </div>

      <div className={classes.tableWrapper}>
        <CustomTable
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totals}
          rowsPerPage={mainState.limits}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>
    </React.Fragment>
  );
};

export default Financial;
