import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useProps } from "../../../contexts";
import useQuery from "../../../hooks/useQuery";
import { getRoutesWithPath } from "../../../utils";
import {routes } from "../../../configs/routes";
import { PageProps, ParamTypes } from "../../../types/common";
import { ReferralDetailsProps, ReferralProps } from "../../../types/company";
//Component
import CustomTable from "../../components/CustomTable";
import RedirectText from "../../components/RedirectText";
import AmountText from "../../components/AmountText";
//Controller
import { handleGetReferralListAPI } from "../../../controller/CompanyController";
//Material UI
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  wrapper: {
    padding: "40px 0px",
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}));

const header = [
  { id: "id", label: "層級", disabledSort: true, align: "center" },
  // { id: "username", label: "帳號", align: "left" },
  { id: "name", label: "名稱", align: "left" },
  { id: "email", label: "電郵", align: "left" },
  { id: "rates", label: "抽成", align: "left" },
];

const generateBody = (
  classes: any,
  body: ReferralDetailsProps[],
  page: number,
  rowsPerPage: number,
  handleRedirect: any,
) => {
  return body.map((e:ReferralDetailsProps, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    // username: <RedirectText id={e.ID} text={e.username} tooltip="查看個人" handleClick={handleRedirect} />,
    name: e.name,
    email: <RedirectText id={e.ID} text={e.email} tooltip="查看個人" handleClick={handleRedirect} />,
    rates: <AmountText amount={e.rates} unit={"%"} onlyUnit endUnit/>,
  }));
};
type ReferralAllProps = ReferralProps & PageProps & {
    // list: Array<ReferralDetailsProps>;
    order: string;
    orderBy: string;
  };
const CompanyBranchList = () => {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const {_handleChange} = useProps();
  const { companyID } = useParams<ParamTypes>();
  const [mainState, _setMainState] = useState<ReferralAllProps>({
    list:[],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10, //rowPerPage
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleRedirect = (ID: number) => {
    history.push(getRoutesWithPath(routes.navBar.memberProfile.path, [ID]));
  }

  useEffect(() => {
    let ID: any = companyID;
    handleGetReferralListAPI(ID, setMainState, _handleChange);
  }, [mainState.order, mainState.orderBy])

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limits,
    handleRedirect,
  );

  return (
    <React.Fragment>
      <div className={classes.wrapper}>

        <CustomTable
          noBorder={true}
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totals}
          rowsPerPage={mainState.limits}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>
    </React.Fragment>
  );
};

export default CompanyBranchList;
