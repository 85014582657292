import React, { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import clsx from "clsx";
import { getRoutesWithPath } from "../../utils";
import { routes } from "../../configs/routes";
import { ParamTypes } from "../../types/common";
import { useProps } from "../../contexts";
import { ProductDetailProps } from "../../types/company";
//Component
import BackButton from "../components/BackButton";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import { iconObjList } from "../components/IconSelectList";
import VideoPlayer from "../components/VideoPlayer";
import VRPopUp from "../components/Popups/VRPopUp";
//Material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Switch from "@material-ui/core/Switch";
//Controller
import { handleDownloadImage } from "../../controller/DownloadImageController";
import {
  handleChangeProductStatusAPI,
  HandleGetProductDetailsInitData,
} from "../../controller/CompanyProductDetailController";
import ImageContainer from "../components/ImageContainer";

export type CompanySeriesListProps = {
  companyName: string;
  logoPath: string;
  description: string;
};

const useStyles = makeStyles((theme) => ({
  back_text: {
    fontSize: "0px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  sub_header_container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    alignItems: "flex-end",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0px",
    },
  },
  product_details_container: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  product_details_inner_container: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  product_name: {
    margin: 0,
    fontSize: "15px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
  sub_header_dummy_container: {
    width: "80px",
  },
  logo: {
    width: "100px",
  },
  product_img_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "120px",
    borderRadius: "20px",
    overflow: "hidden",
    backgroundColor: theme.palette.gray.light,
    marginRight: "10px",
  },
  details_container: {
    minHeight: "80vh",
    padding: "40px 10px 40px 10px",
    borderRadius: "10px",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      padding: "40px 30px 40px 30px",
    },
  },
  product_status_title: {
    margin: 0,
    fontSize: "18px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  product_details_title: {
    margin: 0,
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  vr_vd_container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dc_ct_container: {
    marginTop: "30px",
  },
  vr_container: {
    flex: 0.48,
  },
  vr_vd_inner_container: {
    display: "flex",
    height: "350px",
    [theme.breakpoints.down("lg")]: {
      height: "250px",
    },
    "& .page-embed": {
      border: "1px solid black,"
    },
  },
  vd_container: {
    flex: 0.48,
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  vd_inner_container: {
    display: "flex",
    height: "350px",
  },
  ct_container: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: theme.palette.gray.light,
  },
  contact_name: {
    margin: "0px 20px 0px 0px",
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  contact_button: {
    width: "120px",
    padding: "2px 0px",
    color: "white",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  dc_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    marginBottom: "10px",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  dc_title: {
    margin: "0px 0px 0px 10px",
    fontSize: "18px",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // width: "200px",
    // textOverflow: "ellipsis",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("md")]: {
      width: "100px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  dc_item_inner_container: {
    display: "flex",
    alignItems: "center",
  },
  ct_agent_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  title_container: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  status_container: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  hide_over_video: {
    zIndex: 7,
  },
}));

type BusinessDetails = { businessNameID: number };
type ProductDetailsAllProps = ProductDetailProps & BusinessDetails;
const CompanyProductPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation<{ state: string }>();
  const { companyID, seriesID, productID } = useParams<ParamTypes>();
  const { languagePackage, _handleChange, user } = useProps();
  const [isVRPopUpOpen, setVRPopUpOpen] = useState<boolean>(false);
  const [selectedVRPopUp, setSelectedVRPopUp] = useState({
    title: "",
    urlLink: "",
  });
  const [mainState, _setMainState] = useState<ProductDetailsAllProps>({
    accessCode: "",
    businessName: "",
    businessNameID: 0,
    items: {
      vrLink: {
        access: false,
        value: "",
      },
      videoLink: {
        access: false,
        value: "",
      },
      documents: {
        access: false,
        items: [],
      },
      contact: {
        access: false,
        list: [],
      },
    },
    productID: 0,
    productImageUrl: "",
    productName: "",
    status: 0,
    productContactList: [],
  });

  const setMainState = useCallback((newState: any) => {
    _setMainState((prevState: any) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  if (productID !== undefined) {
    let ID: any = productID;
    HandleGetProductDetailsInitData(ID, mainState, setMainState, _handleChange);
  }

  if (mainState.items.contact.list.length === 0) {
    let newList: any = [];
    mainState.productContactList.forEach((i) => {
      if (i.roleType === 2) {
        newList.push(i);
        mainState.items.contact.list.push(i);
      }
    });
  }

  const previousPage = [
    {
      title: routes.navBar.company.text,
      link: routes.navBar.company.path,
      hidden: false,
    },
    {
      title: routes.navBar.companyProfile.text,
      link: getRoutesWithPath(routes.navBar.companyProfile.path, [companyID]),
      hidden: false,
    },
    {
      title: routes.navBar.companySeries.text,
      link: getRoutesWithPath(routes.navBar.companyProducts.path, [companyID, seriesID]),
      hidden: false,
    },
  ];

  const handleGetDownloadImage = (event: any, fileURL: any, fileName: string) => {
    handleDownloadImage(fileURL, fileName).then((imageURL: any) => {
      if (imageURL !== "") {
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = fileName;
        link.click();
      }
    });
  };

  const handleChangeStatus = (event: any) => {
    let value: boolean = event.target.checked;
    let status = value ? 1 : 0;
    const ID: any = productID;
    handleChangeProductStatusAPI(ID, mainState.accessCode, status, setMainState, _handleChange);
  };

  const handleSelectedVRPopUp = (title: string, urlLink: string) => {
    setSelectedVRPopUp({ title: title, urlLink: urlLink });
    setVRPopUpOpen(true);
  };

  return (
    <React.Fragment>
      <div className={classes.sub_header_container}>
        <BackButton to={getRoutesWithPath(routes.navBar.companyProducts.path, [companyID, seriesID])} />

        <div className={classes.product_details_container}>
           <div className={classes.product_details_inner_container}>
           {/* <div className={classes.product_img_container}>
              <img className={classes.logo} alt="" src={mainState.productImageUrl} />
            </div> */}
            <ImageContainer  imageURL={mainState.productImageUrl} />
          </div>
          <p className={classes.product_name}>{mainState.productName}</p>
        </div>

        <div className={classes.sub_header_dummy_container}/>
      </div>
      <CustomBreadcrumbs data={previousPage} />

      <div className={classes.details_container}>
        <div className={classes.vr_vd_container}>
          <div className={classes.vr_container}>
            <div className={classes.title_container}>
              <p className={clsx(classes.product_details_title, classes.hide_over_video)}>
                {languagePackage.PRODUCT_VR}
              </p>
            </div>
            <div className={classes.vr_vd_inner_container}>
              <VideoPlayer
                  urlLink={mainState.items.vrLink.value}
                  isUnlock={mainState.items.vrLink.access}
                  isVR
                  handleSelectedVRPopUp={handleSelectedVRPopUp}
              />
            </div>
          </div>
          <div className={classes.vd_container}>
            <div className={classes.title_container}>
              <p className={clsx(classes.product_details_title, classes.hide_over_video)}>
                {languagePackage.PRODUCT_VIDEO}
              </p>
            </div>
            <div className={classes.vr_vd_inner_container}>
              <VideoPlayer
                  urlLink={mainState.items.videoLink.value}
                  isUnlock={mainState.items.videoLink.access}
              />
            </div>
          </div>
        </div>
        <div className={clsx(classes.vr_vd_container, classes.dc_ct_container)}>
          <div className={clsx(classes.vr_container, classes.hide_over_video)}>
            <div className={classes.title_container}>
              <p className={classes.product_details_title}>{languagePackage.PRODUCT_DOC}</p>
            </div>
            <React.Fragment>
              {mainState.items.documents.items.map((i, index) => {
                let ICON: any = null;
                if (i.icon) {
                  iconObjList.forEach((j) => {
                    if (i.icon === j.value) {
                      ICON = j.icon;
                    }
                  });
                }
                const url = i.fileUrl;
                let fileName = url.slice(url.lastIndexOf("/") + 1);
                if (fileName.length > 18) {
                  fileName = fileName.slice(0, 18) + "...";
                }
                return (
                  <div key={index} className={classes.dc_container}>
                    <div className={classes.dc_item_inner_container}>
                      {ICON && <ICON />}
                      <p className={classes.dc_title}>{fileName}</p>
                    </div>
                    <div/>
                    <Button
                      className={classes.contact_button}
                      variant="contained"
                      color="primary"
                      onClick={(event) => handleGetDownloadImage(event, i.fileUrl, fileName)}
                      // target={"_blank"}
                      startIcon={<CloudDownloadIcon />}
                    >
                      {languagePackage.DOWNLOAD}
                    </Button>
                  </div>
                );
              })}
            </React.Fragment>
          </div>
          <div className={clsx(classes.vd_container, classes.hide_over_video)}>
            <div className={classes.title_container}>
              <p className={clsx(classes.product_details_title, classes.hide_over_video)}>
                {languagePackage.PRODUCT_CONTACT_US}
              </p>
            </div>

            {mainState.items.contact.list.length !== 0 && (
              <div className={classes.ct_container}>
                {mainState.items.contact.list.map((i: any, index: number) => {
                  // TODO
                  return (
                    <div className={classes.ct_agent_container}>
                      <p className={classes.contact_name}>業務人員{index + 1}: </p>
                      <p className={classes.contact_name}>{i.name}</p>
                    </div>
                  );
                })}
              </div>
            )}

            <div className={classes.status_container}>
              <p className={classes.product_details_title}>
                {/* {languagePackage.SET_PUBLISH} */}
                設定產品發布狀態:
              </p>
                <Switch
                  checked={mainState.status !== 0}
                  onChange={(event) => {
                    handleChangeStatus(event);
                  }}
                  size={"medium"}
                />
                <p className={classes.product_status_title}>
                  {/* {languagePackage.SET_PUBLISH} */}
                  {mainState.status === 0 ? "(已下架)" : "(已發布)"}
                </p>
            </div>
          </div>
        </div>
      </div>

      <VRPopUp
          isOpen={isVRPopUpOpen}
          handleClose={() => {
            setVRPopUpOpen(false);
          }}
          vrLink={selectedVRPopUp.urlLink}
          title={selectedVRPopUp.title}
          isNotHomePage={true}
      />
    </React.Fragment>
  );
};

export default CompanyProductPage;
