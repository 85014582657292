import { useEffect } from "react";
import { getAccountDetails, updateAccountDetails, updateAccountPassword } from "../services/api/user";
import { languagePackage } from "../types/common";
import { apiMiddleware } from "../utils/middleware";

const contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const HandleGetInitAccountData = (
  setMainState: (params: any) => void,
  changeContext: any) => {
  useEffect(() => {
    handleGetAccountDetailsAPI(setMainState, changeContext);
  }, [setMainState]);
};

export const handleGetAccountDetailsAPI = async (setMainState: any, changeContext: any) => {
  changeContext({ openBackdrop: true });

  const { success, data } = await apiMiddleware(changeContext, getAccountDetails);

  if (!success) return;
  setMainState({ ...data });
};

export const handleUpdateAccountAPI = async (
  name: string,
  email: string,
  languagePackage: languagePackage,
  changeContext: any
) => {
  changeContext({ openBackdrop: true });

  let params = {
    name: name,
    email: email,
  };
  const { success } = await apiMiddleware(changeContext, updateAccountDetails, params);

  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = languagePackage.UPDATE + languagePackage.SUCCESSFUL;
  changeContext(contextParams);
};

export const handleUpdateAccountPasswordAPI = async (
  password: string,
  oldPassword: string,
  changeContext: any
) => {
  changeContext({ openBackdrop: true });

  let params = {
    password: password,
    oldPassword: oldPassword,
  };
  const { success } = await apiMiddleware(changeContext, updateAccountPassword, params);

  if (!success) return;

  contextParams.snackbarType = 3;
  contextParams.snackbarContent = "更新成功";
  changeContext(contextParams);
};
