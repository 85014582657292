import React from "react";
import {useProps} from "../../contexts";
import {makeStyles} from "@material-ui/core/styles";
import LockOpenSharpIcon from "@material-ui/icons/LockOpenSharp";
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';

const useStyles = makeStyles((theme) => ({
    lock_container: {
        // backgroundColor: "white",
        backgroundColor: "#585656",
        border: "1px solid grey",
        height: "100%",
        width: "100%",
        borderRadius: "15px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    lock_sub_container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    icon_notice: {
        fill: "#ffffff",
        marginBottom: "5px"
    },
    text_notice: {
        textAlign: "center",
        color: "#ffffff",
        marginBottom: "5px",
        marginTop: 0,
        fontWeight: theme.typography.fontWeightMedium,
    }
}))

type VideoPlayerHolderProps = {
    isEmpty?: boolean;
};

const VideoPlayerHolder: React.FC<VideoPlayerHolderProps> = ({
    isEmpty
}) => {
    const classes = useStyles();
    const {languagePackage} = useProps();

    return (
        <div className={classes.lock_container}>
            <div className={classes.lock_sub_container}>
                {/*<img className={classes.img_size} src={default_img}/>*/}
                {isEmpty ?
                    <VideocamOffOutlinedIcon className={classes.icon_notice}/>
                    :
                    <LockOpenSharpIcon className={classes.icon_notice} />
                }
                <p className={classes.text_notice}>
                    {isEmpty ? languagePackage.NO_RESOURCES : languagePackage.NO_UNLOCK}
                </p>
            </div>
        </div>
    )
}

export default VideoPlayerHolder;
