import { useEffect } from "react";
import { getProductPagination } from "../services/api/company";
import { apiMiddleware } from "../utils/middleware";

export const HandleGetInitProductListData = (
    seriesID:number,
    mainState: any,
    setMainState: (params: any) => void,
    changeContext: any
  ) => {
    useEffect(() => {
        handleGetProductListAPI(seriesID,mainState, setMainState, changeContext);
      }, [mainState.page,mainState.order, mainState.orderBy])
    };

export const handleGetProductListAPI = async (
    seriesID: number,
    mainState: any,
    setMainState: any,
    changeContext: any
  ) => {
    changeContext({ openBackdrop: true });

    const { page, limits, order, orderBy } = mainState;
    const params = {
      seriesID: seriesID,
      pageDetails: {
        page: page,
        limits: limits,
        order: order,
        orderBy: orderBy,
      },
    };
    const { success, data } = await apiMiddleware(changeContext, getProductPagination, params);

    if (!success) return;
    setMainState({ list: data.data, totals: data.totals });
};
