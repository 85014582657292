import { useEffect } from "react";
import { getCompanyPagination, getManagerInfo, getRoleManagerInfo } from "../../services/api/company";
import { apiMiddleware } from "../../utils/middleware";

export const HandleGetInitAdminAccData = (
  companyID: number,
  setMainState: (params: any) => void,
  changeContext: any
) => {
  useEffect(() => {
    handleGetManagerInfoAPI(companyID, setMainState, changeContext);
    handleGetRoleAPI(companyID, setMainState, changeContext);
  }, []);
};

export const handleGetManagerInfoAPI = async (companyID: number, setMainState: any, changeContext: any) => {
  changeContext({ openBackdrop: true });

  const { success, data } = await apiMiddleware(changeContext, getManagerInfo, companyID);

  const { ID, email, username, name, phoneNumber, idNumber, status } = data;
  if (!success) return;
  setMainState({
    ID: ID,
    email: email,
    username: username,
    name: name,
    idNumber: idNumber,
    phoneNumber: phoneNumber,
    status: status,
  });
};

export const handleGetRoleAPI = async (companyID: number, setMainState: any, changeContext: any) => {
  changeContext({ openBackdrop: true });

  const { success, data } = await apiMiddleware(changeContext, getRoleManagerInfo, companyID);

  const { roleManage, roleContact } = data;
  if (!success) return;
  
  setMainState({ roleManage: roleManage, roleContact: roleContact });
};
