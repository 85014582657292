import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { routes } from "../../configs/routes";
import configVariables from "../../configs/variables.json";
import { checkTabInURL, getRoutesWithPath } from "../../utils";
import { ParamTypes } from "../../types/common";
// Components
import BackButton from "../components/BackButton";
import CompanyProfile from "./CompanyProfile";
import CompanySeriesList from "./CompanySeriesList";
import CompanyProductList from "./CompanyProductList";
import CompanyFinance from "./CompanyFinance";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import CompanyBranchRecord from "./CompanyBranch/CompanyBranchRecord";
import CompanyBranchList from "./CompanyBranch/CompnayBranchList";
import CompanyAdminAcc from "./CompanyAdminAcc";
import TabBar from "../components/TabBar";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import DeletePopUp from "../components/Popups/DeletedPopUp";
import { handleCheckCompanyStatusAPI } from "../../controller/CompanyController";
import { useProps } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "30px",
  },
  button: {
    margin: 0,
    color: theme.palette.common.white,
    fontWeight: "bold",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

const CompanyPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const { _handleChange } = useProps();
  const { companyID } = useParams<ParamTypes>();
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [mainState, setMainState] = useState({
    allowDelete: false,
    status: 0,
  });
  const currentTab = checkTabInURL(1, location.pathname);
  const tabHeader = useMemo(
    () =>
      configVariables.businessTabs.map((e, i) => {
        let active = i !== 3;
        return { active, label: e };
      }),
    []
  );
  const routesArray = useMemo(
    () => [
      routes.navBar.companyProfile.path,
      routes.navBar.companyAdminAcc.path,
      routes.navBar.companySeries.path,
      routes.navBar.companyProducts.path,
      routes.navBar.companyFinancial.path,
      routes.navBar.companyBranchList.path,
      routes.navBar.companyBranchRecord.path,
    ],
    []
  );

  const previousPage = [
    {
      title: routes.navBar.company.text,
      link: routes.navBar.company.path,
      hidden: false,
    },
    {
      title: routes.navBar.companyProfile.text,
      link: getRoutesWithPath(routes.navBar.companyProfile.path, [companyID]),
      hidden: true,
    },
    {
      title: routes.navBar.companyAdminAcc.text,
      link: getRoutesWithPath(routes.navBar.companyAdminAcc.path, [companyID]),
      hidden: true,
    },
    {
      title: routes.navBar.companySeries.text,
      link: getRoutesWithPath(routes.navBar.companySeries.path, [companyID]),
      hidden: true,
    },
    {
      title: routes.navBar.companyProducts.text,
      link: getRoutesWithPath(routes.navBar.companyProducts.path, [companyID]),
      hidden: true,
    },
    {
      title: routes.navBar.companyFinancial.text,
      link: getRoutesWithPath(routes.navBar.companyFinancial.path, [companyID]),
      hidden: true,
    },
    {
      title: routes.navBar.companyBranchList.text,
      link: getRoutesWithPath(routes.navBar.companyBranchList.path, [companyID]),
      hidden: true,
    },
    {
      title: routes.navBar.companyBranchRecord.text,
      link: getRoutesWithPath(routes.navBar.companyBranchRecord.path, [companyID]),
      hidden: true,
    },
  ];

  const handleDelete = async () => {
    // let ID: number | undefined = memberID ? parseInt(memberID) : undefined;
    // await handleDeleteMemberAPI(ID, mainState.allowDelete, _handleChange).then((isSuccess) => {
    //   if (isSuccess) {
    //     history.push(routes.navBar.members.path);
    //   }
    // });
  };

  const getSortArray = () => {
    let list = [...previousPage];
    previousPage[currentTab + 1].hidden = false;
    // previousPage[currentTab+1].hidden = false;
    return list;
  };

  const handleOpenPopUp = () => {
    setOpenDeletePopUp(true);
  };

  useEffect(() => {
    let ID: number | undefined = companyID ? parseInt(companyID) : undefined;
    handleCheckCompanyStatusAPI(ID, setMainState, _handleChange);
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BackButton to={routes.navBar.company.path} />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!mainState.allowDelete}
          onClick={handleOpenPopUp}
        >
          刪除公司
        </Button>
      </div>
      <CustomBreadcrumbs data={getSortArray()} />
      <div className={classes.wrapper}>
        <TabBar
          headers={tabHeader}
          // breadcrumbs={handleBreadcrumbs}
          tabRoutes={routesArray}
          FirstTab={<CompanyProfile />}
          SecondTab={<CompanyAdminAcc />}
          ThirdTab={<CompanySeriesList />}
          FourthTab={<CompanyProductList />}
          FifthTab={<CompanyFinance />}
          SixthTab={<CompanyBranchList />}
          SeventhTab={<CompanyBranchRecord />}
          activeTab={checkTabInURL(1, location.pathname)}
        />
      </div>
      <DeletePopUp
        isOpen={openDeletePopUp}
        itemName={"此公司"}
        setOpen={setOpenDeletePopUp}
        handleSubmit={handleDelete}
      />
    </React.Fragment>
  );
};

export default CompanyPage;
