import fetchData from "../../utils/fetchData";

export type LoginAPIProps = {
  username: string;
  password: string;
  rememberAcc: boolean;
};

export function login(body: LoginAPIProps) {
  return fetchData({
    url: "/users/login",
    method: "POST",
    data: body,
  });
}

export function logout() {
  return fetchData({
    url: "/users/logout",
    method: "DELETE",
  });
}

export type UpdatePasswordProps = {
  password: string;
  oldPassword: string;
  confirmPassword?: string;
};

export function getAccountDetails() {
  return fetchData({
    url: "/users/",
    method: "GET",
  });
}
export function updateAccountDetails(body: { name: string; email: string }) {
  return fetchData({
    url: "/users/",
    method: "PUT",
    data: body,
  });
}
export function updateAccountPassword(body: { password: string; oldPassword: string }) {
  return fetchData({
    url: "/users/password",
    method: "PUT",
    data: body,
  });
}
