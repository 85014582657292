import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import configVariables from "../../configs/variables.json";
//controller
import { handleGetBillingListAPI } from "../../controller/CompanyController";
import { BillingDetailsProps, BillingProps } from "../../types/company";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
//component
import { useProps } from "../../contexts";
import { PageProps, ParamTypes } from "../../types/common";
import useQuery from "../../hooks/useQuery";
import CustomTable from "../components/CustomTable";
import AmountText from "../components/AmountText";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 0",
  },
  balanceCoin: {
    fontWeight: 'bold',
    textAlign: "right",
    marginBottom: 50,
    paddingRight: 30,
  },
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "task", label: "項目", align: "left" },
  { id: "coin", label: "點數紀錄", disabledSort: true, align: "left" },
  { id: "createdAt", label: "創建日期", align: "left" },
];

const getTextStatus = (list: { label: string; value: number }[], statusID: number) => {
  let status:string = "";

  list.map((items, i) => {
    if (items.value === statusID) status = items.label.toString();
  });

  return status;
};

const getActionStatus = (list: { label: string; value: number }[], actionID: number) => {

  let status:string = "";

  list.map((items, i) => {
    if (items.value === actionID) status = items.label.toString();
  });

  return status;
};

const generateBody = (
  classes: any,
  body: Array<BillingDetailsProps>,
  page: number,
  rowsPerPage: number,
) => {
  return body.map((e: BillingDetailsProps, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    task: getActionStatus(configVariables.actionType,e.actions) + getTextStatus(configVariables.subscribeType, e.productType),
    coin: <AmountText amount={e.coins} />,
    createdAt: moment(e.createdAt).format("DD/MM/YYYY"),
  }));
};
type BillingAllProps = PageProps &
  BillingProps & {
    order: string;
    orderBy: string;
  };
const CompanyFinance = () => {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const { companyID } = useParams<ParamTypes>();
  const [mainState, _setMainState] = useState<BillingAllProps>({
    list: [],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
    balanceCoin: 0,
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  //API
  useEffect(() => {
    let ID: any = companyID;
    handleGetBillingListAPI(ID, mainState, setMainState, _handleChange);
  }, [mainState.page,mainState.order, mainState.orderBy])

  const body = generateBody(classes, mainState.list, mainState.page, mainState.totals);

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.balanceCoin}>點數餘額: {mainState.balanceCoin}</Typography>
      <CustomTable
        header={header}
        body={body}
        rowsPerPageOptions={[10, 25, 100]}
        count={mainState.totals}
        rowsPerPage={mainState.limits}
        page={mainState.page}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
        noBorder={true}
      />
    </div>
  );
};

export default CompanyFinance;
