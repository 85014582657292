import React, { useEffect, useState } from "react";
import SelectDropDownWithSearch from "./SelectDropDownWithSearch";
import { useProps } from "../../contexts";

const getCurrentLanguageName = (nameObj: any, language: string) => {
    return language === 'zh' ? nameObj.cn : nameObj.en;
}

const getStateByCountry = (countryList: any, countryISO: string) => {
    let targetItems: any;

    for (const country of countryList) {
        if (country.isoCode === countryISO) {
            targetItems = country;
            break;
        }
    }
    return targetItems;
}

type CountryStateSelectorProps = {
    handleChange: any;
    stateKey: string;
    value: string;
    disabled?: boolean;
    countryCode?: string;
    statusIndex?: number; // 0: country, 1: state, 2:district
    countryList: any
};

const CountryStateSelector: React.FC<CountryStateSelectorProps> = ({
  handleChange,
  stateKey,
  value,
  disabled,
  countryCode,
  statusIndex = 0,
  countryList=[],
}) => {
    const {languagePackage, language} = useProps();
    const [dataList, setDataList] = useState<any>([]);
    let defaultLabel = "--";
    switch (statusIndex) {
        case 0:
            defaultLabel = defaultLabel + languagePackage.CHOOSE + languagePackage.COUNTRY + "--";
            break;
        case 1:
            defaultLabel = defaultLabel + languagePackage.CHOOSE + languagePackage.CITY + "--";
            break;
        case 2:
            // defaultLabel = defaultLabel + languagePackage.CHOOSE + languagePackage.STATE + "--";
            break;
        default:
            break;
    }
    useEffect(() => {
        let list: any = [];
        if (statusIndex === 0) {
            list = countryList.map((country: any) => {
                return {value: country.isoCode, label: getCurrentLanguageName(country.name, language)}
            })
        } else if (statusIndex === 1 && countryCode) {
            const stateData = getStateByCountry(countryList, countryCode);
            list = stateData?.stateList.map((state: any) => {
                return {value: state.isoCode, label: getCurrentLanguageName(state.name, language)}
            })

        } else if (statusIndex === 2 && countryCode) {
            // list = State.getStatesOfCountry(countryCode);
        }
        setDataList(list);
    }, [countryList, countryCode]);

    return (
        <SelectDropDownWithSearch
            customStyle={{width: "100%"}}
            defaultLabel={defaultLabel}
            initValue={value}
            listArray={dataList}
            stateKey={stateKey}
            disabled={disabled}
            handleChange={handleChange}
        />
    );
};

export default CountryStateSelector;
