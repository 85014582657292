import moment from "moment";
import variables from "../configs/variables.json";
import { IApp } from "../types/app";
import { checkUserLoginAPI } from "../controller/GeneralController";

type BreadcrumbCellProps = {
  title: string;
  link: string | undefined;
  hidden: boolean;
};

const initialLocalStorageSettings = (utilsContextState: IApp, _handleChange: any) => {
  const originalLocalStorage = window.localStorage.getItem(variables.local_storage_version);

  if (!originalLocalStorage) {
    if (!utilsContextState.user.isLogin) {
      checkUserLoginAPI().then((userData) => {
        utilsContextState.user = userData;
        _handleChange({ user: userData });
        window.localStorage.setItem(variables.local_storage_version, JSON.stringify(utilsContextState));
      });
    }
  } else {
    window.localStorage.setItem(variables.local_storage_version, JSON.stringify(utilsContextState));
  }
    //delete more than 1 local storage
    if (window.localStorage.length > 1) {
        const currentKey = variables.local_storage_version;
        for (var i = 0; i < window.localStorage.length; i++) {
          let key = window.localStorage.key(i);
          if (key !== currentKey && key !== null) {
            window.localStorage.removeItem(key);
          }
        }
      }
};

const getRoutesWithPath = (path: string, listArr: any[]) => {
  const splitPath = path.split("/");
  let newPath = path;
  let replaceItems = [];

  for (let index in splitPath) {
    const params = splitPath[index];
    if (params.includes(":")) {
      replaceItems.push(params);
    }
  }

  for (let index in replaceItems) {
    let params = replaceItems[index];
    let items = listArr[index];
    newPath = newPath.replace(params, items);
  }
  return newPath;
};

// TODO
const checkTabInURL = (type: number, path: string) => {
  let returnTab: number = 0;

  if (type === 1) {
    if (path.includes("company") || path.includes("member")) returnTab = 0;
    if (path.split("/")[3] === "bank-account" || path.split("/")[3] === "sub-account") returnTab = 1;
    if (path.includes("series") || path.includes("transfer")) returnTab = 2;
    if ((path.includes("series") && path.split("/").length === 5) || path.includes("benefit")) returnTab = 3;
    if (path.includes("financial") || path.includes("branch-list")) returnTab = 4;
    if (path.includes("branchlist") || (path.includes("branch-list") && path.split("/").length === 5))
      returnTab = 5;
    if (path.split("/")[3] === "branch-record") returnTab = 6;
  }

  return returnTab;
};

const getBreadCrumsPath = () => {
  let list: Array<BreadcrumbCellProps> = [];
  return list;
};

const indexOfByKey = (objList: any, key: string, value: string | number) => {
  for (let index in objList) {
    if (objList[index][key] === value) return index;
  }
  return -1;
};

const getFileUploadPath = (file: File, callback: any) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result);
  };
};

const formatDate = (date: string) => {
  return moment(date).format("YYYY-MM-DD");
};

export {
  initialLocalStorageSettings,
  getRoutesWithPath,
  checkTabInURL,
  getBreadCrumsPath,
  indexOfByKey,
  getFileUploadPath,
  formatDate,
};
