import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useProps} from "../../contexts";
import useQuery from "../../hooks/useQuery";
import {getRoutesWithPath} from "../../utils";
import {routes} from "../../configs/routes";
import {PageProps, ParamTypes} from "../../types/common";
//Component
import CustomTable from "../components/CustomTable";
import RedirectText from "../components/RedirectText";
import AmountText from "../components/AmountText";
import SliderCard from "../components/SliderCard";
//Controller
import {FanshuiListProps, FanshuiDetailsProps, FanshuiReferralMembersProps} from "../../types/user";
//Material UI
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
// Assets
import PersonIcon from "../../assets/login_personal.png";
import BusinessIcon from "../../assets/homepage_services_comissions.png";
import {getMemberReferralDetailsAPI} from "../../controller/MemberController";


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        fontSize: 24,
    },
    wrapper: {
        padding: "40px 0px",
    },
    cardContainer: {
        marginTop: 20,
        padding: '0 20px',
    },
    deleteIcon: {
        color: theme.palette.primary.main,
    },
    emptyPerson: {
        fontSize: 16,
        color: theme.palette.gray.main,
    }
}));

const header = [
    {id: "id", label: "序號", disabledSort: true, align: "center"},
    {id: "coin", label: "點數", align: "left"},
    {id: "originalAmount", label: "企業消費", align: "left"},
    {id: "createdAt", label: "創建日期", align: "left"},
];

const generateBody = (
    classes: any,
    body: FanshuiDetailsProps[],
    page: number,
    rowsPerPage: number,
) => {
    return body.map((e: FanshuiDetailsProps, i) => ({
        id: (page - 1) * rowsPerPage + i + 1,
        coin: <AmountText amount={e.coin} onlyUnit/>,
        originalAmount: <AmountText amount={e.originalAmount} onlyUnit/>,
        createdAt: e.createdAt,
    }));
};

type ReferralAllProps = FanshuiListProps & PageProps & {
    order: string;
    orderBy: string;
};

const MemberBranchListDetails = () => {
    const classes = useStyles();
    const [query, setQuery] = useQuery();
    const history = useHistory();
    const {_handleChange} = useProps();
    const {memberID, branchID} = useParams<ParamTypes>();
    const [mainState, _setMainState] = useState<ReferralAllProps>({
        list: [
            {ID: 1, coin: 100, originalAmount: 1000, createdAt: '20/02/2021'},
            {ID: 2, coin: 200, originalAmount: 2000, createdAt: '20/02/2021'},
            {ID: 3, coin: 300, originalAmount: 3000, createdAt: '20/02/2021'},
            {ID: 4, coin: 400, originalAmount: 4000, createdAt: '20/02/2021'},
            {ID: 5, coin: 500, originalAmount: 5000, createdAt: '20/02/2021'},
        ],
        totals: 10,
        page: query.offset ? parseInt(query.offset) : 1,
        limits: query.limit || 10, //rowPerPage
        order: query.sortBy || "desc",
        orderBy: query.sort || "createdAt",
        businessDetails: {
            ID: 0,
            name: '',
        },
        referralList: [],
    });

    const setMainState = useCallback((newState) => {
        _setMainState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    const handleRedirect = (ID: number) => {
        history.push(getRoutesWithPath(routes.navBar.companyProfile.path, [ID]));
    }

    const handleRedirectMembers = (ID: number) => {
        history.push(getRoutesWithPath(routes.navBar.memberProfile.path, [ID]));
    }

    useEffect(() => {
        getMemberReferralDetailsAPI(memberID, branchID, mainState, setMainState, _handleChange)
    }, [mainState.page, mainState.order, mainState.orderBy])

    const body = generateBody(
        classes,
        mainState.list,
        mainState.page,
        mainState.limits,
    );

    return (
        <React.Fragment>
            <Grid className={classes.cardContainer} container spacing={3} alignItems="stretch" justifyContent="center">
                <Grid item xs={6} md={12} lg={12}>
                    <SliderCard
                        iconImage={BusinessIcon}
                        imageOnly
                        content={<RedirectText
                            id={mainState.businessDetails.ID}
                            text={mainState.businessDetails.name}
                            handleClick={handleRedirect}/>
                        }
                    />
                </Grid>

                {mainState.referralList.map((item: FanshuiReferralMembersProps, index) => (
                    <Grid item xs={6} md={4} lg={2}>
                        <SliderCard
                            iconImage={PersonIcon}
                            index={index}
                            subTitle={
                                parseInt(memberID as string) === item.ID ? item.label
                                    : item.ID === 0 ? <Typography className={classes.emptyPerson}>無用戶</Typography>
                                    : <RedirectText id={item.ID} text={item.label} handleClick={handleRedirectMembers}
                                                    tooltip="查看用戶"/>
                            }
                            flowValues={[{value: item.value}]}
                            value={item.value}
                            handleTotalValue={() => {}}
                            disabled
                        />
                    </Grid>
                ))}
            </Grid>

            <div className={classes.wrapper}>
                <CustomTable
                    noBorder={true}
                    header={header}
                    body={body}
                    rowsPerPageOptions={[10, 25, 100]}
                    count={mainState.totals}
                    rowsPerPage={mainState.limits}
                    page={mainState.page}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                />
            </div>
        </React.Fragment>
    );
};

export default MemberBranchListDetails;
