import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import close_icon from "../../../assets/001-close.svg";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import clsx from "clsx";
import CustomTextField from "../Inputs/CustomTextField";
import {InputChangeEventHandler} from "../../../types/common";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiPaper-root": {
            borderRadius: "20px",
            backgroundColor: "white",
        },
    },
    dialog_container: {
        display: "flex",
        flexDirection: "column",
        padding: "30px",
        // minHeight: "50vh",
        backgroundColor: "white",
        borderRadius: "50px",
    },
    icon_container: {
        alignSelf: "flex-end",
    },
    icon: {
        cursor: "pointer",
        width: "20px",
        "&:hover ": {
            filter:
                "invert(35%) sepia(55%) saturate(3225%) hue-rotate(338deg) brightness(87%) contrast(116%)",
        },
    },
    input_container: {
        display: "flex",
        padding: "20px 0px 20px 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    input_title: {
        margin: "5px",
        marginBottom: "25px",
        fontSize: "20px",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    img_upload_file: {
        margin: "0px 0px 0px 10px",
        fontSize: "15px",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        textAlign: "center",
    },
    input_form_container: {
        margin: "0px 0px 0px 0px",
        width: "200px",
        [theme.breakpoints.up("md")]: {
            width: "500px",
        },
    },
    input_form_img_container: {
        marginBottom: "30px",
    },
    img_upload_title: {
        margin: "20px 0px 0px 10px",
        fontSize: "14px",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    upload_button: {
        alignSelf: "center",
        marginTop: "5px",
        width: "100px",
        padding: "3px 0px",
        color: "white",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    back_button: {
        alignSelf: "center",
        marginTop: "5px",
        width: "120px",
        padding: "8px 6px",
        color: "black",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    submit_button: {
        marginLeft: "20px",
        alignSelf: "center",
        marginTop: "5px",
        width: "120px",
        padding: "8px 6px",
        color: "white",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    dialog_inner_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 10px 30px 10px",
        [theme.breakpoints.up("lg")]: {
            padding: "0px 100px 30px 100px",
        },
    },
    img_upload_container: {
        display: "flex",
        alignItems: "center",
    },
    button_container: {
        marginTop: "20px",
        textAlign: "center",
    },
}));

type DialogProps = {
    name: string;
    descriptions: string;
    isOpen: boolean;
    setOpen: any;
    type: number;
    handleChange: (event: InputChangeEventHandler,stateKey: string) => void;
    handleUpdate: () => void;
    handleCreate: () => void;
}


type Props = DialogProps;
const IndustryPopup: React.FC<Props> = (props) => {
    const {name, descriptions, isOpen, setOpen, type, handleChange, handleUpdate, handleCreate} = props;
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            className={classes.dialog}
            open={isOpen}
            onClose={handleClose}
            maxWidth={"lg"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={classes.dialog_container}>
                <div className={classes.icon_container}>
                    <img className={classes.icon}
                         src={close_icon}
                         onClick={handleClose}
                         alt="close-icon"
                    />
                </div>
                <div className={classes.dialog_inner_container}>
                    <div className={classes.input_container}>
                        <p className={classes.input_title}>
                            {type === 0 ? '新增' : '編輯'}
                            產業
                        </p>
                        <div className={classes.input_form_container}>
                            <CustomTextField
                                label="產業名稱"
                                value={name}
                                stateKey="name"
                                handleChange={handleChange}
                                fullWidth={true}
                            />
                            <CustomTextField
                                label="產業敘述"
                                value={descriptions}
                                stateKey="description"
                                handleChange={handleChange}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                    <div className={classes.button_container}>
                        <Button className={classes.back_button} onClick={handleClose}>
                            {/*{languagePackage.BACK}*/}
                            取消
                        </Button>
                        <Button
                            className={classes.submit_button}
                            variant="contained"
                            color={"primary"}
                            onClick={type === 0 ? handleCreate : handleUpdate}
                        >
                            {/*{languagePackage.CONFIRM_DELETE}*/}
                            {type === 0 ? '提交' : '更新'}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default IndustryPopup;
