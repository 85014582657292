import React, {useCallback, useEffect, useState} from "react";
import { routes } from "../configs/routes";
import { useProps } from "../contexts";
import clsx from "clsx";
//Material ui
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
//Component
import CustomButtons from "./components/Inputs/CustomButtons";
import CustomSlider from "./components/Inputs/CustomSlider";
//icon
import PersonIcon from "../assets/login_personal.png";
import BusinessIcon from "../assets/homepage_services_comissions.png";
// Controllers
import {getProfits, updateProfits} from "../controller/ProfitControllers";
import RedirectText from "./components/RedirectText";
import SliderCard from "./components/SliderCard";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 50,
  },
  commonWrapper: {
    backgroundColor: theme.palette.primary.light,
    textAlign: "center",
    boxShadow: "1px 1px 10px #d2d2d2ba",
  },
  iconWrapper: {
    backgroundColor: theme.palette.primary.light,
    padding: "43px 20px",
  },
  contentWrapper: {
    textAlign: "center",
    padding: 20,
  },
  buildingIcon: {},
  personIcon: {
    width: 50,
  },
  buttonWrapper: {
    marginTop: 50,
    textAlign: "end",
  },
}));

const Profit = () => {
  const classes = useStyles();
  const { _handleChange } = useProps();
  const [mainState, _setMainState] = useState({
    flowValues: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleTotalValue = useCallback(
    (event: any, index: number, value: number) => {
      const { flowValues } = mainState;
      const tmpData: any = [...flowValues];
      tmpData[index]["value"] = value;

      let list = checkFullProfit(tmpData);
      setMainState({ flowValues: list });
    },
    [mainState, setMainState]
  );

  const checkFullProfit = (valueList: [{ value: number }]) => {
    let list: any = [...valueList];
    let checkTotal: number = 0;
    for (let key in list) {
      if (checkTotal >= 100) list[key]["value"] = 0;
      else checkTotal += list[key]["value"];
    }
    return list;
  };

  const handleUpdateButton = async (event: any) => {
    await updateProfits(mainState, setMainState, _handleChange)
    await fetchData();
  }

  //  API
  const fetchData = async () => {
    await getProfits(setMainState, _handleChange)
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h1">{routes.navBar.profit.text}</Typography>

      <Grid className={classes.container} container spacing={2} alignItems="center">
        <Grid item xs={6} md={4} lg={2}>
          <SliderCard
              iconImage={BusinessIcon}
              imageOnly
              imageFullWidth
          />
        </Grid>

        {mainState.flowValues.map((items, index) => {
          return (
            <Grid item xs={6} md={4} lg={2}>
              <SliderCard
                  iconImage={PersonIcon}
                  index={index}
                  flowValues={mainState.flowValues}
                  value={items.value}
                  handleTotalValue={handleTotalValue}
              />
            </Grid>
          );
        })}
      </Grid>

      <div className={classes.buttonWrapper}>
        <CustomButtons label="更新" handleClick={handleUpdateButton} />
      </div>
    </React.Fragment>
  );
};

export default Profit;
