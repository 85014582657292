import {Routes} from "../types/common";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Industry from "../pages/Industry";
import Withdraw from  "../pages/Withdraw";
import MembersList from "../pages/Members/MembersList";
//Company
import CompanyList from "../pages/Company/CompanyList";
import CompanyPage from "../pages/Company/CompanyPage";
import CompanyProductPage from "../pages/Company/CompanyProductPage";
import Financial from "../pages/Financial";
import Profit from "../pages/Profit";
import MemberPage from "../pages/Members/MemberPage";
import NewList from "../pages/NewList";
import AccountPage from "../pages/Account";
// Manager
import ManagerList from "../pages/Managers/ManagerList";
import ManagerDetails from "../pages/Managers/ManagerDetails";

//permisson
//LocalAgent = 0
//SuperAdmin = 1

//ex: "test" Page is under "mainpage"
//link follow by
// mainpage/?:mainpageID/test

const routes: Routes = {
    generals: {
        login: {
            text: "登入",
            path: "/login",
            exact: true,
            component: Login,
            permission:[0,1]
        }
    },
    navBar: {
        dashboard: {
            text: "首頁",
            path: "/",
            exact: true,
            component: Dashboard,
            permission:[0,1]
        },
        industry: {
            text: "產業類型",
            path: "/industry",
            exact: true,
            component: Industry,
            permission:[1]

        },
        newList: {
            text: "最新消息",
            path: "/news",
            exact: true,
            component: NewList,
            permission:[1]
        },
        // Individual Account
        members: {
            text: "個人帳號列表",
            path: "/members",
            exact: true,
            component: MembersList,
            permission:[0,1]
        },
        memberProfile: {
            text: "個人帳號",
            path: "/members/:memberID/profile",
            exact: true,
            component: MemberPage,
            hidden: true,
            permission:[0,1]
        },
        memberTransfer: {
            text: "提款紀錄",
            path: "/members/:memberID/transfer",
            exact: true,
            component: MemberPage,
            hidden: true,
            permission:[0,1]
        },
        memberBenefit: {
            text: "點數紀錄",
            path: "/members/:memberID/benefit",
            exact: true,
            component: MemberPage,
            hidden: true,
            permission:[0,1]
        },
        memberBankAccount:{
            text: "帳戶資料",
            path: "/members/:memberID/bank-account",
            exact: true,
            component: MemberPage,
            hidden: true,
            permission:[0,1]
        },
        memberBranchList:{
            text: "分支列表",
            path: "/members/:memberID/branch-list",
            exact: true,
            component: MemberPage,
            hidden: true,
            permission:[0,1]
        },
        memberBranchListDetails:{
            text: "分支詳情",
            path: "/members/:memberID/branch-list/:branchID",
            exact: true,
            component: MemberPage,
            hidden: true,
            permission:[0,1]
        },
        // Company Pages
        company: {
            text: "企業帳號列表",
            path: "/company",
            exact: true,
            component: CompanyList,
            permission:[0,1]

        },
        companyProfile: {
            text: "企業資料",
            path: "/company/:companyID/profile",
            exact: true,
            component: CompanyPage,
            hidden: true,
            permission:[0,1]

        },
        companyAdminAcc:{
            text: "企業管理員",
            path: "/company/:companyID/sub-account",
            exact: true,
            component: CompanyPage,
            hidden: true,
            permission:[0,1]

        },
        companySeries: {
            text: "系列列表",
            path: "/company/:companyID/series",
            exact: true,
            component: CompanyPage,
            hidden: true,
            permission:[0,1]

        },
        companyProducts: {
            text: "產品列表",
            path: "/company/:companyID/series/:seriesID",
            exact: true,
            component: CompanyPage,
            hidden: true,
            permission:[0,1]

        },
        companyProductsPage: {
            text: "產品",
            path: "/company/:companyID/series/:seriesID/product/:productID",
            exact: true,
            component: CompanyProductPage,
            hidden: true,
            permission:[0,1]

        },
        companyFinancial: {
            text: "充值紀錄",
            path: "/company/:companyID/financial",
            exact: true,
            component: CompanyPage,
            hidden: true,
            permission:[0,1]

        },
        companyBranchList:{
            text: "分支列表",
            path: "/company/:companyID/branchlist",
            exact: true,
            component: CompanyPage,
            hidden:true,
            permission:[0,1]

        },
        companyBranchRecord:{
            text: "分潤紀錄", //反水
            path: "/company/:companyID/branch-record",
            exact: true,
            component:CompanyPage,
            hidden:true,
            permission:[0,1]
        },
        // Financial
        withdraw: {
            text: "提款列表",
            path: "/withdraw",
            exact: true,
            component: Withdraw,
            permission:[0,1]

        },
        financial: {
            text: "財務報表",
            path: "/finance",
            exact: true,
            component: Financial,
            permission:[0,1]

        },
        profit: {
            text: "分潤調整",
            path: "/profit",
            exact: true,
            component: Profit,
            permission:[0,1]

        },
        // Manager
        managerList: {
            text: "管理者帳號",
            path: "/manager",
            exact: true,
            component: ManagerList,
            permission:[1]
        },
        managerDetails: {
            text: "管理者帳號資料",
            path: "/manager/:managerID/details",
            exact: true,
            component: ManagerDetails,
            hidden:true,
            permission:[1]
        },
        accountSetting:{
            text: "帳號設定",
            path: "/account",
            exact: true,
            component: AccountPage,
            permission:[0,1]

        },
    }
}

export {routes}
