import React from "react";
import {Grid, Paper, Typography} from "@material-ui/core";
import clsx from "clsx";
import PersonIcon from "../../assets/login_personal.png";
import CustomSlider from "./Inputs/CustomSlider";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    commonWrapper: {
        backgroundColor: theme.palette.primary.light,
        textAlign: "center",
        boxShadow: "1px 1px 10px #d2d2d2ba",
    },
    contentWrapper: {
        textAlign: "center",
        padding: 20,
        height: '83%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    iconWrapper: {
        backgroundColor: theme.palette.primary.light,
        padding: "43px 20px",
        minHeight: 100,
    },
    personIcon: {
        width: 50,
    },
    businessIcon: {
        width: 80,
    },
    iconFullWidth: {
        width: 'auto',
    },
    content: {
        marginTop: 10,
        textAlign: "center",
    }
}));

type flowValuesType = {
    value: number;
}

type SliderCardProps = {
    iconImage: any;
    imageFullWidth?: boolean;
    index?: number;
    subTitle?: any;
    flowValues?: Array<flowValuesType>,
    value?: number;
    handleTotalValue?: any,
    imageOnly?: boolean,
    content?: any,
    disabled?: boolean,
};

const SliderCard: React.FC<SliderCardProps> = (props) => {
    const {
        iconImage,
        imageFullWidth,
        index = 0,
        subTitle,
        flowValues = [],
        value = 0,
        handleTotalValue,
        imageOnly = false,
        content,
        disabled = false
    } = props;
    const classes = useStyles();

    return (
        <Paper className={clsx(
            classes.commonWrapper,
            imageOnly ? classes.iconWrapper : classes.contentWrapper,
        )}>
            <img className={clsx(
                imageOnly ? classes.businessIcon : classes.personIcon,
                imageFullWidth && classes.iconFullWidth,
            )}
                 src={iconImage}
                 alt="personal_icon"
            />
            {subTitle && subTitle}
            {!imageOnly &&
                <CustomSlider
                  index={index}
                  subTitle={subTitle}
                  currentList={flowValues}
                  testValue={value}
                  handleTotalValue={handleTotalValue}
                  disabled={disabled}
                />
            }
            {content && <Typography className={classes.content}>{content}</Typography>}
        </Paper>
    )
}

export default SliderCard;
