import {apiMiddleware} from "../utils/middleware";
import {indexOfByKey} from "../utils";
import {dashboardData} from "../services/api/general";

export const getDashboardData = async (
    mainState: any,
    setMainState: (params: any) => void,
    changeContext: any
) => {
    const {data, success} = await apiMiddleware(changeContext, dashboardData)

    if (!success) return;

    const tmpState = {...mainState};
    // Online Users
    tmpState.userData[0].value = data.totalBusinessUser + data.totalPlatformUser;
    tmpState.userData[1].value = data.onlineUsers;
    tmpState.userData[2].value = data.previousDayTotal;
    tmpState.userData[3].value = data.todayDayTotal;
    // Geo Data
    let countryArray: any[] = [];
    let deviceArray: any[] = [];
    let deviceLabelArray: any[] = [];

    for (const item of data.geoResult) {
        const lowerCaseCountry = item.country.toLowerCase();
        countryArray.push({country: lowerCaseCountry, value: item.value})
        deviceLabelArray.push(item.countryName)
        deviceArray.push(item.value)
    }

    tmpState.geoData = countryArray;
    tmpState.deviceData = {
        label: deviceLabelArray,
        value: deviceArray
    }
    tmpState.readyShowChart = true;


    setMainState(tmpState)
}
