import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import configVariables from "../../configs/variables.json";
import { routes } from "../../configs/routes";
import { checkTabInURL, getRoutesWithPath } from "../../utils";
import { useProps } from "../../contexts";
import { ParamTypes } from "../../types/common";
// Components
import BackButton from "../components/BackButton";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import TabBar from "../components/TabBar";
import MemberProfile from "./MemberProfile";
import MemberTransfer from "./MemberTransfer";
import MemberBenefit from "./MemberBenefit";
import MemberBankAccount from "./MemberBankAccount";
import MemberBranchList from "./MemberBranchList";
import MemberBranchListDetails from "./MemberBranchListDetails";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// Controllers
import { handleCheckMemberStatusAPI, handleDeleteMemberAPI } from "../../controller/MemberController";
import DeletePopUp from "../components/Popups/DeletedPopUp";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "30px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  businessName: {
    fontSize: 16,
    marginBottom: 30,
  },
  search_bar_container: {
    marginLeft: "10px",
    padding: "0px 4px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "13px",
    height: "40px",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginBottom: "10px",
    },
  },
  filter_container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  icon: {
    color: theme.palette.background.default,
  },
  button: {
    margin: 0,
    // padding: 0,
    // fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: "bold",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

const MemberPage = () => {
  const classes = useStyles();
  const { _handleChange } = useProps();
  const location = useLocation();
  const history = useHistory();
  const currentTab = checkTabInURL(1, location.pathname);
  const { memberID, branchID } = useParams<ParamTypes>();

  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [mainState, setMainState] = useState({
    allowDelete: false,
    manualStatus: 0,
    status: 0,
  });

  const tabHeader = useMemo(
    () =>
      configVariables.individualTabs.map((e, i) => {
        let active = i !== 5;
        return { active, label: e };
      }),
    []
  );
  const routesArray = useMemo(
    () => [
      routes.navBar.memberProfile.path,
      routes.navBar.memberBankAccount.path,
      routes.navBar.memberTransfer.path,
      routes.navBar.memberBenefit.path,
      routes.navBar.memberBranchList.path,
      routes.navBar.memberBranchListDetails.path,
    ],
    []
  );

  const pageList = [
    {
      title: routes.navBar.members.text,
      link: getRoutesWithPath(routes.navBar.members.path, [memberID]),
      hidden: false,
    },
    {
      title: routes.navBar.memberProfile.text,
      link: getRoutesWithPath(routes.navBar.memberProfile.path, [memberID]),
      hidden: true,
    },
    {
      title: routes.navBar.memberBankAccount.text,
      link: getRoutesWithPath(routes.navBar.memberBankAccount.path, [memberID]),
      hidden: true,
    },
    {
      title: routes.navBar.memberTransfer.text,
      link: getRoutesWithPath(routes.navBar.memberTransfer.path, [memberID]),
      hidden: true,
    },
    {
      title: routes.navBar.memberBenefit.text,
      link: getRoutesWithPath(routes.navBar.memberBenefit.path, [memberID]),
      hidden: true,
    },
    {
      title: routes.navBar.memberBranchList.text,
      link: getRoutesWithPath(routes.navBar.memberBranchList.path, [memberID]),
      hidden: true,
    },
    {
      title: routes.navBar.memberBranchListDetails.text,
      link: getRoutesWithPath(routes.navBar.memberBranchListDetails.path, [memberID, branchID]),
      hidden: true,
    },
  ];
  const handleDelete = async () => {
    let ID: number | undefined = memberID ? parseInt(memberID) : undefined;
    await handleDeleteMemberAPI(ID, mainState.allowDelete, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        history.push(routes.navBar.members.path);
      }
    });
  };

  const handleOpenPopUp = () => {
    setOpenDeletePopUp(true);
  };

  const getSortArray = () => {
    let list = [...pageList];
    pageList[currentTab + 1].hidden = false;
    return list;
  };

  useEffect(() => {
    let ID: number | undefined = memberID ? parseInt(memberID) : undefined;
    handleCheckMemberStatusAPI(ID, setMainState, _handleChange);
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BackButton to={routes.navBar.members.path} />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!mainState.allowDelete}
          onClick={handleOpenPopUp}
        >
          刪除用戶
        </Button>
      </div>
      <CustomBreadcrumbs data={getSortArray()} />
      <div className={classes.wrapper}>
        <TabBar
          headers={tabHeader}
          tabRoutes={routesArray}
          FirstTab={<MemberProfile />}
          SecondTab={<MemberBankAccount />}
          ThirdTab={<MemberTransfer />}
          FourthTab={<MemberBenefit />}
          FifthTab={<MemberBranchList />}
          SixthTab={<MemberBranchListDetails />}
          activeTab={currentTab}
        />
      </div>
      <DeletePopUp
        isOpen={openDeletePopUp}
        itemName={"此用戶"}
        setOpen={setOpenDeletePopUp}
        handleSubmit={handleDelete}
      />
    </React.Fragment>
  );
};

export default MemberPage;
