import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../types/common";
import { useProps } from "../../contexts";
import { CompanyFormProps } from "../../types/user";
import clsx from "clsx";
// Components
import CustomTextField from "../components/Inputs/CustomTextField";
import SelectDropDownWithSearch from "../components/SelectDropDownWithSearch";
import CountryStateSelector from "../components/CountryStateSelector";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//Img 
// import defaultLogo from "../../assets/ESP_logo_black.png";
import defaultLogo from "../../assets/logo_black.png";
// Controllers
import { handleGetCompanyProfileAPI } from "../../controller/CompanyController";
import { getAllIndustryList } from "../../controller/IndusctryController";
import { getCountryListAPI } from "../../controller/GeneralController";
import ImageContainer from "../components/ImageContainer";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
    },
  },
  input_title: {
    margin: "0px 0px 0px 10px",
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  date_input_container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  input_container: {
    margin: "25px 0px",
  },
  address_address_container: {
    margin: "10px 0px",
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },
  address_container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  double_input_inner_container: {
    width: "100%",
    marginRight: "10px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "15px",
    },
  },

  company_container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    position: "relative",
  },
  company_img_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "120px",
    borderRadius: "60px",
    overflow: "hidden",
    backgroundColor: theme.palette.gray.light,
    marginRight: "10px",
  },

  logo: {
    width: "100px",
  },
  marginTop: {
    marginTop: "25px",
  },
  grid_mobile_size: {
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0 0 0",
    },
  },
}));

const FormDetails: React.FC = () => {
  const classes = useStyles();
  const { languagePackage, _handleChange } = useProps();
  const { companyID } = useParams<ParamTypes>();
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [formDetails, _setFormDetails] = useState<CompanyFormProps>({
    companyName: "",
    companyIcon: "", //COMICON
    companyDescription: "",
    industryID: 0,
    companyCountry: "",
    companyRegisterNumber: "",
    companyOwnerName: "",
    companyPhone: "",
    companyEmail: "",
    companyDomain: "",
    companyRegisterAddress: {
      address: "",
      country: "",
      postalCode: null,
      district: "",
      city: "",
    },
    companyContactAddress: {
      address: "",
      country: "",
      postalCode: null,
      district: "",
      city: "",
    },
    referralCode: "",
  });

  const setFormDetails = useCallback((newState) => {
    _setFormDetails((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  useEffect(() => {
    let ID: any = companyID;
    handleGetCompanyProfileAPI(ID, formDetails, setFormDetails, _handleChange);
    getAllIndustryList(setIndustryList, _handleChange);
    getCountryListAPI(_handleChange, setCountryList);
  }, []);

  const handleTextInput = (event: { target: { value: any } }, statusKey: string) => {};
  const handleDropdownList = (event: { value: string; label: string }, statusKey: string) => {};

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <div className={classes.company_container}>
          {/* <div className={classes.company_img_container}>
            {formDetails.companyIcon === "" ? (
              <img className={classes.logo} alt="" src={defaultLogo} />
            ) : (
              <img className={classes.logo} alt="" src={formDetails.companyIcon} />
            )}
          </div> */}
          <ImageContainer
            isCircle
            imageURL={formDetails.companyIcon ? formDetails.companyIcon : defaultLogo}
          />
        </div>
      </Grid>
      <Grid className={classes.grid_mobile_size} item xs={12} md={12} lg={6}>
        <CustomTextField
          label={languagePackage.COMPANY_NAME}
          type={"text"}
          stateKey={"companyName"}
          value={formDetails.companyName}
          handleChange={handleTextInput}
          disabled
          customStyle={{ marginTop: "0px" }}
          fullWidth
        />

        <CustomTextField
          value={formDetails.companyDescription}
          stateKey={"companyDescription"}
          handleChange={handleTextInput}
          label={languagePackage.COMPANY_DESCRIPTION}
          type={"text"}
          disabled
          fullWidth
        />
        <div className={classes.input_container}>
          <p className={classes.input_title}>{languagePackage.COMPANY_CATEGORY}</p>
          <SelectDropDownWithSearch
            customStyle={{ width: "100%", marginTop: 5 }}
            defaultLabel={"--產業--"} //TODO
            initValue={formDetails.industryID}
            stateKey={"industryID"}
            handleChange={handleDropdownList}
            listArray={industryList}
            disabled
          />
        </div>
        <div className={classes.input_container}>
          {/* <p className={classes.input_title}>{languagePackage.COMPANY_CODE_DESC}</p> */}
          <CustomTextField
            label={languagePackage.COMPANY_CODE}
            type={"text"}
            stateKey={"companyRegisterNumber"}
            value={formDetails.companyRegisterNumber}
            handleChange={handleTextInput}
            disabled
            fullWidth
          />
        </div>
        <CustomTextField
          label={languagePackage.BUSINESS_BEHALF}
          type={"text"}
          stateKey={"companyOwnerName"}
          value={formDetails.companyOwnerName}
          handleChange={handleTextInput}
          disabled
          fullWidth
        />

        <CustomTextField
          label={languagePackage.COMPANY_PHONE}
          type={"tel"}
          stateKey={"companyPhone"}
          value={formDetails.companyPhone}
          handleChange={handleTextInput}
          disabled
        />

        <CustomTextField
          label={languagePackage.COMPANY_EMAIL}
          type={"text"}
          stateKey={"companyEmail"}
          value={formDetails.companyEmail}
          handleChange={handleTextInput}
          disabled
          fullWidth
        />

        <CustomTextField
          label={languagePackage.COMPANY_WEBSITE}
          type={"text"}
          stateKey={"companyDomain"}
          value={formDetails.companyDomain}
          handleChange={handleTextInput}
          disabled
          fullWidth
        />
        <div className={classes.input_container}>
          <p className={classes.input_title}>{languagePackage.COMPANY_COUNTRY}</p>
          <CountryStateSelector
            value={formDetails.companyCountry}
            stateKey={"companyCountry"}
            statusIndex={0}
            countryList={countryList}
            handleChange={handleDropdownList}
            disabled
          />
        </div>
      </Grid>
      <Grid className={classes.grid_mobile_size} item xs={12} md={12} lg={6}>
        <p className={classes.input_title}>{languagePackage.COMPANY_REGISTER_ADDRESS}</p>
        <div className={classes.address_container}>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              value={formDetails.companyRegisterAddress.country}
              stateKey={"companyRegisterAddress.country"}
              statusIndex={0}
              countryList={countryList}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              countryCode={formDetails.companyRegisterAddress.country}
              value={formDetails.companyRegisterAddress.city}
              stateKey={"companyRegisterAddress.city"}
              statusIndex={1}
              countryList={countryList}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
        </div>
        <div className={clsx(classes.input_container, classes.address_address_container)}>
          <CustomTextField
            label={""}
            type={"text"}
            stateKey={"companyRegisterAddress.address"}
            value={formDetails.companyRegisterAddress.address}
            handleChange={handleTextInput}
            customStyle={{ margin: "0" }}
            disabled
            fullWidth
          />
        </div>
        <CustomTextField
          label={languagePackage.POST_CODE}
          type={"text"}
          stateKey={"companyRegisterAddress.postalCode"}
          value={formDetails.companyRegisterAddress.postalCode}
          handleChange={handleTextInput}
          isFlex
          fullWidth
          disabled
        />
        <p className={clsx(classes.input_title, classes.marginTop)}>
          {languagePackage.COMPANY_CONTACT_ADDRESS}
        </p>
        <div className={classes.address_container}>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              value={formDetails.companyContactAddress.country}
              stateKey={"companyContactAddress.country"}
              statusIndex={0}
              countryList={countryList}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              countryCode={formDetails.companyContactAddress.country}
              value={formDetails.companyContactAddress.city}
              stateKey={"companyContactAddress.city"}
              statusIndex={1}
              countryList={countryList}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
        </div>
        <div className={clsx(classes.input_container, classes.address_address_container)}>
          <CustomTextField
            label={""}
            type={"text"}
            stateKey={"companyContactAddress.address"}
            value={formDetails.companyContactAddress.address}
            handleChange={handleTextInput}
            customStyle={{ margin: "0" }}
            disabled
            fullWidth
          />
        </div>
        <CustomTextField
          label={languagePackage.POST_CODE}
          type={"text"}
          stateKey={"companyContactAddress.postalCode"}
          handleChange={handleTextInput}
          value={formDetails.companyContactAddress.postalCode}
          isFlex
          fullWidth
          disabled
        />
      </Grid>
    </Grid>
  );
};

const CompanyProfile: React.FC = () => {
  const classes = useStyles();
  const { languagePackage } = useProps();

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <FormDetails />
      </div>
    </React.Fragment>
  );
};

export default CompanyProfile;
