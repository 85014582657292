import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { routes } from "../../configs/routes";
import { useParams } from "react-router-dom";
import { ParamTypes } from "../../types/common";
import { FormUpateProps } from "../../types/user";
import { formatDate } from "../../utils";
import { useProps } from "../../contexts";
import { getRoutesWithPath } from "../../utils";
//Component
import CustomTextField from "../components/Inputs/CustomTextField";
import CountryStateSelector from "../components/CountryStateSelector";
import RedirectText from "../components/RedirectText";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
//Controler
import { getMemberDetails } from "../../controller/MemberController";
import { getCountryListAPI } from "../../controller/GeneralController";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
    },
  },
  input_title: {
    margin: "0px 0px 0px 10px",
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  input_spacer: {
    marginLeft: 10,
  },
  spacing: {
    margin: "50px 0px 15px 0px",
  },
  date_input_container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  input_container: {
    margin: "25px 0px",
  },
  address_address_container: {
    margin: "10px 0px",
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },
  address_container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  double_input_inner_container: {
    width: "100%",
    marginRight: "10px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "15px",
    },
  },
  double_input_container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  marginTop: {
    marginTop: "25px",
  },
}));
type IndProps = FormUpateProps & {
  username: string;
  referralBy: { ID: number; email: string; name: string };
  referralCode: string;
};
const FormDetails: React.FC = () => {
  const classes = useStyles();
  const { memberID } = useParams<ParamTypes>();
  const history = useHistory();
  const { languagePackage, user, _handleChange } = useProps();
  const [countryList, setCountryList] = useState([]);
  const [formDetails, setFormDetails] = useState<IndProps>({
    name: "",
    birthDate: "",
    idNumber: "",
    phoneNumber: "",
    email: "",
    backupEmail: "",
    address: {
      address: "",
      postalCode: null,
      country: "",
      district: "",
      city: "",
    },
    companyName: "",
    companyCountry: "",
    companyPhone: "",
    companyPhonePrefix: "",
    companyAddress: {
      address: "",
      country: "",
      postalCode: null,
      district: "",
      city: "",
    },
    // referralCode: "",
    username: "",
    referralBy: {
      ID: 0,
      name: "",
      email: "",
    },
    referralCode: "",
  });

  const handleTextInput = (event: { target: { value: any } }, statusKey: string) => {};

  const handleDropdownList = (event: { value: string; label: string }, statusKey: string) => {};

  const handleRedirect = (id: number) => {
    history.push(getRoutesWithPath(routes.navBar.memberProfile.path, [id]));
  };

  useEffect(() => {
    getMemberDetails(memberID, setFormDetails, _handleChange);
    getCountryListAPI(_handleChange, setCountryList);
  }, [memberID]);

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={6}>
        <Typography variant="h2">{languagePackage.PERSONAL_DETAIL}</Typography>
        <CustomTextField
          stateKey={"name"}
          value={formDetails.name}
          handleChange={handleTextInput}
          label={languagePackage.REGISTER_NAME}
          // label={languagePackage.REGISTER_NAME_DESC}
          type={"text"}
          fullWidth
          disabled
        />
        <CustomTextField
          value={formatDate(formDetails.birthDate)}
          stateKey={"birthDate"}
          handleChange={handleTextInput}
          label={languagePackage.BIRTHDAY}
          type={"date"}
          isFlex
          fullWidth
          disabled
        />
        <CustomTextField
          value={formDetails.idNumber}
          stateKey={"idNumber"}
          handleChange={handleTextInput}
          label={languagePackage.IC_NUMBER}
          type={"text"}
          fullWidth
          disabled
        />
        <CustomTextField
          value={formDetails.phoneNumber}
          stateKey={"phoneNumber"}
          handleChange={handleTextInput}
          label={languagePackage.PHONE_NUMBER}
          type={"tel"}
          fullWidth
          disabled
        />
        <CustomTextField
          value={formDetails.email}
          stateKey={"email"}
          handleChange={handleTextInput}
          label={languagePackage.EMAIL}
          type={"email"}
          fullWidth
          disabled
        />

        <CustomTextField
          value={formDetails.backupEmail}
          stateKey={"backupEmail"}
          handleChange={handleTextInput}
          label={languagePackage.TEMP_EMAIL}
          type={"email"}
          fullWidth
          disabled
        />
        <p className={clsx(classes.input_title, classes.marginTop)}>{languagePackage.ADDRESS}</p>
        <div className={classes.address_container}>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              countryCode={formDetails.address.country}
              value={formDetails.address.country}
              stateKey="address.country"
              statusIndex={0}
              countryList={countryList}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              countryCode={formDetails.address.country}
              value={formDetails.address.city}
              stateKey="address.city"
              statusIndex={1}
              countryList={countryList}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
        </div>

        <div className={clsx(classes.input_container, classes.address_address_container)}>
          <CustomTextField
            value={formDetails.address.address}
            stateKey={"address.address"}
            handleChange={handleTextInput}
            label={""}
            type={"text"}
            customStyle={{ margin: "0" }}
            fullWidth
            disabled
          />
        </div>
        <CustomTextField
          label={languagePackage.POST_CODE}
          value={formDetails.address.postalCode}
          type={"text"}
          stateKey={"address.postalCode"}
          handleChange={handleTextInput}
          fullWidth
          isFlex
          disabled
          // customStyle={{ width: 300 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h2">{languagePackage.JOB_DETAIL}</Typography>
        <CustomTextField
          label={languagePackage.CURRENT_COMPANY}
          value={formDetails.companyName}
          stateKey={"companyName"}
          type={"text"}
          handleChange={handleTextInput}
          fullWidth
          disabled
        />
        <div className={clsx(classes.double_input_container, classes.marginTop)}>
          <div className={classes.double_input_inner_container}>
            <CustomTextField
              stateKey={"phoneNumber"}
              value={formDetails.companyPhone}
              handleChange={handleTextInput}
              label={languagePackage.COMPANY_PHONE}
              type={"tel"}
              customStyle={{ margin: 0 }}
              fullWidth
              disabled
            />
          </div>
          <div>
            <CustomTextField
              stateKey={"companyPhonePrefix"}
              value={formDetails.companyPhonePrefix}
              handleChange={handleTextInput}
              label={languagePackage.PHONE_CODE}
              type={"text"}
              customStyle={{ margin: "0" }}
              fullWidth
              disabled
            />
          </div>
        </div>

        <p className={clsx(classes.input_title, classes.marginTop)}>{languagePackage.COMPANY_ADDRESS}</p>
        <div className={classes.address_container}>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              value={formDetails.companyAddress.country}
              statusIndex={0}
              countryList={countryList}
              stateKey={"companyAddress.country"}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
          <div className={classes.double_input_inner_container}>
            <CountryStateSelector
              countryCode={formDetails.companyAddress.country}
              value={formDetails.companyAddress.city}
              statusIndex={1}
              countryList={countryList}
              stateKey={"companyAddress.city"}
              handleChange={handleDropdownList}
              disabled
            />
          </div>
        </div>

        <div className={clsx(classes.input_container, classes.address_address_container)}>
          <CustomTextField
            value={formDetails.companyAddress.address}
            stateKey={"companyAddress.address"}
            handleChange={handleTextInput}
            label={""}
            type={"text"}
            customStyle={{ margin: "0" }}
            fullWidth
            disabled
          />
        </div>
        <CustomTextField
          value={formDetails.companyAddress.postalCode}
          label={languagePackage.POST_CODE}
          type={"text"}
          stateKey={"companyAddress.postalCode"}
          handleChange={handleTextInput}
          fullWidth
          isFlex
          disabled
        />
        <Typography variant="h2" className={classes.spacing}>
          {languagePackage.BANK_ACCOUNT_SETTING}
        </Typography>
        <CustomTextField
          label={languagePackage.USER_NAME}
          value={formDetails.username}
          stateKey="username"
          handleChange={handleTextInput}
          fullWidth={true}
          disabled
        />
        <CustomTextField
          stateKey={"referralCode"}
          value={formDetails.referralCode}
          handleChange={handleTextInput}
          label={languagePackage.INVITE_CODE}
          type={"copy"}
          disabled
        />

        <p className={clsx(classes.input_title, classes.marginTop)}>{languagePackage.REFERRER}</p>

        <div className={classes.input_spacer}>
          {formDetails.referralBy.ID ? (
            <RedirectText
              id={formDetails.referralBy.ID}
              text={formDetails.referralBy.name ? formDetails.referralBy.name : formDetails.referralBy.email}
              tooltip=""
              handleClick={handleRedirect}
            />
          ) : (
            "-"
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const MemberProfile: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <FormDetails />
      </div>
    </React.Fragment>
  );
};

export default MemberProfile;
