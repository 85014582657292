import fetchData from "../../utils/fetchData";

export function checkUserLogin() {
  return fetchData({
    url: "/users/check-login",
    method: "GET",
  });
}

export function getCountryList() {
  return fetchData({
    url: "/generals/select-country-list",
    method: "GET",
  });
}

export type ResetPasswordProps = {
  password: string;
  token: string;
};

export type UpdateProfitsProps = {
  rates: Array<number>;
};

export function getProfitCompanyProfitRates() {
  return fetchData({
    url: `/users/commission`,
    method: "GET",
  });
}

export function updateProfitCompanyProfitRates(body: UpdateProfitsProps) {
  return fetchData({
    url: `/users/commission`,
    method: "PUT",
    data: body,
  });
}

export function getNewsList(params: any) {
  const { page, limits, order, orderBy, startDate, endDate } = params;
  return fetchData({
    url: "/generals/news",
    method: "GET",
    params: params,
  });
}
export type NewsDetailsProps = {
  title: string;
  content: string;
  order: number;
  forceOrder: boolean;
};

export type NewsProps = NewsDetailsProps & {
  ID: number;
  createdAt: string;
};

export function dashboardData() {
  return fetchData({
    url: "/generals/dashboard",
    method: "GET",
  });
}

export function createNews(body: NewsDetailsProps) {
  return fetchData({
    url: "generals/news/",
    method: "POST",
    data: body,
  });
}

export function getNews(ID: number) {
  return fetchData({
    url: `generals/news/${ID}`,
    method: "GET",
  });
}

export function updateNews(ID: number, body: NewsDetailsProps) {
  return fetchData({
    url: `generals/news/${ID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteNews(ID: number) {
  return fetchData({
    url: `generals/news/${ID}`,
    method: "DELETE",
  });
}
