import { makeStyles } from "@material-ui/core/styles";
import { useProps } from "../../contexts";
import default_img from "../../assets/no_video.png";
import ZoomIcon from "@material-ui/icons/ZoomOutMap";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import LockOpenSharpIcon from "@material-ui/icons/LockOpenSharp";
import React from "react";
import VideoPlayerHolder from "./VideoPlayerHolder";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  upload_container: {
    backgroundColor: "white",
    // backgroundColor: "#585656",
    border: "1px solid grey",
    height: "100%",
    width: "100%",
    borderRadius: "15px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    zIndex: 7,
  },
  lock_container: {
    // backgroundColor: "white",
    backgroundColor: "#585656",
    border: "1px solid grey",
    height: "100%",
    width: "100%",
    borderRadius: "15px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  lock_sub_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img_size: {
    width: "100px",
  },
  dummy_box: {
    position: "absolute",
    marginTop: "-1px",
    top: 0,
    left: -2,
    height: "50px",
    width: "302px",
    backgroundColor: "white",
    zIndex: 6,
    [theme.breakpoints.up("md")]: {
      height: "50px",
      width: "42vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  dummy_box_bottom: {
    bottom: 0,
    position: "absolute",
    marginBottom: "-1px",
    left: -2,
    height: "50px",
    width: "303px",
    backgroundColor: "white",
    zIndex: 6,
    [theme.breakpoints.up("md")]: {
      height: "50px",
      width: "42vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  iframe_container: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
    width: "100%",
  },
  VR_iframe_container: {
    marginTop: "-46px",
    height: "445px",
    [theme.breakpoints.down("lg")]: {
      height: "345px",
      marginTop: "-47px",
    },
  },
  zoom_icon_container: {
    zIndex: 7,
    width: "50px",
    height: "50px",
    position: "absolute",
    right: 0,
    bottom: "50px",
  },
  lock_font: {
    color: "white",
    marginBottom: "5px",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

type VideoPlayerProps = {
  urlLink: string;
  isUnlock?: boolean;
  isVR?: boolean;
  handleSelectedVRPopUp?: (link: string, title: string) => void | undefined;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  urlLink,
  isUnlock,
  isVR = false,
  handleSelectedVRPopUp,
}) => {
  const classes = useStyles();
  const { languagePackage } = useProps();

  let title = languagePackage.PRODUCT_VIDEO;
  if (isVR) title = languagePackage.PRODUCT_VR;

  return (
    <React.Fragment>
      {isUnlock ? (
        urlLink ? (
          <div className={clsx(classes.iframe_container, isVR && classes.VR_iframe_container)}>
            {isVR ? <div className={classes.dummy_box} /> : null}

            {isVR ? (
              <IconButton
                className={classes.zoom_icon_container}
                onClick={(e) => {
                  handleSelectedVRPopUp && handleSelectedVRPopUp(title, urlLink);
                }}
              >
                <ZoomIcon />
              </IconButton>
            ) : null}
            {isVR ? (
              <iframe
                name="myFrame"
                id="myFrame"
                style={{ height: "100%", width: "100%" }}
                frameBorder="0"
                src={urlLink}
              ></iframe>
            ) : (
              <ReactPlayer controls={true} height={"100%"} width={"100%"} url={urlLink} />
            )}
            {isVR ? <div className={classes.dummy_box_bottom} /> : null}
          </div>
        ) : (
          <VideoPlayerHolder isEmpty />
        )
      ) : (
        <VideoPlayerHolder />
      )}
    </React.Fragment>
  );
};

export default VideoPlayer;
