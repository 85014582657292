import fetchData from "../../utils/fetchData";
import {UpdateObjType} from "./members";

export type CompanyDetailsProps = {
  adminID: number;
  username: string;
  name: string;
  status: number;
  companyID: number;
  companyName: string;
  companyRegisterNumber: string;
  coins: number;
  createdAt: string;
};

export function getCompanyPagination(params: any) {
  return fetchData({
    url: "/company",
    method: "GET",
    params: params,
  });
}
export function getCompanyProfile(companyID: number) {
  return fetchData({
    url: `/company/${companyID}/business`,
    method: "GET",
  });
}

export function getManagerInfo(companyID: number) {
  return fetchData({
    url: `/company/${companyID}/manager`,
    method: "GET",
  });
}

export function getRoleManagerInfo(companyID: number) {
  return fetchData({
    url: `/company/${companyID}/roles`,
    method: "GET",
  });
}

export function getSeriesPagination(params: any) {
  const { companyID, pageDetails } = params;
  return fetchData({
    url: `/company/${companyID}/series`,
    method: "GET",
    params: pageDetails,
  });
}

export function getSeries(seriesID: number) {
  return fetchData({
    url: `/company/series/${seriesID}`,
    method: "GET",
  });
}

export function getProductPagination(params: any) {
  const { seriesID, pageDetails } = params;
  return fetchData({
    url: `/company/series/${seriesID}/product`,
    method: "GET",
    params: pageDetails,
  });
}
export function getProduct(productID: number) {
  return fetchData({
    url: `/company/product/${productID}`,
    method: "GET",
  });
}
export function updateProductStatus(params: any) {
  const {productID,details} = params;
    return fetchData({
    url: `/company/product/${productID}`,
    method: "PUT",
    data:details,
  });
}
export function getBillingsPagination(params: any) {
  const { companyID, pageDetails } = params;
  return fetchData({
    url: `/company/${companyID}/billings/`,
    method: "GET",
    params: pageDetails,
  });
}

export function getReferralList(companyID: number) {
  return fetchData({
    url: `/company/${companyID}/referral/`,
    method: "GET",
  });
}

export function getReferralBillingsPagination(params: any) {
  const { companyID, pageDetails } = params;
  return fetchData({
    url: `/company/${companyID}/referral-billings`,
    method: "GET",
    params: pageDetails,
  });
}

export function updateAdminStatus(body: UpdateObjType) {
  return fetchData({
    url: `/company/${body.ID}/status`,
    method: "PUT",
    data: body,
  });
}
export function checkCompanyStatus(companyID: number) {
  return fetchData({
    url: `/company/${companyID}/check-status`,
    method: "GET",
  });
}
