import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import useQuery from "../../hooks/useQuery";
import { getRoutesWithPath } from "../../utils";
import { routes } from "../../configs/routes";
import { PageProps, ParamTypes } from "../../types/common";
import { ProductProps } from "../../types/company";
import { useProps } from "../../contexts";
// Components
import CustomTable from "../components/CustomTable";
import DeletePopUp from "../components/Popups/DeletedPopUp";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
//Controller
import { HandleGetInitProductListData } from "../../controller/ProductListPageController";

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "name", label: "名稱", align: "left" },
  { id: "accessCode", label: "通行碼", align: "left" },
  { id: "createdAt", label: "創建日期 時間", align: "left" },
  { id: "view", label: "檢視", align: "left" },
];

const generateBody = (
  classes: any,
  body: Array<ProductProps>,
  page: number,
  rowsPerPage: number,
  handleDeleteDialog: any,
  handleToProduct: any
) => {
  return body.map((e: ProductProps, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    name: e.name === "" ? "-" : e.name,
    accessCode: e.accessCode || '-',
    createdAt: e.createdAt,
    view: (
      <IconButton aria-label="toggle password visibility" onClick={() => handleToProduct(e.ID)} edge="end">
        <Visibility />
      </IconButton>
    ),
  }));
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 0",
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  businessName: {
    fontSize: 16,
    marginBottom: 30,
  },
  checkIcon: {
    color: theme.palette.secondary.main,
  },
  cancelIcon: {},
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}));
type ProductAllProps = PageProps & {
  list: [];
  // list: Array<ProductProps>;
  totals: number;
  order: string;
  orderBy: string;
  deleteDialogID: number;
  deleteDialogContent: string;
};
const CompanyProductList = () => {
  const classes = useStyles();
  const { companyID, seriesID } = useParams<ParamTypes>();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [query, setQuery] = useQuery();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [mainState, _setMainState] = useState<ProductAllProps>({
    list: [],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
    deleteDialogID: 0,
    deleteDialogContent: "",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  //API
  if (seriesID !== undefined) {
    let ID: any = seriesID;
    HandleGetInitProductListData(ID, mainState, setMainState, _handleChange);
  }

  const handleDeleteDialog = useCallback(
    (id: number, name: string) => () => {
      setMainState({ deleteDialogID: id, deleteDialogContent: name });
      setOpenDeleteDialog(true);
    },
    []
  );

  const handleToProductList = (id: number) => {
    history.push(getRoutesWithPath(routes.navBar.companyProductsPage.path, [companyID, seriesID, id]));
  };

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.totals,
    handleDeleteDialog,
    handleToProductList
  );

  return (
    <div className={classes.wrapper}>
      <CustomTable
        header={header}
        body={body}
        rowsPerPageOptions={[10, 25, 100]}
        count={mainState.totals}
        rowsPerPage={mainState.limits}
        page={mainState.page}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
        noBorder={true}
      />
      <DeletePopUp
        itemName={mainState.deleteDialogContent}
        isOpen={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        handleSubmit={() => {}}
      />
    </div>
  );
};

export default CompanyProductList;
