import React, {useEffect, useState} from 'react';
import Router from "./Router";
// Functions
import {initialLocalStorageSettings} from "./utils";
// Components
import CustomizedSnackbar from './pages/components/Snackbar';
import CustomBackdrop from './pages/components/Backdrop';
// Material UI
import {MuiThemeProvider} from "@material-ui/core/styles";
// contexts
import {theme, utilsContext, defaultStatus} from "./contexts";
// types
import {IApp} from "./types/app";

const App = () => {
    // add context provider at here
    const [utilsContextState, setUtilsContext] = useState(defaultStatus);

    const handleUtilsContextChange = (newState: IApp): void => {
        setUtilsContext((prevState: IApp) => ({
            ...prevState,
            ...newState,
        }));
    };

    useEffect(() => {
        initialLocalStorageSettings(utilsContextState, handleUtilsContextChange);
    }, [utilsContextState.user.isLogin])

    return (
        <utilsContext.Provider
            value={{
                ...utilsContextState,
                _handleChange: handleUtilsContextChange,
            }}
        >
            <MuiThemeProvider theme={theme}>
                <CustomBackdrop isOpen={utilsContextState.openBackdrop}/>
                <CustomizedSnackbar
                    open={utilsContextState.snackbar}
                    type={utilsContextState.snackbarType}
                    content={utilsContextState.snackbarContent}
                    handleOpen={handleUtilsContextChange}
                />
                <Router/>
            </MuiThemeProvider>
        </utilsContext.Provider>
    )
}

export default App;
