import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    iconTextWrapper: {
        margin: 0,
    },
    finishedStatus: {
        color: theme.palette.primary.main,
    },
    pendingStatus: {
        color: theme.palette.secondary.main,
    }
}));

type StatusTextProps = {
    text: string;
    status: number;
};

const StatusText: React.FC<StatusTextProps> = (props) => {
    const {text, status} = props;
    const classes = useStyles();
    return (
        <React.Fragment>
            {status === 0 && <Typography className={clsx(classes.iconTextWrapper, classes.pendingStatus)}>{text}</Typography>}
            {status === 1 && <Typography className={clsx(classes.iconTextWrapper, classes.finishedStatus)}>{text}</Typography>}
        </React.Fragment>
    );
};

export default StatusText;
