import {
    createManager,
    deleteManagerDetails,
    getManagerDetails,
    getManagerList,
    updateManagerDetails
} from "../../services/api/manager";
import { apiMiddleware } from "../../utils/middleware";
import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import { languagePackage } from "../../types/common";
import {isEmpty, validEmail} from "../../utils/validation";

const contextParams: { [key: string]: any } = {
    snackbar: true,
    snackbarType: 1,
    snackbarContent: "",
};

export const getManagerListAPI = async (
    mainState: any,
    setMainState: any,
    changeContext: any,
    setQuery: any
) => {
    let params = {
        page: mainState.page,
        limits: mainState.limits,
        order: mainState.order,
        orderBy: mainState.orderBy,
    };

    const { success, data } = await apiMiddleware(changeContext, getManagerList, params);
    if (!success) return;

    const list = data.data.map((e: any) => ({
        ID: e.ID,
        name: e.name,
        country: e.country,
        email: e.email,
        createdAt: e.createdAt,
    }))

    setMainState({ bodyData: list, totals: data.totals});
    setQuery(params)
};

export const getManagerDetailsAPI = async (
    managerID: any,
    setMainState: any,
    changeContext: any,
) => {
    const { success, data } = await apiMiddleware(changeContext, getManagerDetails, managerID);
    if (!success) return;

    const resData = {
        username: data.username,
        name: data.name,
        email: data.email,
        countryShortCode: data.countryShortCode,
        countryID: data.countryID,
        flowValues: data.rates.map((r: number) => ({value: r}))
    }
    setMainState(resData)
}

export const
    updateManagerDetailsAPI = async (
    managerID: string | undefined,
    mainState: any,
    changeContext: any,
    languagePackage: languagePackage,
) => {
    const {username, name, email, flowValues, password} = mainState;
    const verifyArr = [
        isEmpty(username, languagePackage.USER_NAME),
        isEmpty(email, languagePackage.EMAIL),
    ];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);

    if (!isValidate) return false;

    const bodyData = {
        ID: managerID,
        username,
        name,
        email,
        password,
        rates: flowValues.map((e:any) => e.value),
    }

    const { success } = await apiMiddleware(changeContext, updateManagerDetails, bodyData);
    if (!success) return;

    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Successfully updated!";
    changeContext(contextParams)
}

export const deleteManagerAPI = async (
    managerID: string | undefined,
    changeContext: any,
) => {
    const {success} = await apiMiddleware(changeContext, deleteManagerDetails, managerID);
    if (!success) return;

    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Successfully delete!";
    changeContext(contextParams)
}

export const createManagerAPI = async (
    mainState: any,
    setMainState: any,
    setOpenDialog: any,
    changeContext: any,
    languagePackage: languagePackage,
) => {
    const {username, password, name, email, countryName, countryCode} = mainState;
    const verifyArr = [
        isEmpty(countryCode, languagePackage.COUNTRY),
        isEmpty(username, languagePackage.USER_NAME),
        isEmpty(password, languagePackage.PASSWORD),
        isEmpty(name, languagePackage.NAME),
        validEmail(email),
    ];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);

    if (!isValidate) return false;

    const bodyData = {
        username,
        name,
        email,
        password,
        countryName,
        countryValue: countryCode,
    }

    const { success } = await apiMiddleware(changeContext, createManager, bodyData);
    if (!success) return;

    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Successfully Create!";
    changeContext(contextParams)
    setMainState({username: '', password: '', name: '', email: '', countryCode: '', refreshList: !mainState.refreshList})
    setOpenDialog(false)
}
