export default function useCheckIsValidation(validateArr: any, handleChange: any) {
  let errMassage = "";
  try {
    validateArr.forEach((i: any) => {
      if (i) {
        errMassage = i;
        return;
      }
    });
  } catch (err) {
    return;
  }

  if (errMassage) {
    let contextParams: { [key: string]: any } = {
      snackbar: true,
      snackbarType: 1,
      snackbarContent: errMassage,
    };
    handleChange(contextParams);
    return false;
  } else {
    return true;
  }
}
