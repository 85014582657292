import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {routes} from "../../configs/routes";
import {useHistory} from "react-router";
import {ParamTypes} from "../../types/common";
// Material UI
import {Paper, Typography, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Components
import {useProps} from "../../contexts";
import BackButton from "../components/BackButton";
import CustomTextField from "../components/Inputs/CustomTextField";
import CountryStateSelector from "../components/CountryStateSelector";
import SliderCard from "../components/SliderCard";
import DoubleConfirmDeletePopUp from "../components/Popups/DoubleConfirmDeletePopup";
// Assets
import PersonIcon from "../../assets/login_personal.png";
import CustomPasswordField from "../components/Inputs/CustomPasswordField";
import CustomButtons from "../components/Inputs/CustomButtons";
// Controllers
import {getCountryListAPI} from "../../controller/GeneralController";
import {
    deleteManagerAPI,
    getManagerDetailsAPI,
    updateManagerDetailsAPI
} from "../../controller/SuperAdmin/ManagerController";

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.primary.light,
        marginTop: 30,
        padding: 30,
    },
    title: {
        marginLeft: -100,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -80,
        },
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    countryWrapper: {

    },
    countryText: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 30,
        marginLeft: 10,
        marginBottom: 5,
    },
    commonWrapper: {
        backgroundColor: theme.palette.primary.light,
        textAlign: "center",
        boxShadow: "1px 1px 10px #d2d2d2ba",
    },
    iconWrapper: {
        backgroundColor: theme.palette.primary.light,
        padding: "43px 20px",
    },
    contentWrapper: {
        textAlign: "center",
        padding: 20,
    },
    personIcon: {
        width: 50,
    },
    wrapperSpacing: {
        marginTop: 30,
        [theme.breakpoints.down("sm")]: {
            marginTop: 60,
        }
    },
    buttonWrapper: {
        marginTop: 50,
        textAlign: 'right',
    }
}))

const ManagerDetails: React.FC = () => {
    const classes = useStyles();
    const { languagePackage, _handleChange } = useProps();
    const { managerID } = useParams<ParamTypes>();
    const history = useHistory();
    const [countryList, setCountryList] = useState([])
    const [deleteDialog, handleDeleteDialog] = useState(false)
    const [mainState, _setMainState] = useState({
        username: "",
        name: "",
        email: "",
        countryID: "",
        countryShortCode: "",
        password: "",
        flowValues: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
    });

    const setMainState = useCallback((newState) => {
        _setMainState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    const handleChange = useCallback((event, prop) => {
            setMainState({ [prop]: event.target.value });
    }, [setMainState]);

    const handleDropdownList = (event: { value: string; label: string }, statusKey: string) => {
        setMainState({[statusKey]: event.value})
    };

    const handleTotalValue = useCallback(
        (event: any, index: number, value: number) => {
            const { flowValues } = mainState;
            const tmpData: any = [...flowValues];
            tmpData[index]["value"] = value;

            let list = checkFullProfit(tmpData);
            setMainState({ flowValues: list });
        },
        [mainState, setMainState]
    );

    const checkFullProfit = (valueList: [{ value: number }]) => {
        let list: any = [...valueList];
        let checkTotal: number = 0;
        for (let key in list) {
            if (checkTotal >= 100) list[key]["value"] = 0;
            else checkTotal += list[key]["value"];
        }
        return list;
    };

    const handleUpdate = async () => {
        await updateManagerDetailsAPI(managerID, mainState, _handleChange, languagePackage)
    }

    const handleDelete = async () => {
        await deleteManagerAPI(managerID, _handleChange)
        history.push(routes.navBar.managerList.path)
    }

    useEffect(() => {
        getCountryListAPI(_handleChange, setCountryList)
        getManagerDetailsAPI(managerID, setMainState, _handleChange)
    }, [])

    return (
        <React.Fragment>
            <div className={classes.titleWrapper}>
                <BackButton to={routes.navBar.managerList.path} />
                <Typography variant="h1" className={classes.title}>{routes.navBar.managerList.text}</Typography>
                <div/>
            </div>

            <Paper className={classes.container} elevation={1}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            label={'帳號'}
                            value={mainState.username}
                            stateKey="username"
                            handleChange={handleChange}
                            fullWidth
                        />
                        <CustomTextField
                            label={'姓名'}
                            value={mainState.name}
                            stateKey="name"
                            handleChange={handleChange}
                            fullWidth
                        />
                        <CustomTextField
                            label={'信箱'}
                            value={mainState.email}
                            stateKey="email"
                            handleChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.countryWrapper}>
                            <Typography className={classes.countryText}>國家</Typography>
                            <CountryStateSelector
                                countryCode={mainState.countryShortCode}
                                value={mainState.countryShortCode}
                                stateKey="countryShortCode"
                                statusIndex={0}
                                disabled
                                countryList={countryList}
                                handleChange={handleDropdownList}
                            />
                        </div>

                        <CustomPasswordField
                            value={mainState.password}
                            label={languagePackage.PASSWORD}
                            stateKey={"password"}
                            handleChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid
                    className={classes.wrapperSpacing}
                    container
                    spacing={2}
                    justifyContent="space-evenly"
                >
                    {mainState.flowValues.map((items, index) => {
                        return (
                            <Grid key={`paper-${index}`} item xs={6} md={4} lg={2}>
                                <SliderCard
                                    iconImage={PersonIcon}
                                    index={index}
                                    flowValues={[{value: items.value}]}
                                    value={items.value}
                                    handleTotalValue={handleTotalValue}
                                />
                            </Grid>
                        );
                    })}
                </Grid>

                <div className={classes.buttonWrapper}>
                    <CustomButtons
                        label={languagePackage.DELETE}
                        handleClick={() => {handleDeleteDialog(true)}}
                    />
                    <CustomButtons
                        label={languagePackage.UPDATE}
                        themeColor={'secondary'}
                        customStyle={{marginLeft: 10}}
                        handleClick={handleUpdate}
                    />
                </div>
            </Paper>

            <DoubleConfirmDeletePopUp
                itemName={mainState.username}
                isOpen={deleteDialog}
                setOpen={handleDeleteDialog}
                handleSubmit={handleDelete}
            />

        </React.Fragment>
    )
}

export default ManagerDetails;
