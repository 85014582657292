import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import AppsIcon from "@material-ui/icons/Apps";
import ArchiveIcon from "@material-ui/icons/Archive";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import CollectionsIcon from "@material-ui/icons/Collections";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import FilterIcon from "@material-ui/icons/Filter";
import SportsEsportsOutlinedIcon from "@material-ui/icons/SportsEsportsOutlined";
import SdCardIcon from "@material-ui/icons/SdCard";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
import TurnedInNotOutlinedIcon from "@material-ui/icons/TurnedInNotOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Grid, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  list_container: {
    padding: "10px 10px 10px 0",
    height: "40vh",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  cell_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "row",
  },
  formControlLabel: {
    margin: 0,
    alignItems: 'baseline',
  },
}));

export const iconObjList = [
  {
    value: "ATOI",
    icon: AttachmentOutlinedIcon,
    selected: false,
  },
  {
    value: "AOI",
    icon: AssessmentOutlinedIcon,
    selected: false,
  },
  {
    value: "AFOI",
    icon: AttachFileOutlinedIcon,
    selected: false,
  },
  {
    value: "DOI",
    icon: DescriptionOutlinedIcon,
    selected: false,
  },
  {
    value: "IDFOI",
    icon: InsertDriveFileOutlinedIcon,
    selected: false,
  },
  {
    value: "LRI",
    icon: ListRoundedIcon,
    selected: false,
  },
  {
    value: "SI",
    icon: SettingsIcon,
    selected: false,
  },
  {
    value: "CMOI",
    icon: ContactMailOutlinedIcon,
    selected: false,
  },
  {
    value: "ABAOI",
    icon: AccountBalanceOutlinedIcon,
    selected: false,
  },
  {
    value: "ACOI",
    icon: AccountCircleOutlinedIcon,
    selected: false,
  },
  {
    value: "ABOI",
    icon: AccountBoxOutlinedIcon,
    selected: false,
  },
  {
    value: "SAOI",
    icon: SupervisorAccountOutlinedIcon,
    selected: false,
  },
  {
    value: "APPI",
    icon: AppsIcon,
    selected: false,
  },
  {
    value: "ARCI",
    icon: ArchiveIcon,
    selected: false,
  },
  {
    value: "ARI",
    icon: ArrowRightIcon,
    selected: false,
  },
  {
    value: "ARAI",
    icon: ArrowRightAltIcon,
    selected: false,
  },
  {
    value: "PAOI",
    icon: PlayArrowOutlinedIcon,
    selected: false,
  },
  {
    value: "CI",
    icon: CollectionsIcon,
    selected: false,
  },
  {
    value: "VOI",
    icon: ViewListOutlinedIcon,
    selected: false,
  },
  {
    value: "FI",
    icon: FilterIcon,
    selected: false,
  },
  {
    value: "SEOI",
    icon: SportsEsportsOutlinedIcon,
    selected: false,
  },
  {
    value: "SCI",
    icon: SdCardIcon,
    selected: false,
  },
  {
    value: "BOI",
    icon: BookmarksOutlinedIcon,
    selected: false,
  },
  {
    value: "TNOI",
    icon: TurnedInNotOutlinedIcon,
    selected: false,
  },
  {
    value: "PADI",
    icon: PictureAsPdfIcon,
    selected: false,
  },
  {
    value: "COI",
    icon: CropOriginalIcon,
    selected: false,
  },
  {
    value: "PCOI",
    icon: PhotoCameraOutlinedIcon,
    selected: false,
  },
  {
    value: "NNOI",
    icon: NotificationsNoneOutlinedIcon,
    selected: false,
  },
  {
    value: "LMOI",
    icon: LocalMallOutlinedIcon,
    selected: false,
  },
];
type IconListPros = {
  selectedValue: string;
  handleCheck: (value: string) => void;
};
const IconSelectList: React.FC<IconListPros> = ({ selectedValue, handleCheck }) => {
  const classes = useStyles();

  const dataList = iconObjList.map((i) => {
    if (i.value === selectedValue) {
      i.selected = true;
    } else {
      i.selected = false;
    }
    return i;
  });

  return (
    <div className={classes.list_container}>
      <Grid container spacing={3}>
        {dataList.map((i) => {
          let ICON = i.icon;
          return (
            <Grid item xs={6} sm={6} lg={4} className={classes.cell_container}>
              <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox
                        checked={i.selected}
                        onChange={() => handleCheck(i.value)}
                        name="checked"
                        color="primary"
                    />
                  }
                  label={<ICON />}
              />
              {/*<Checkbox*/}
              {/*  checked={i.selected}*/}
              {/*  onChange={() => handleCheck(i.value)}*/}
              {/*  name="checked"*/}
              {/*  color="primary"*/}
              {/*/>*/}
              {/*<ICON />*/}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default IconSelectList;
