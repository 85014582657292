import { checkUserLogin, getCountryList } from "../services/api/general";

export const getCountryListAPI = async (changeContext: any, setCountryList: any) => {
  changeContext({ openBackdrop: true });

  let contextParams: { [key: string]: any } = {
    snackbar: true,
    snackbarType: 1,
    snackbarContent: "",
    openBackdrop: false,
  };
  try {
    const result = await getCountryList();
    const { success, data } = result;
    changeContext({ openBackdrop: false });
    if (success) {
      setCountryList(data.list);
    } else {
      contextParams.snackbarContent = data.message;
      changeContext(contextParams);
    }
  } catch (error) {
    contextParams.snackbarContent = error;
    changeContext(contextParams);
  }
};

export const checkUserLoginAPI = async () => {
  const { data } = await checkUserLogin();
  return {
    username: data.username,
    isSuperAdmin: data.isSuperAdmin,
    isLogin: data.isLogin,
    role: 0,
  };
};
