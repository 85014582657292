import React, { useEffect } from "react";
import { routes } from "../../../configs/routes";
// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
// Assets
import Logo from "../../../assets/SYB_logo_clean.png";
import HamburgerDrawer from "./HamburgerDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router";
import { handleLogoutAPI } from "../../../controller/LoginPageController";
import { useProps } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    padding: "0 30px",
    zIndex: 10,
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftToolWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "75%",
  },
  toolWrapper: {
    display: "flex",
    alignItems: "center",
  },
  popWrapper: {
    zIndex: 10,
  },
  toolButton: {
    color: theme.palette.primary.light,
    transition: 'all .2s ease-in-out',
    "&:hover": {
      color: theme.palette.primary.dark,
    }
  },
  iconButton: {
    fontSize: 40,
  },
  greetingText: {
    color: theme.palette.primary.light,
    // fontSize: 20,
    [theme.breakpoints.down("md")]: {
      flex: 1,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, _handleChange } = useProps();
  const theme = useTheme();
  const isSizeDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogout = (event:any) => {
    handleLogoutAPI(_handleChange, user.isSuperAdmin).then((isSuccess:any) => {
      if (isSuccess) {
        history.push(routes.generals.login.path);
      }
    });
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      if (anchorRef.current) anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftToolWrapper}>
        {isSizeDevice && <HamburgerDrawer />}
        <img className={classes.logo} src={Logo} alt="syb-logo" />
      </div>
      <div className={classes.toolWrapper}>
        <Typography className={classes.greetingText}>你好，{user.username}！</Typography>
        <IconButton
            className={classes.toolButton}
            aria-label="inspect"
            onClick={handleLogout}
        >
          <PowerSettingsNewIcon />
        </IconButton>
        <Popper
          className={classes.popWrapper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={(e)=>handleLogout(e)}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Header;
