import React from "react";
import clsx from "clsx";
import { SelectChangeEventHandler } from "../../../types/common";
import { useProps } from "../../../contexts";
import configVariables from "../../../configs/variables.json";
// Components
import CustomSelect from "../Inputs/CustomSelect";
// Assets
import close_icon from "../../../assets/001-close.svg";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "20px",
      backgroundColor: "white",
    },
  },

  dialog_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    minHeight: "40vh",
    backgroundColor: "white",
    borderRadius: "50px",
  },
  icon_container: {
    alignSelf: "flex-end",
  },
  icon: {
    cursor: "pointer",
    width: "20px",
    "&:hover ": {
      filter: "invert(35%) sepia(55%) saturate(3225%) hue-rotate(338deg) brightness(87%) contrast(116%)",
    },
  },
  input_container: {
    display: "flex",
    padding: "20px 40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  input_title: {
    margin: "5px",
    marginBottom: "25px",
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  img_upload_file: {
    margin: "0px 0px 0px 10px",
    fontSize: "15px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
  },
  input_form_container: {
    margin: "0px 0px 0px 0px",
    minWidth:"200px",
    maxWidth:"200px",
    [theme.breakpoints.up("md")]: {
      width: "500px",
      maxWidth:"300px",
    },
  },
  input_form_img_container: {
    marginBottom: "30px",
  },
  img_upload_title: {
    margin: "20px 0px 0px 10px",
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  uploaded_image: {
    width: 150,
  },
  upload_button: {
    alignSelf: "center",
    marginTop: "5px",
    width: "100px",
    padding: "3px 0px",
    color: "white",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  submit_button: {
    alignSelf: "center",
    marginTop: "5px",
    width: "120px",
    padding: "8px 6px",
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  dialog_inner_container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  img_upload_container: {
    display: "flex",
    alignItems: "center",
  },
}));

type UploadProps = {
  title: string;
  selectID: number;
  selectStatus: number;
  imageName: string;
  imagePath: string;
  originalImage?: string;
  isOpen: boolean;
  handleClose: (event: any) => void;
  handleChange: (event: SelectChangeEventHandler) => void;
  handleSetFile: (event: any) => void;
  handleSubmit: (event: any) => void;
};

const UploadBankStatementPopUp: React.FC<UploadProps> = (props) => {
  const {
    title, selectStatus, imageName, imagePath, originalImage, isOpen, handleClose, handleChange, handleSetFile, handleSubmit
  } = props;
  const { languagePackage, _handleChange } = useProps();
  const classes = useStyles();
  const imageToShow = imagePath ? imagePath : originalImage;

  return (
    <Dialog
      className={classes.dialog}
      open={isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog_container}>
        <div className={classes.icon_container}>
          <img className={classes.icon} src={close_icon} onClick={handleClose} alt="close-icon" />
        </div>
        <div className={classes.dialog_inner_container}>
          <div className={classes.input_container}>
            <p className={classes.input_title}>{title}</p>
            <div className={clsx(classes.input_form_container)}>
              {/* TODO */}
              <p className={classes.img_upload_title}>{"狀態"}</p>
              <div className={classes.img_upload_container}>
                <CustomSelect
                  stateKey="status"
                  value={selectStatus}
                  items={configVariables.withdrawStatus}
                  handleChange={handleChange}
                  autoWidth
                />
              </div>
            </div>
            <div className={clsx(classes.input_form_container, classes.input_form_img_container)}>
              <p className={classes.img_upload_title}>{languagePackage.IMG}</p>
              {imageToShow && <img className={classes.uploaded_image} src={imageToShow} alt="syb-receipt"/>}

              <div className={classes.img_upload_container}>
                <label htmlFor="upload-bank">
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    id="upload-bank"
                    name="upload-bank"
                    onChange={handleSetFile}
                  />
                  <Button
                    className={classes.upload_button}
                    variant="contained"
                    color={"primary"}
                    startIcon={<CloudUploadIcon />}
                    component="span"
                  >
                    {languagePackage.UPLOAD}
                  </Button>
                </label>
                <p className={classes.img_upload_file}>{imageName}</p>
              </div>
            </div>
          </div>
          <Button
            className={classes.submit_button}
            variant="contained"
            color={"secondary"}
            onClick={handleSubmit}
          >
            {languagePackage.UPDATE}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UploadBankStatementPopUp;
