import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { UserAccountProps, UserPasswordProps } from "../types/user";
import { useProps } from "../contexts";
import { routes } from "../configs/routes";
//Component
import CustomTextField from "./components/Inputs/CustomTextField";
import CustomPasswordField from "./components/Inputs/CustomPasswordField";
//material Ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  HandleGetInitAccountData,
  handleUpdateAccountAPI,
  handleUpdateAccountPasswordAPI,
} from "../controller/AccountController";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
  },
  sub_header_title_container: {
    display: "flex",
    flexDirection: "row",
  },
  button_container: {
    textAlign: "end",
  },
  back_button: {
    fontSize: "15px",
    color: "white",
    padding: "5px 30px",
    marginTop: "10px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  update_button: {
    backgroundColor: theme.palette.secondary.main,
    padding: "5px 30px",
    marginTop: "10px",
    marginLeft: "10px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  cell_top_divider: {
    marginTop: "0px",
    [theme.breakpoints.up("lg")]: {
      marginTop: "220px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "100px",
    },
  },
  wrapper: {
    marginTop: "30px",
  },
  form_inner_container: {
    padding: 30,
  },
}));
const AdminAccFormRight: React.FC = () => {
  const classes = useStyles();
  const { languagePackage, _handleChange } = useProps();

  const [mainState, setMainState] = useState<UserPasswordProps>({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleTextInput = (event: { target: { value: any } }, statusKey: string) => {
    setMainState({ ...mainState, [statusKey]: event.target.value });
  };

  const handleSubmit = (event: any) => {
    handleUpdateAccountPasswordAPI(mainState.newPassword, mainState.password, _handleChange);
    setMainState({ password: "", newPassword: "", confirmPassword: "" }); //init
  };
  return (
    <Grid item xs={12} md={6} className={classes.form_inner_container}>
      <CustomPasswordField
        value={mainState.password}
        label={languagePackage.OLD_PASSWORD}
        stateKey={"password"}
        handleChange={handleTextInput}
        fullWidth
      />
      <CustomPasswordField
        value={mainState.newPassword}
        label={languagePackage.NEW_PASSWORD}
        stateKey={"newPassword"}
        handleChange={handleTextInput}
        fullWidth
      />

      <CustomPasswordField
        value={mainState.confirmPassword}
        label={languagePackage.CONFIRM_PASSWORD}
        stateKey={"confirmPassword"}
        handleChange={handleTextInput}
        fullWidth
      />
      <div className={classes.cell_top_divider} />
      <div className={classes.button_container}>
        <Button
          color="secondary"
          variant="contained"
          className={clsx(classes.back_button, classes.update_button)}
          onClick={(e) => handleSubmit(e)}
        >
          {languagePackage.UPDATE}
        </Button>
      </div>
    </Grid>
  );
};

const AdminAccFormLeft: React.FC = () => {
  const classes = useStyles();
  const { languagePackage, _handleChange } = useProps();
  const [formDetails, _setFormDetails] = useState<UserAccountProps>({
    username: "",
    name: "",
    email: "",
    country: "",
    countryID: 0,
  });

  const setFormDetails = useCallback((newState) => {
    _setFormDetails((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  HandleGetInitAccountData(setFormDetails, _handleChange);

  const handleTextInput = (event: { target: { value: any } }, statusKey: string) => {
    const key = statusKey;
    let value = event.target.value;
    setFormDetails({ ...formDetails, [key]: value });
  };

  const handleSubmit = (event: any) => {
    handleUpdateAccountAPI(formDetails.name, formDetails.email, languagePackage, _handleChange);
  };

  return (
    <Grid item xs={12} md={6} className={classes.form_inner_container}>
      <CustomTextField
        label={languagePackage.NAME}
        type={"text"}
        stateKey={"name"}
        value={formDetails.name}
        handleChange={handleTextInput}
        fullWidth
      />
      <CustomTextField
        label={languagePackage.EMAIL}
        type={"email"}
        stateKey={"email"}
        value={formDetails.email}
        handleChange={handleTextInput}
        fullWidth
      />
      <CustomTextField
        label={languagePackage.COUNTRY}
        type={"text"}
        stateKey={"country"}
        value={formDetails.country}
        handleChange={handleTextInput}
        fullWidth
        disabled
      />

      {/* <CustomTextField
        label="CountryID"
        type={"text"}
        stateKey={"countryID"}
        value={formDetails.countryID}
        handleChange={handleTextInput}
        disabled
        fullWidth
      /> */}
      <CustomTextField
        label={languagePackage.USER_NAME}
        type={"text"}
        stateKey={"username"}
        value={formDetails.username}
        handleChange={handleTextInput}
        fullWidth
        disabled
      />
      <div className={classes.button_container}>
        <Button
          color="secondary"
          variant="contained"
          className={clsx(classes.back_button, classes.update_button)}
          onClick={(e) => handleSubmit(e)}
        >
          {languagePackage.UPDATE}
        </Button>
      </div>
    </Grid>
  );
};

const AccountPage: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h1">{routes.navBar.accountSetting.text}</Typography>
      <div className={classes.wrapper}>
        <Grid className={classes.root} container>
          <AdminAccFormLeft />
          <AdminAccFormRight />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AccountPage;
