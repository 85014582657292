import React from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
//material-ui
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color:"rgba(0, 0, 0, 0.54)",
    // color: theme.palette.primary.contrastText,
  },
  todayDate: {
    color: theme.palette.primary.contrastText,
  },
  selectedDate: {
    // color: theme.palette.primary.main,
    color: "white",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "4px",
  },
  disableDate: {
    color: "lightgrey",
  },
  datepicker: {
    // width: "20%",
    width: "120px",
    margin: "0",
    "& input:hover": {
      cursor: "pointer"
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      top: "40px",
      left: "-5px",
    },
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "10px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    },
  },
  ".MuiPaper-root": {
    backgroundColor: "pink",
  },
}));
type DatePickerProps = {
  label?: string;
  stateKey: string;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  value: any;
  handleChange: (event: any, stateKey: string) => void;
};

const getObjDate = (obj: any) => {
  return moment(obj._d).format("YYYY-MM-DD");
};

const getObjDayFormat = (obj: any) => {
  return moment(obj._d).format("DD");
};
const getDisableDay = (obj: any, date: any,isStartDate:boolean) => {
  if(isStartDate)
  return moment(obj._d).isBefore(date);

  else
  return moment(obj._d).isAfter(date);

};
export default function CustomDatePickers(props: DatePickerProps) {
  const { label, stateKey, placeholder, minDate, maxDate, value, handleChange } = props;
  const classes = useStyles();

  const isStartDate:boolean = minDate !== undefined;
  const currentDate = minDate || maxDate;

  const renderDay = (date: any, selectedDate: any, dayInCurrentMonth: any) => {
    const dateClone = getObjDate(date);
    const dateDay = getObjDayFormat(date);
    const selectedDateClone = getObjDate(selectedDate);
    const todayDate = moment().format("YYYY-MM-DD");

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.todayDate]: dateClone === todayDate,
      [classes.selectedDate]: dateClone === selectedDateClone,
      [classes.disableDate]: getDisableDay(date,currentDate,isStartDate)
    });

    return (
      <div>
        <IconButton className={dayClassName}>
          <span>{dateDay}</span>
        </IconButton>
      </div>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        disableToolbar
        disablePast={!moment(value).isBefore(maxDate)}
        disableFuture={!moment(value).isAfter(minDate)}
        minDateMessage={""}
        maxDateMessage={""}
        className={classes.datepicker}
        placeholder={placeholder && placeholder}
        autoOk
        minDate={minDate && minDate}
        maxDate={maxDate && maxDate}
        id="date-picker-inline"
        variant="inline"
        inputVariant="outlined"
        color="secondary"
        format="DD-MM-YYYY"
        margin="normal"
        label={label}
        value={value}
        onChange={(event) => {
          handleChange(event, stateKey);
        }}
        renderDay={renderDay}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
