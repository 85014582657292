import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import moment from "moment";
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import "react-medium-image-zoom/dist/styles.css";
import { routes } from "../../configs/routes";
// Functions
import { getRoutesWithPath } from "../../utils";
import useQuery from "../../hooks/useQuery";
import { ParamTypes } from "../../types/common";
import { useProps } from "../../contexts";
// Components
import CustomTable from "../components/CustomTable";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
//Controller
import { SeriesProps } from "../../types/company";
import { HandleGetInitSeriesData } from "../../controller/SeriesListPageController";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 0 20px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  businessName: {
    fontSize: 16,
    marginBottom: 30,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  zoomImage: {
    width: 0,
  },
  showImage: {
    width: 100,
  }
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "name", label: "名稱", align: "left" },
  { id: "totalProduct", label: "產品數量", align: "left" },
  { id: "createdAt", label: "創建日期", align: "left" },
  { id: "image", label: "圖片", disabledSort: true, align: "left" },
  { id: "view", label: "檢視", disabledSort: true, align: "left" },
];

const generateBody = (
  classes: any,
  body: Array<SeriesProps>,
  page: number,
  rowsPerPage: number,
  handleToProduct: any,
  handleZoomChange: any,
) => {
  if (body.length === 0) return [];
  else {
    return body.map((e: SeriesProps, i) => ({
      id: (page - 1) * rowsPerPage + i + 1,
      name: e.name,
      totalProduct: e.totalProduct,
      createdAt: moment(e.createdAt).format("DD/MM/YYY"),
      image: <IconButton aria-label="inspect" onClick={handleZoomChange(i)}>
        <ImageSearchIcon />
      </IconButton>,
      view: (
        <IconButton aria-label="inspect" onClick={()=>handleToProduct(e.seriesID, e.name)}>
          <Visibility />
        </IconButton>
      ),
    }));
  }
};

const CompanySeriesList = () => {
  const classes = useStyles();
  const { companyID } = useParams<ParamTypes>();
  const history = useHistory();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.offset ? parseInt(query.offset) : 1,
    limits: query.limit || 10,
    totals: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "date",
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleZoomChange = (index: number) => (shouldZoom: any) => {
    const tmpState: Array<SeriesProps> = [...mainState.list];
    tmpState[index].isZoomed = shouldZoom;
    setMainState({list: tmpState})
  }

  //API
  if (companyID !== undefined) {
    let ID: any = companyID;
    HandleGetInitSeriesData(ID, mainState, setMainState, _handleChange);
  }

  const handleToProductList = (id: number) => {
    history.push(getRoutesWithPath(routes.navBar.companyProducts.path, [companyID, id]));
  };

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limits,
    handleToProductList,
    handleZoomChange
  );

  return (
    <div className={classes.wrapper}>
      <CustomTable
        header={header}
        body={body}
        rowsPerPageOptions={[10, 25, 100]}
        count={mainState.totals}
        rowsPerPage={mainState.limits}
        page={mainState.page}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
        noBorder={true}
      />

      {mainState.list.map((item: any, index) => (
          <ControlledZoom key={`image-${index}`} isZoomed={item.isZoomed} onZoomChange={handleZoomChange(index)}>
            <img
                alt="that wanaka tree"
                onLoad={() => {}}
                src={item.imageUrl}
                className={clsx(item.isZoomed && classes.showImage, classes.zoomImage)}
            />
          </ControlledZoom>
      ))}
    </div>
  );
};

export default CompanySeriesList;
