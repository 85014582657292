import { createContext, useContext } from "react";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import en from "./language/en";
import zh from "./language/zh-tw";
// types
import { IApp } from "../types/app";
import variables from "../configs/variables.json";
declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        gray: Palette["primary"];
        pink: Palette["primary"];
    }
    interface PaletteOptions {
        gray: PaletteOptions["primary"];
        pink: PaletteOptions["primary"];
    }
}
// A custom theme for this app
const breakpoints = createBreakpoints({});
let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 1024,
            xl: 1440,
        },
    },
    palette: {
        pink: {
            main: "#FFCCCC",
        },
        gray: {
            main: "rgb(191,191,191)",
            dark: "rgb(72,72,72)",
            light: "rgb(242,240,240)",
        },
        primary: {
            main: "#ED2C29",
            light: "#FFFFFF",
            contrastText: "#000000",
        },
        secondary: {
            main: "rgb(20,167,72)",
        },
        error: {
            main: "#19857b",
        },
        success: {
            main: "rgb(20,167,72)",
        },
        info: {
            main: "#3265B5",
        },
        background: {
            default: "#242021",
            paper: "#F7F7F7",
        },
        text: {
            disabled: '#8080804a'
        }
    },
    typography: {
        fontFamily: `'Noto Sans', sans-serif`,
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 900,
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: '24px !important',
                fontWeight: 'bold',
                [breakpoints.down("xs")]: {
                    fontSize: '22px !important',
                }
            },
            h2: {
                fontSize: '20px !important',
                fontWeight: 'bold',
                [breakpoints.down("xs")]: {
                    fontSize: '18px !important',
                }
            },
        }
    }
});

theme = responsiveFontSizes(theme);

let defaultStatus: IApp = {
    language: "zh",
    languagePackage: zh,
    snackbar: false,
    snackbarType: 0,
    snackbarContent: "Success",
    openBackdrop: false,
    user:{
        username:"",
        isLogin:false,
        isSuperAdmin:false,
        role:0,
    },
};
try {
    const json = window.localStorage.getItem(variables.local_storage_version);
    // defaultState = json ? JSON.parse(json) : defaultState;
    if (json) {
      defaultStatus = JSON.parse(json) as IApp;
    }
  } catch (error) {
    console.log("error get local storage");
  }

const utilsContext = createContext(defaultStatus);
const useProps = () => useContext(utilsContext);

export { en, zh, theme, defaultStatus, utilsContext, useProps };
