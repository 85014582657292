import React, {useCallback} from 'react';
import clsx from 'clsx';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import {Typography} from "@material-ui/core";
import {SelectChangeEventHandler} from "../../../types/common";

type SelectItems = {
    label: string | number;
    value: string | number;
}

type CustomSelectProps = {
    label?: string;
    stateKey: string;
    value: string | number | undefined,
    items: SelectItems[],
    handleChange?: (event: SelectChangeEventHandler,stateKey: string) => void;
    disabled?: boolean;
    incline?: boolean;
    autoWidth?: boolean;
    fullWidth?: boolean;
    flexStart?: boolean;
}

const SimpleInput = withStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.primary.main}`,
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: 'relative',
        padding: '9px 40px 9px 11px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 16,
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        alignItems: 'center',
    },
    displayIncline: {
        display: 'flex',
    },
    selectWrapper: {
        margin: `8px 0`,
        boxShadow: '1px 1px 1px #c9c9c9',
        border: 'none',
        borderRadius: 5,
        backgroundColor: theme.palette.gray.light,
        minWidth: 120,
        '&.Mui-focused': {
        }
    },
    menuItems: {},
    fullWidth: {
        width: '100%',
    },
    autoWidth: {
        width: 'auto',
    },
    nonMargin: {
        margin: 0,
    },
    selectLabel: {
        '&.MuiFormLabel-root.Mui-focused': {
            // color: theme.palette.secondary.main
        }
    },
    fieldLabel: {
        color: theme.palette.gray.dark,
        marginRight: 10,
        flex: 1,
        [theme.breakpoints.down('md')]: {
            flex: '.35',
        },
        [theme.breakpoints.down('xs')]: {
            flex: '.5',
        },
    },
    flexStart: {
        flex: 0,
    },
    flexEnd: {
        textAlign: 'end',
    }
}));

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
    const {
        label, stateKey, value, items, handleChange,
        disabled=false, incline=false, autoWidth=false, fullWidth=false, flexStart=false
    } = props;
    const classes = useStyles();

    const _handleChange = useCallback((event: SelectChangeEventHandler,key: string) => {
        handleChange && handleChange(event,key);
    }, [handleChange])

    return (
        <React.Fragment>
            <div className={clsx(classes.container, incline && classes.displayIncline)}>
                {label &&
                    <Typography className={clsx(
                        classes.fieldLabel,
                        flexStart && classes.flexStart,
                        incline && classes.flexEnd,
                    )}>
                        {label}
                    </Typography>
                }
                <Select
                    className={clsx(
                        classes.selectWrapper,
                        fullWidth && classes.fullWidth,
                        incline && classes.nonMargin,
                        autoWidth && classes.autoWidth,
                    )}
                    id={label}
                    color="primary"
                    disabled={disabled && disabled}
                    value={value}
                    onChange={(e)=>{_handleChange(e,stateKey)}}
                    input={<SimpleInput/>}
                >
                    {/*<MenuItem value={0} className={classes.menuItems}>*/}
                    {/*  -- Please Select --*/}
                    {/*</MenuItem>*/}
                    {
                        items.map((item, index) => (
                            <MenuItem
                                value={item.value}
                                key={index}
                                className={classes.menuItems}
                            >
                                {item.label}
                            </MenuItem>
                        ))
                    }
                </Select>
            </div>
        </React.Fragment>
    );
}

export default CustomSelect;
