import React, {useState, useCallback} from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import close_icon from "../../../assets/001-close.svg";
import Button from "@material-ui/core/Button";
import CustomTextField from "../Inputs/CustomTextField";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiPaper-root": {
            width: 600,
            borderRadius: "20px",
            backgroundColor: "white",
        },
    },
    dialog_container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "30px",
        // minHeight: "40vh",
        backgroundColor: "white",
        borderRadius: "50px",
    },
    icon_container: {
        alignSelf: "flex-end",
    },
    icon: {
        cursor: "pointer",
        width: "20px",
        "&:hover ": {
            filter: "invert(35%) sepia(55%) saturate(3225%) hue-rotate(338deg) brightness(87%) contrast(116%)",
        },
    },
    input_container: {
        // width: '100%',
        // display: "flex",
        // padding: "20px 0px 20px 0px",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
    },
    input_des: {
        fontSize: 16,
        margin: 0,
    },
    input_title: {
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
        textAlign: "center",
        margin: '5px 0 15px',
    },
    back_button: {
        alignSelf: "center",
        marginTop: "5px",
        width: "120px",
        padding: "8px 6px",
        color: "black",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    submit_button: {
        marginLeft: "20px",
        alignSelf: "center",
        marginTop: "5px",
        width: "120px",
        padding: "8px 6px",
        color: "white",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    dialog_inner_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 30,
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        }
    },
    button_container: {
        marginTop: "20px",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        }
    },
}));

type DialogProps = {
    itemName: string;
    isOpen: boolean;
    setOpen: any;
    handleSubmit: () => void;
}

const DoubleConfirmDeletePopUp: React.FC<DialogProps> = ({itemName, isOpen, setOpen, handleSubmit})=> {
    const classes = useStyles();
    const [confirmText, setConfirmText] = useState('');
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const deleteText = `DELETE_${itemName.toUpperCase()}`;

    const handleChange = useCallback((event, prop) => {
        const newValue = event.target.value;
        setConfirmText(newValue)

        if (newValue === deleteText) {
            setDisabledSubmit(false)
        } else {
            setDisabledSubmit(true)
        }

    }, [deleteText])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            className={classes.dialog}
            open={isOpen}
            onClose={handleClose}
            maxWidth={"lg"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={classes.dialog_container}>
                <div className={classes.icon_container}>
                    <img className={classes.icon}
                         src={close_icon}
                         onClick={handleClose}
                         alt="close-icon"
                    />
                </div>
                <div className={classes.dialog_inner_container}>
                    <div className={classes.input_container}>
                        <Typography className={classes.input_title}>
                            {/*{languagePackage.CONFIRM_DELETE}*/}
                            確定刪除
                        </Typography>
                        <Typography className={classes.input_des}>
                            {/*{languagePackage.CONFIRM_DELETE_DESC}*/}
                            此操作無法撤消。 這將永久刪除 <b>{itemName}</b> 在平台上使用權限，並且旗下所有的 私人/企業 會員將會被轉移到成為台灣的旗下帳號。
                        </Typography>
                        <Typography className={classes.input_des}>
                            請輸入 <b>{deleteText}</b> 進行確認。
                        </Typography>

                        <CustomTextField
                            value={confirmText}
                            stateKey="confirmText"
                            placeholder={deleteText}
                            handleChange={handleChange}
                            fullWidth
                        />

                    </div>
                    <div className={classes.button_container}>
                        <Button className={classes.back_button} onClick={handleClose}>
                            {/*{languagePackage.BACK}*/}
                            返回
                        </Button>
                        <Button
                            className={classes.submit_button}
                            variant="contained"
                            color={"primary"}
                            disabled={disabledSubmit}
                            onClick={handleSubmit}
                        >
                            {/*{languagePackage.CONFIRM_DELETE}*/}
                            刪除
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default DoubleConfirmDeletePopUp;
