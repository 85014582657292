import {apiMiddleware} from "../utils/middleware";
import {
    createTheIndustry,
    deleteTheIndustry,
    getAllIndustry,
    getIndustryPagination,
    getTheIndustry,
    updateTheIndustry
} from "../services/api/industry";
import {BasicQueryProps} from "../types/common";

const contextParams: { [key: string]: any } = {
    snackbar: true,
    snackbarType: 1,
    snackbarContent: "",
};

export interface IListBody {
    id: number;
    name: string;
    description: string;
    createdAt: string;
}

export const getAllIndustryList = async (
    setIndustryList: any,
    changeContext: any
) => {
    const {data, success} = await apiMiddleware(changeContext, getAllIndustry)
    if (!success) return;

    let refactorData = data;
    refactorData.map((i: any) => {
      delete Object.assign(i, { ["value"]: i["ID"] })["ID"];
      delete Object.assign(i, { ["label"]: i["name"] })["name"];
    });

    refactorData.unshift({ description: "", value: 0, label: "--產業--" });

    setIndustryList(refactorData);

}

export const getIndustryList = async (
    params: BasicQueryProps,
    setMainState: (params: any) => void,
    changeContext: any
) => {
    const {data, success} = await apiMiddleware(changeContext, getIndustryPagination, params)
    if (!success) return;

    const sortData = data.data.map((item: any) => ({
        id: item.ID,
        name: item.name,
        description: item.description,
        createdAt: item.createdAt
    }))

    setMainState({bodyData: sortData, totalPageItem: data.totals})
}

export const getIndustryDetails = async (
    id: number,
    setMainState: (params: any) => void,
    changeContext: any
) => {
    const {data, success} = await apiMiddleware(changeContext, getTheIndustry, id)
    if (!success) return;

    setMainState({
        dialogID: id,
        name: data.name,
        description: data.description,
    })
}

export const updateIndustryDetails = async (
    mainState: any,
    changeContext: any
) => {
    const {dialogID, name, description} = mainState;
    const body = {
        ID: dialogID,
        name,
        description
    }
    const {success} = await apiMiddleware(changeContext, updateTheIndustry, body)
    if (!success) return;

    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Successfully updated!";
    changeContext(contextParams)
}

export const createIndustry = async (
    mainState: any,
    changeContext: any
) => {
    const {name, description} = mainState;
    const body = {
        name,
        description
    }
    const {success} = await apiMiddleware(changeContext, createTheIndustry, body)
    if (!success) return;

    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Successfully created!";
    changeContext(contextParams)
}

export const deleteIndustry = async (
    mainState: any,
    changeContext: any
) => {
    const {deleteDialogID} = mainState;
    const {success} = await apiMiddleware(changeContext, deleteTheIndustry, deleteDialogID)
    if (!success) return;

    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Successfully deleted!";
    changeContext(contextParams)
}
