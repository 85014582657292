import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Typography, Tooltip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    linkText: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.info.main,
        fontSize: 16,
        '&:hover': {

        }
    }
}));

type RedirectTextProps = {
    id: number;
    text: string;
    tooltip?: string;
    handleClick: (id: number) => void;
};

const RedirectText: React.FC<RedirectTextProps> = (props) => {
    const {id, text, tooltip, handleClick} = props;
    const classes = useStyles();

    const _handleClick = () => {
        handleClick(id)
    }

    return (
        <React.Fragment>
            {tooltip ?
                <Tooltip title={tooltip} placement="top-start">
                    <Typography className={classes.linkText} onClick={_handleClick}>
                        {text}
                    </Typography>
                </Tooltip>
                :
                <Typography className={classes.linkText} onClick={_handleClick}>
                    {text}
                </Typography>
            }
        </React.Fragment>
    );
};

export default RedirectText;
