import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { routes } from "../../../configs/routes";
import { OnClickEventHandler, OnKeyboardEventHandler } from "../../../types/common";
// material ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useProps } from "../../../contexts";

type ListProps = {
  toggleDrawer?: (state: boolean) => (event: OnKeyboardEventHandler | OnClickEventHandler) => void;
};

type LinkItemProps = {
  classes: any;
  text: string;
  to: string;
  exact: boolean;
  toggleDrawer?: (state: boolean) => (event: OnKeyboardEventHandler | OnClickEventHandler) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
}));

const LinkItem = withStyles((theme) => ({
  noWrap: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  active: {
    boxSizing: "border-box",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.pink.main,
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  linkText: {
    justifyContent: "center",
    transition: "all 0.1s",
    color: theme.palette.primary.main,
    padding: "15px 50px",
    "&:hover": {
      backgroundColor: theme.palette.pink.main,
    },
  },
  titleIcon: {
    justifyContent: "flex-start",
    minWidth: "inherit",
    marginRight: 16,
  },
  titleText: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  typography: {
    color: theme.palette.gray.dark,
    // fontSize: 18,
    fontWeight: "bold",
    // fontSize: theme.typography.textFieldLabel.fontSize,
  },
}))(({ classes, text, to, exact, toggleDrawer }: LinkItemProps) => {
  const renderLink = React.forwardRef((itemProps, ref) => <NavLink to={to} {...itemProps} />);

  const isMatchActive = (match: any, location: any) => {
    let isMatch:boolean = false;
    if (location.pathname.split("/")[1] === to.split("/")[1]) {
      isMatch = true;
    }   
    return isMatch;  
  };
  
  return (
    <ListItem
      className={clsx(classes.linkText)}
      // @ts-ignore
      component={renderLink}
      to={to}
      isActive={isMatchActive}
      activeClassName={classes.active}
      exact={exact}
      onClick={toggleDrawer && toggleDrawer(false)}
    >
      <ListItemText classes={{ primary: classes.typography }} className={classes.noWrap} primary={text} />
    </ListItem>
  );
});

const ListItems = (props: ListProps) => {
  const { toggleDrawer } = props;
  const classes = useStyles();
  const { user } = useProps();

  let role: number = 0;
  if (user.isSuperAdmin) role = 1;

  return (
    <React.Fragment>
      <List className={classes.root}>
        {Object.keys(routes.navBar).map(
          (key) =>
            !routes.navBar[key].hidden &&
            routes.navBar[key].permission.includes(role) && (
              <LinkItem
                key={routes.navBar[key].text}
                exact
                text={routes.navBar[key].text}
                to={routes.navBar[key].path}
                toggleDrawer={toggleDrawer}
              />
            )
        )}
      </List>
    </React.Fragment>
  );
};

export default ListItems;
