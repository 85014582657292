import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ParamTypes} from "../../types/common";
import {routes} from "../../configs/routes";
import configVariables from "../../configs/variables.json";
// Components
import {getRoutesWithPath} from "../../utils";
import {useProps} from "../../contexts";
import useQuery from "../../hooks/useQuery";
import CustomTable from "../components/CustomTable";
import AmountText from "../components/AmountText";
import RedirectText from "../components/RedirectText";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {Typography, Grid, Paper} from "@material-ui/core";
// Controllers
import {getMemberCoinList, MemberBenefitBody} from "../../controller/MemberController";
import CustomCard from "../components/CustomCard";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: 24,
  },
  wrapper: {
    padding: "40px 0px",
  },
  totalsWrapper: {
    marginBottom: 10,
    padding: '0 20px',
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  balanceCoin: {
    fontWeight: 'bold',
    textAlign: "center",
  },
}));

const header = [
  { id: "id", label: "序號", disabledSort: true, align: "center" },
  { id: "actions", label: "項目", align: "left" },
  { id: "amount", label: "分潤點數", align: "left" },
  { id: "createdAt", label: "創建日期 時間", align: "left" },
];

const typeTitle = (
    type: number,
    languagePackage: any,
    businessID: number,
    companyName: string,
    handleRedirect: (ID: number) => (event: any) => {}
) => {
  let title: any = '';
  switch (type) {
    case configVariables.individualCoinHistoryType.receiveFromFanShui:
      title = <RedirectText id={businessID} text={companyName} tooltip={companyName} handleClick={handleRedirect}/>;
      break;
    case configVariables.individualCoinHistoryType.receiveFromSystem:
      title = languagePackage.SYSTEM_DEPOSIT;
      break;
    case configVariables.individualCoinHistoryType.withdraw:
      title = languagePackage.WITHDRAW;
      break;
    default:
      title = companyName;
  }
  return title;
}

const generateBody = (
    classes: any,
    body: MemberBenefitBody[],
    page: number,
    rowsPerPage: number,
    handleRedirect: any,
    languagePackage: any,
) => {
  return body.map((e, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    actions: typeTitle(e.actions, languagePackage, e.businessID, e.companyName, handleRedirect),
    amount: <AmountText amount={e.amount}/>,
    createdAt: e.createdAt
  }));
};

const MemberBenefit = () => {
  const classes = useStyles();
  const {memberID} = useParams<ParamTypes>();
  const history = useHistory();
  const {languagePackage, _handleChange} = useProps();
  const [query, setQuery] = useQuery();
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    rowsPerPage: query.limit || 10,
    totalPageItem: 10,
    order: query.sortBy || "desc",
    orderBy: query.sort || "createdAt",
    bodyData: [],
    refreshList: false,
    balanceCoin: 0,
    totalCoin: 0,
    totalMonthCoin: 0,
  });

  const setMainState = useCallback((newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleRedirect = (ID: number) => {
    history.push(getRoutesWithPath(routes.navBar.companyProfile.path, [ID]));
  }


  const fetchData = async () => {
    const { page, rowsPerPage, order, orderBy } = mainState;
    const params = {
      page,
      limits: rowsPerPage,
      order,
      orderBy,
    };
    await getMemberCoinList(memberID, params, setMainState, _handleChange);
    setQuery(params);
  };

  useEffect(() => {
    fetchData();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList]);

  const body = generateBody(
      classes,
      mainState.bodyData,
      mainState.page,
      mainState.rowsPerPage,
      handleRedirect,
      languagePackage
  );

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Grid direction="row" className={classes.totalsWrapper} container spacing={5}>
          <Grid item xs={12} md={4}>
            <CustomCard title={"當月收入"} value={mainState.totalMonthCoin}/>
              {/*<Typography className={classes.balanceCoin}>當月收入: {mainState.balanceCoin}</Typography>*/}
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard title={"累積點數"} value={mainState.totalCoin}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard title={"點數餘額"} value={mainState.balanceCoin}/>
          </Grid>
        </Grid>
        <CustomTable
          noBorder={true}
          header={header}
          body={body}
          rowsPerPageOptions={[10, 25, 100]}
          count={mainState.totalPageItem}
          rowsPerPage={mainState.rowsPerPage}
          page={mainState.page}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </div>
    </React.Fragment>
  );
};

export default MemberBenefit;
