export const validEmail = (email: string) => {
  let errMessage;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email))) {
    errMessage = 'Wrong email format.';
  }
  return errMessage;
};
export const checkAgree = (isChecked:boolean) =>{
  let errMessage;

  if (isChecked === false) {
    errMessage = "Please indicate that you accept the Terms & Policy.";
  }
  return errMessage;
  
}
export const isEmpty = (string: string|number|null, label='field') => {
  let errMessage;

  if (!string || string === '') {
    errMessage = label + " can't be blank.";
  }
  return errMessage;
};

export const checkUsername = (username:string,label="field") =>{
  let errMessage;

  const ch = /^[ A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF]{0,9}$/;

  if (!username || username === '') {
    return errMessage = label + " can't be blank.";
  }
  if(username.match(" ")){
    return errMessage = label + " cannot include spaces.";
  }
  if(ch.test(String(username))){
    return errMessage = label + "cannot use chiness word as username.";
  }
  return errMessage;
}

export const isNumber = (num:number | null,label="filed") => {
  let errMessage;

  if (typeof num !== "number") {
    errMessage = label + " only contain numbers.";
  }
  return errMessage;
}

export const isEmptyArray = (value: Array<any>) => {
  let errMessage;

  if (value.length === 0) {
    errMessage = "Empty data";
  }
  return errMessage;
};

export const isChecked = (value: boolean) => {
  let errMessage;
  if (!value) {
    errMessage = "Please indicate that you accept the Terms & Policy"
  }
  return errMessage
}
export const compareEmail = (email:string,backupEmail:string | undefined) =>{
  let errMessage; 
  
    if (email === backupEmail ) {
      errMessage = "Your email can not be the same as back up email.";
    }
  return errMessage;
}
export const comparePassword = (password: string, confirmPassword: string | undefined, isSame = false ) => {
  let errMessage;
  if (!password || password === '' || password.length === 0) {
    errMessage = "Password can't be blank.";
  }

  if (!confirmPassword || confirmPassword === ''|| password.length === 0) {
    errMessage = "Password can't be blank.";
  }

  if (isSame) {
    if (password === confirmPassword ) {
      errMessage = "Your new password can not be the same as current password.";
    }
  }
  else {
    if (password !== confirmPassword ) {
      errMessage = "Password are different.";
    }
  }

  return errMessage
}
