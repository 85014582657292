import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import configVariables from "../../configs/variables.json";
import {ParamTypes, SelectChangeEventHandler} from "../../types/common";
//component
import useQuery from "../../hooks/useQuery";
import {useProps} from "../../contexts";
import CustomTable from "../components/CustomTable";
import AmountText from "../components/AmountText";
import UploadBankStatementPopUp from "../components/Popups/UploadBankStatementPopUp";
//Material UI
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
// Controllers
import {getMemberWithdrawList, MemberTransferBody, updateWithdrawRecord} from "../../controller/MemberController";
import {getFileUploadPath} from "../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        fontSize: 24,
    },
    deleteIcon: {
        color: theme.palette.primary.main,
    },
    wrapper: {
        padding: '40px 0',
    }
}));

const header = [
    {id: "id", label: "序號", disabledSort: true, align: "center"},
    {id: "amount", label: "匯款金額", align: "left"},
    {id: "coins", label: "點數", align: "left"},
    {id: "status", label: "狀態", align: "left"},
    {id: "createdAt", label: "創建日期 時間", align: "left"},
    {id: "edit", label: "編輯", disabledSort: true, align: "left"},
];

const getUserStatus = (list: { label: string; value: number }[], statusID: number) => {
    let status;

    list.map((item, value) => {
        if (item.value === statusID) status = item.label.toString();
    });

    return status;
};
const generateBody = (
    classes: any,
    body: MemberTransferBody[],
    page: number,
    rowsPerPage: number,
    handleOpenEditPopUp: any,
) => {
    return body.map((e, i) => ({
        id: (page - 1) * rowsPerPage + i + 1,
        status: getUserStatus(configVariables.withdrawStatus, e.status),
        amount: <AmountText amount={e.amount} unit={"$"} onlyUnit />,
        coins: <AmountText amount={e.coins} onlyUnit />,
        createdAt: e.createdAt,
        edit: (
            <IconButton
                aria-label="inspect"
                onClick={handleOpenEditPopUp(e.ID, e.imageURL, e.status)}
            >
                <EditIcon/>
            </IconButton>
        ),
    }));
};

const MemberTransfer = () => {
    const classes = useStyles();
    const {memberID} = useParams<ParamTypes>();
    const {_handleChange} = useProps();
    const [query, setQuery] = useQuery();
    const [file, setFile] = useState<File>(new File([""], ""));
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [mainState, _setMainState] = useState({
        page: query.offset ? parseInt(query.offset) : 1,
        rowsPerPage: query.limit || 10,
        totalPageItem: 10,
        order: query.sortBy || "desc",
        orderBy: query.sort || "createdAt",
        bodyData: [],
        localUploadImagePath: '',
        selectItem: {
            ID: 0,
            userID: 0,
            status: 0,
            imageURL: '',
        },
        refreshList: false,
    });

    const setMainState = useCallback((newState) => {
        _setMainState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    const handleOpenEditPopUp = (ID: number, imageURL: string, status: number) => () => {
        const tmpData = {ID, imageURL, status}
        setMainState({selectItem: tmpData});
        setOpenEditDialog(true);
    };

    const handleCloseEditPopUp = () => {
        setMainState({localUploadImagePath: ''})
        setFile(new File([""], ""))
        setOpenEditDialog(false);
    };

    const handleSetFile = (event: any) => {
        const tmpFile = event.target.files[0];
        getFileUploadPath(tmpFile, (path: string) => {
            setMainState({localUploadImagePath: path})
        })
        setFile(tmpFile);
    };

    const handleSelect = (event: SelectChangeEventHandler) => {
        const tmpData: any = {...mainState.selectItem}
        tmpData.status = event.target.value;
        setMainState({selectItem: tmpData});
    };

    // API
    const handleSubmit = async () => {
        await updateWithdrawRecord(memberID, file, mainState, setMainState, _handleChange);
        setOpenEditDialog(false);
        setMainState({refreshList: !mainState.refreshList})
    }

    const fetchData = async () => {
        const {page, rowsPerPage, order, orderBy} = mainState;
        const params = {
            page,
            limits: rowsPerPage,
            order,
            orderBy
        }
        await getMemberWithdrawList(memberID, params, setMainState, _handleChange);
        setQuery(params);
    }

    useEffect(() => {
        fetchData();
    }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshList])

    const body = generateBody(
        classes,
        mainState.bodyData,
        mainState.page,
        mainState.rowsPerPage,
        handleOpenEditPopUp,
    );

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <CustomTable
                    noBorder={true}
                    header={header}
                    body={body}
                    rowsPerPageOptions={[10, 25, 100]}
                    count={mainState.totalPageItem}
                    rowsPerPage={mainState.rowsPerPage}
                    page={mainState.page}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                />
                <UploadBankStatementPopUp
                    title={"上傳銀行帳單"}
                    selectID={mainState.selectItem.ID}
                    selectStatus={mainState.selectItem.status}
                    imageName={file.name}
                    imagePath={mainState.localUploadImagePath}
                    originalImage={mainState.selectItem.imageURL}
                    isOpen={openEditDialog}
                    handleChange={handleSelect}
                    handleSetFile={handleSetFile}
                    handleClose={handleCloseEditPopUp}
                    handleSubmit={handleSubmit}
                />
            </div>
        </React.Fragment>
    );
};

export default MemberTransfer;
