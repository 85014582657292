import { getAllWithdrawListPagination } from "../services/api/members";
import { BasicQueryProps } from "../types/common";
import { formatDate } from "../utils";
import { apiMiddleware } from "../utils/middleware";

export const getAllWithdrawList = async (
    params: BasicQueryProps,
    setMainState: (params: any) => void,
    changeContext: any
) => {
    const {data, success} = await apiMiddleware(changeContext, getAllWithdrawListPagination, params)
    if (!success) return;

    const sortData = data.data.map((item: any) => ({
        ID: item.ID,
        userID: item.userID,
        username: item.username,
        name: item.name,
        email: item.email,
        amount:item.amount,
        coins:item.coins,
        description: item.requestDescription,
        status: item.status,
        imageURL: item.imageUrl,
        createdAt: formatDate(item.createdAt),
    }))

    setMainState({bodyData: sortData, totalPageItem: data.totals})
}
