import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// Material UI
import { Typography, IconButton, OutlinedInput, InputAdornment, FormHelperText } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// Types
import { InputChangeEventHandler, styleProps } from "../../../types/common";

type InputProps = {
  label?: string;
  value?: string | number | null;
  placeholder?: string | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  // keyPress?: (event: React.KeyboardEvent) => void;
  customStyle?:styleProps;
  handleKeyPress?: (event: any) => void;
  handleChange: (event: InputChangeEventHandler, stateKey: string) => void;
  stateKey: string;
  error?: string;
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginTop: 25,
  },
  root: {
    borderRadius: "15px",
    backgroundColor: theme.palette.background.paper,
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",
      fontSize: "15px",
      maxHeight: 45,
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiOutlinedInput-input": {
    //   borderRadius: "15px",
    //   padding: "10.5px 12px",
      maxHeight: 14,
      borderRadius: "15px",
      padding: "15.5px 14px",
    },
  },
  fullWidth: {
    width: "100%",
  },
  incline: {
    display: "flex",
    alignItems: "center",
    "& p": {
        marginRight: 10,
      },
  },
  inclineInput: {
    margin: 0,
    marginLeft: 40,
  },
  fieldLabel: {
    // // color: theme.palette.primary.light,
    // fontSize: 16,
    margin: "0px 0px 0px 10px",
    fontSize: "15px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  errorText: {
    color: "red",
    textAlign: "end",
  },
}));

export default function CustomPasswordField(props: InputProps) {
  const classes = useStyles();
  const { label, stateKey, value, customStyle,fullWidth, handleChange, handleKeyPress, error } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShow = () => {
    setShowPassword(!showPassword);
  };

  const _handleChange = (event: any, key: string) => {
    handleChange(event, key);
  };

  return (
    <div className={classes.inputWrapper} style={customStyle}>
      <Typography className={classes.fieldLabel} align="left">
        {label}
      </Typography>
        <OutlinedInput
          id={label}
          type={showPassword ? "text" : "password"}
          color="primary"
          value={value}
          onKeyPress={(event) => handleKeyPress && handleKeyPress(event)}
          // onKeyDown={handleKeyPress && handleKeyPress}
          className={clsx(classes.root, fullWidth && classes.fullWidth)}
          onChange={(e) => _handleChange(e, stateKey)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleToggleShow} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <FormHelperText className={classes.errorText}>{error}</FormHelperText>}
    </div>
  );
}
