import fetchData from "../../utils/fetchData";

type IndustryBody = {
    ID: number;
    name?: string;
    description?: string;
}

export function getAllIndustry() {
    return fetchData({
        url: "/generals/industry/all",
        method: "GET",
    });
}

export function getIndustryPagination(params: any) {
    return fetchData({
        url: "/generals/industry",
        method: "GET",
        params: params,
    });
}

export function getTheIndustry(industryID: number) {
    return fetchData({
        url: `/generals/industry/${industryID}`,
        method: "GET",
    });
}

export function updateTheIndustry(body: IndustryBody) {
    return fetchData({
        url: `/generals/industry/${body.ID}`,
        method: "PUT",
        data: body,
    });
}

export function deleteTheIndustry(industryID: number) {
    return fetchData({
        url: `/generals/industry/${industryID}`,
        method: "DELETE",
    });
}


export function createTheIndustry(body: IndustryBody) {
    return fetchData({
        url: `/generals/industry`,
        method: "POST",
        data: body,
    });
}
