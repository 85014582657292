import fetchData from "../../utils/fetchData";
import { BasicQueryProps } from "../../types/common";
import { getMemberReferralListAPI } from "../../controller/MemberController";

export type UpdateObjType = {
  ID: number;
  status: number;
};

export function getMembersPagination(params: any) {
  return fetchData({
    url: "/members",
    method: "GET",
    params: params,
  });
}

export function updateStatus(body: UpdateObjType) {
  return fetchData({
    url: `/members/${body.ID}/status`,
    method: "PUT",
    data: body,
  });
}

export function updateManualStatus(body: UpdateObjType) {
  return fetchData({
    url: `/members/${body.ID}/manual-status`,
    method: "PUT",
    data: body,
  });
}

export function getDetails(memberID: number) {
  return fetchData({
    url: `/members/${memberID}/details`,
    method: "GET",
  });
}

export function checkMemberStatus(memberID: number) {
  return fetchData({
    url: `/members/${memberID}/check-status`,
    method: "GET",
  });
}

export function deleteMember(memberID: number) {
  return fetchData({
    url: `/members/${memberID}/delete`,
    method: "DELETE",
  });
}

export function getBankDetails(memberID: number) {
  return fetchData({
    url: `/members/${memberID}/bank`,
    method: "GET",
  });
}

export function getWithdrawPagination(params: BasicQueryProps, memberID: number) {
  return fetchData({
    url: `/members/${memberID}/withdraw`,
    method: "GET",
    params: params,
  });
}

export function updateWithdraw(body: FormData, memberID: string, withdrawID: string) {
  return fetchData({
    url: `/members/${memberID}/withdraw/${withdrawID}`,
    method: "PUT",
    data: body,
    header: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getCoinListPagination(params: BasicQueryProps, memberID: number) {
  return fetchData({
    url: `/members/${memberID}/coin-history`,
    method: "GET",
    params: params,
  });
}

export function getAllWithdrawListPagination(params: BasicQueryProps) {
  return fetchData({
    url: `/members/withdraw`,
    method: "GET",
    params: params,
  });
}

export function getMemberReferralList(memberID: string, params: BasicQueryProps) {
  return fetchData({
    url: `/members/${memberID}/referral-list`,
    method: "GET",
    params: params,
  });
}

export function getMemberReferralDetails(memberID: string, branchID: string, params: BasicQueryProps) {
  return fetchData({
    url: `/members/${memberID}/referral-list/${branchID}`,
    method: "GET",
    params: params,
  });
}
